import { CSSProperties, useEffect, useMemo, useRef, useState } from 'react'
import { useOnLoadImages } from '../../hooks/useOnLoadImages'
import { Spinner } from '@vkontakte/vkui'
import { IFrameProps } from './Frame.types'
import { Balloon } from '../Balloon/Balloon'
import cn from 'classnames'
import styles from './Frame.module.scss'
import { Weather } from '../Weather/Weather'
import { useMouseCenterShift } from '../../hooks/useMouseCenterShift/useMouseCenterShift'
import { prepareEffects } from '../../utils/prepareEffects'

const BALLOON_MARGIN = 8
const MIN_SCENE_HEIGHT = 300

export const Frame = function ({
  characterImageUrl,
  effects = [],
  fadeOut,
  message,
  sceneImageUrl,
  title,
}: IFrameProps): JSX.Element {
  const contentRef = useRef<HTMLDivElement>(null)
  const balloonRef = useRef<HTMLDivElement>(null)
  const areImagesLoaded = useOnLoadImages(contentRef)
  const [frameHeight, setFrameHeight] = useState(MIN_SCENE_HEIGHT)
  const [balloonStyles, setBalloonStyles] = useState<CSSProperties>({})

  const contentClassNames = cn(
    styles.content,
    {
      [styles.fadeIn]: areImagesLoaded && !fadeOut,
      [styles.fadeOut]: fadeOut,
    }
  )

  const applySizes = () => {
    if (!contentRef.current) return
    const sceneHeight = contentRef.current.offsetWidth || 0
    let height = sceneHeight

    if (message && !!balloonRef.current) {
      const balloonHeight = balloonRef.current.offsetHeight
      const balloonMinTop = 0.75 * sceneHeight

      if (balloonHeight > sceneHeight - balloonMinTop) {
        height = balloonHeight + balloonMinTop
        console.log(2, height)
        setBalloonStyles({
          top: balloonMinTop
        })
      } else {
        setBalloonStyles({
          bottom: BALLOON_MARGIN
        })
      } 
    }
    setFrameHeight(height)
  }

  useEffect(() => {
    applySizes()
  }, [message, areImagesLoaded])

  useEffect(() => {
    window.addEventListener('resize', applySizes)
    return () => {
      window.removeEventListener('resize', applySizes)
    }
  }, [])

  const { xShift } = useMouseCenterShift()
  const frameWidth = contentRef?.current?.offsetWidth || 0

  const preparedEffects = useMemo(() => {
    return prepareEffects(effects)
  }, [effects])

  return (
    <div
      className={styles.root}
      style={{
        height: frameHeight,
      }}
    >
      <div
        className={contentClassNames}
        ref={contentRef}
      >
        {
          !!title && (
            <span className={styles.title}>
              {title}
            </span>
          )
        }
        {
          !!sceneImageUrl && (
            <div className={styles.sceneBackgroundMask}>
              {/* eslint-disable-next-line jsx-a11y/alt-text */}
              <img
                className={styles.sceneBackground}
                src={sceneImageUrl}
                style={{
                  transform: `translateX(${xShift * (2)}px) scale(${(frameWidth + 4)/ frameWidth})`,
                  transition: 'transform 0.2s linear',
                  top: -2,
                }}
              />
            </div>
          )
        }

        <div className={styles.effectMask}>
          {
            preparedEffects.map((effect) => (
              <Weather
                key={effect.value}
                style={{
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  left: -20,
                  right: -20,
                  pointerEvents: 'none',
                  transform: `translateX(${xShift * 3}px)`,
                  transition: 'transform 0.2s linear',
                }}
                {...effect['background']}
              />
            ))
          }
          
        </div>

        {
          !!characterImageUrl && (
            <div className={styles.effectMask}>
              {/* eslint-disable-next-line jsx-a11y/alt-text */}
              <img
                className={styles.character}
                src={characterImageUrl}
                style={{
                  transform: `translateX(${xShift * -3}px) scale(${(frameWidth + 6)/ frameWidth})`,
                  transition: 'transform 0.2s linear',
                  top: -4,
                }}
              />
            </div>
            
          )
        }

        <div className={styles.effectMask}>
        {
            preparedEffects.map((effect) => (
              <Weather
                key={effect.value}
                style={{
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  left: -20,
                  right: -20,
                  pointerEvents: 'none',
                  transform: `translateX(${xShift * -4}px)`,
                  transition: 'transform 0.2s linear',
                }}
                {...effect['foreground']}
              />
            ))
          }
        </div>
        {
          !!message && areImagesLoaded && (
            <div
              className={styles.balloon}
              ref={balloonRef}
              style={balloonStyles}
            >
              <Balloon message={message} />
            </div>
          )
        }
      </div>

      {!areImagesLoaded && (
        <div className={styles.loading}>
          <Spinner />
        </div>
      )}
    </div>
  )
}
