export interface IEffectPreset {
  type: string
  value: string
  label: string

  background: {
    image: string
    size: number | string
  };
  foreground: {
    image: string
    size: number | string
  };
}

export const EFFECT_PRESETS: { [key: string]: IEffectPreset } = {
  SNOW_LIGHT: {
    type: 'SNOW',
    value: 'SNOW_LIGHT',
    label: 'Снег (слабый)',
    background: {
      image: 'photo_2024-05-28_00-02-58.png',
      size: 100,
    },
    foreground: {
      image: 'photo_2024-05-28_00-02-58.png',
      size: 200,
    }
  },

  SNOW_MEDIUM: {
    type: 'SNOW',
    value: 'SNOW_MEDIUM',
    label: 'Снег (средний)',
    background: {
      image: 'photo_2024-06-01_01-20-09.png',
      size: 100,
    },
    foreground: {
      image: 'photo_2024-06-01_01-20-09.png',
      size: 200,
    }
  },

  SNOW_HARD: {
    type: 'SNOW',
    value: 'SNOW_HARD',
    label: 'Снег (сильный)',
    background: {
      image: 'photo_2024-06-01_01-20-09.png',
      size: 100,
    },
    foreground: {
      image: 'photo_2024-06-01_01-20-09.png',
      size: 200,
    }
  },

  RAIN_LIGHT: {
    type: 'RAIN',
    value: 'RAIN_LIGHT',
    label: 'Дождь (слабый)',
    background: {
      image: 'b205bd87-949a-4f07-92f8-242200ef0b73.png',
      size: 100,
    },
    foreground: {
      image: 'b205bd87-949a-4f07-92f8-242200ef0b73.png',
      size: 200,
    }
  },

  RAIN_MEDIUM: {
    type: 'RAIN',
    value: 'RAIN_MEDIUM',
    label: 'Дождь (средний)',
    background: {
      image: 'b205bd87-949a-4f07-92f8-242200ef0b73.png',
      size: 100,
    },
    foreground: {
      image: 'b205bd87-949a-4f07-92f8-242200ef0b73.png',
      size: 200,
    }
  },

  RAIN_HARD: {
    type: 'RAIN',
    value: 'RAIN_HARD',
    label: 'Дождь (сильный)',
    background: {
      image: 'b205bd87-949a-4f07-92f8-242200ef0b73.png',
      size: 100,
    },
    foreground: {
      image: 'b205bd87-949a-4f07-92f8-242200ef0b73.png',
      size: 200,
    }
  },

  LEAF_FALL_LIGHT: {
    type: 'LEAF_FAL',
    value: 'LEAF_FALL_LIGHT',
    label: 'Листопад (Слабый)',
    background: {
      image: 'b205bd87-949a-4f07-92f8-242200ef0b73.png',
      size: 100,
    },
    foreground: {
      image: 'b205bd87-949a-4f07-92f8-242200ef0b73.png',
      size: 200,
    }
  },

  LEAF_FALL_MEDIUM: {
    type: 'LEAF_FAL',
    value: 'LEAF_FALL_MEDIUM',
    label: 'Листопад (Средний)',
    background: {
      image: 'b205bd87-949a-4f07-92f8-242200ef0b73.png',
      size: 100,
    },
    foreground: {
      image: 'b205bd87-949a-4f07-92f8-242200ef0b73.png',
      size: 200,
    }
  },

  LEAF_FALL_HARD: {
    type: 'LEAF_FAL',
    value: 'LEAF_FALL_HARD',
    label: 'Листопад (Сильный)',
    background: {
      image: 'b205bd87-949a-4f07-92f8-242200ef0b73.png',
      size: 100,
    },
    foreground: {
      image: 'b205bd87-949a-4f07-92f8-242200ef0b73.png',
      size: 200,
    }
  },

  PETALS_LIGHT: {
    type: 'PETALS',
    value: 'PETALS_LIGHT',
    label: 'Лепестки (слабое)',
    background: {
      image: 'b205bd87-949a-4f07-92f8-242200ef0b73.png',
      size: 100,
    },
    foreground: {
      image: 'b205bd87-949a-4f07-92f8-242200ef0b73.png',
      size: 200,
    }
  },

  PETALS_MEDIUM: {
    type: 'PETALS',
    value: 'PETALS_MEDIUM',
    label: 'Лепестки (среднее)',
    background: {
      image: 'b205bd87-949a-4f07-92f8-242200ef0b73.png',
      size: 100,
    },
    foreground: {
      image: 'b205bd87-949a-4f07-92f8-242200ef0b73.png',
      size: 200,
    }
  },

  PETALS_HARD: {
    type: 'PETALS',
    value: 'PETALS_HARD',
    label: 'Лепестки (сильное)',
    background: {
      image: 'b205bd87-949a-4f07-92f8-242200ef0b73.png',
      size: 100,
    },
    foreground: {
      image: 'b205bd87-949a-4f07-92f8-242200ef0b73.png',
      size: 200,
    }
  },

  DUST_LIGHT: {
    type: 'DUST',
    value: 'DUST_LIGHT',
    label: 'Пыль (слабая)',
    background: {
      image: 'b205bd87-949a-4f07-92f8-242200ef0b73.png',
      size: 100,
    },
    foreground: {
      image: 'b205bd87-949a-4f07-92f8-242200ef0b73.png',
      size: 200,
    }
  },

  DUST_MEDIUM: {
    type: 'DUST',
    value: 'DUST_MEDIUM',
    label: 'Пыль (средняя)',
    background: {
      image: 'b205bd87-949a-4f07-92f8-242200ef0b73.png',
      size: 100,
    },
    foreground: {
      image: 'b205bd87-949a-4f07-92f8-242200ef0b73.png',
      size: 200,
    }
  },

  DUST_HARD: {
    type: 'DUST',
    value: 'DUST_HARD',
    label: 'Пыль (сильная)',
    background: {
      image: 'b205bd87-949a-4f07-92f8-242200ef0b73.png',
      size: 100,
    },
    foreground: {
      image: 'b205bd87-949a-4f07-92f8-242200ef0b73.png',
      size: 200,
    }
  },
}
