import { useEffect, useState } from "react";
import { ICharacterDocument } from "../features/characters/character.slice";
import { UseAppDispatch, UseAppSelector } from "../hooks/hooks";
import { RootState } from "../app/store";
import { getCreatorCharacterList, updateCreatorPhrases } from "../Api/Creator/creator.api";
import {
  CardGrid,
  ContentCard,
  Group,
  ModalPage,
  ModalPageHeader,
  PanelHeaderClose,
} from "@vkontakte/vkui";
import { ModalPageEnum, setModal } from "../features/navigation/navigation.slice";
import { SERVER_IMAGE_API } from "../constants/constants";
import { setAllPhrases, setCurrentPhrase } from "../features/phrase/phrase.slice";

function CharactersModal({ id: string }) {
  const [characterList, setCharacterList] = useState<ICharacterDocument[]>([]);

  const currentStory = UseAppSelector(
    (state: RootState) => state.story.selectedStory,
  );

  const currentPhrase = UseAppSelector(
    (state: RootState) => state.phrase.currentPhrase,
  );

  const allPhrases = UseAppSelector(
    (state: RootState) => state.phrase.allPhrases,
  );

  const modalHistory = UseAppSelector(
    (state: RootState) => state.navigation.modalHistory,
  );

  const dispatch = UseAppDispatch();

  useEffect(() => {
    const getAllStoriesHandle = async () => {
      if (!currentStory?._id) return;

      const charList: ICharacterDocument[] | undefined = await getCreatorCharacterList(
        currentStory._id
      );

      if (charList?.length) {
        setCharacterList(charList);
      }
    };

    getAllStoriesHandle();
  }, []);

  const handleSelectCharacter = async (character: ICharacterDocument) => {
    if (!currentPhrase?._id) return;
    if (!currentStory?._id) return;
    if (!character?._id) return;

    const isUpdated = await updateCreatorPhrases({
      storyId: currentStory._id,
      phraseId: currentPhrase._id,
      dto: {
        characterId: character._id,
      },
    });

    if (isUpdated) {
      dispatch(setCurrentPhrase({ ...currentPhrase, characterId: character._id }));

      const phraseIdx = allPhrases.findIndex(item => item._id === currentPhrase._id);
      if (phraseIdx !== -1) {
        const phraseList = [...allPhrases];
        phraseList[phraseIdx] = { ...currentPhrase, characterId: character._id };
        dispatch(setAllPhrases(phraseList));
      }
    }

  };

  const handleClose = () => {
    console.log({ modalHistory });
    if (modalHistory[modalHistory.length - 2] === ModalPageEnum.phrase) {
      dispatch(setModal(ModalPageEnum.phrase));
    } else {
      dispatch(setModal(null));
    }
  };

  return (
    <ModalPage
      id={"characters"}
      onClose={handleClose}
      settlingHeight={100}
      dynamicContentHeight
      header={
        <ModalPageHeader before={<PanelHeaderClose onClick={handleClose} />}>
          Выберете персонажа
        </ModalPageHeader>
      }
    >
      <Group description="Персонажи">
        <CardGrid size="m">
          {(characterList &&
            characterList?.length &&
            characterList?.map((characterOne: ICharacterDocument, idx: number) => (
              <ContentCard
                mode="outline"
                key={idx}
                onClick={() => {
                  handleSelectCharacter(characterOne);
                  handleClose();
                }}
                src={SERVER_IMAGE_API + characterOne.imageUrl}
                alt="none"
                header={characterOne.name}
                maxHeight={200}
              />
            ))) ||
            ""}
        </CardGrid>
      </Group>
    </ModalPage>
  );
}

export default CharactersModal;
