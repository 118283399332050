import { Icon24VideoSquareOutline } from "@vkontakte/icons";
import bridge, { EAdsFormats, RequestIdProp } from "@vkontakte/vk-bridge";
import { Alert, Button, Link, Text } from "@vkontakte/vkui";
import { RootState } from "../app/store";
import { UseAppSelector } from "../hooks/hooks";
import {
  readerCheckAdsLimit,
  readerGiftForAds,
} from "../Api/Reader/reader.api";

const checkVkAdsIsDownloaded = async (): Promise<boolean> => {
  try {
    const isIframe = bridge.isIframe();
    if (!isIframe) {
      return false;
    }

    const result = await bridge.send("VKWebAppCheckNativeAds", {
      ad_format: EAdsFormats.REWARD,
    });

    return !!result;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const VkAdsButton = ({ requestKey, stretched, setPopout }: any) => {
  const args: { [key: string]: RequestIdProp } = {};

  const handleAlert = ({
    closeButtonText,
    header,
    text,
  }: {
    closeButtonText: string;
    header: string;
    text: string;
  }) => {
    setPopout(
      <Alert
        actions={[
          {
            title: closeButtonText || "Хорошо",
            autoClose: true,
            mode: "cancel",
          },
        ]}
        actionsLayout="vertical"
        onClose={() => setPopout(null)}
        header={header}
        text={
          <div style={{ width: 304 }}>
            {text}. В случае проблем напишите нам{" "}
            <Link href="https://vk.com/im?sel=-219932114">в группу</Link>
          </div>
        }
      />
    );
  };

  if (requestKey) {
    args.requestKey = requestKey;
  }

  const currentUserId = UseAppSelector(
    (state: RootState) => state.user.selectedUserId
  );
  if (!currentUserId) {
    handleAlert({
      closeButtonText: "Хорошо",
      header: "Ошибка приложения",
      text: "Пользователь не найден. Перезайдите в игру.",
    });
  }

  async function showVkAds() {
    try {
      const isIframe = bridge.isIframe();
      if (!isIframe) {
        return false;
      }

      const data = await bridge.send("VKWebAppShowNativeAds", {
        ad_format: EAdsFormats.REWARD,
      });

      if (data?.result) {
        return true;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  const onClick = async () => {
    const adsIsReady = await checkVkAdsIsDownloaded();

    if (!adsIsReady) {
      handleAlert({
        closeButtonText: "Понятно, бывает",
        header: "Ошибка рекламы",
        text: "Пум-пум. Тут какие-то проблемы с сетью, либо с лимитами по рекламе. Возвращайтесь за ключами 🗝 попозже!",
      });
    }

    const checkUserLimit = await readerCheckAdsLimit();
    if (!checkUserLimit) {
      handleAlert({
        closeButtonText: "Посмотрю попозже",
        header: "Лимит по рекламе",
        text: "В игре есть лимит на получение ключей 🗝 за рекламу. Приходите попозже или выберете другой способ получения ключей 🗝.",
      });
      return;
    }

    const adsIsWatched = await showVkAds();
    if (adsIsWatched) {
      const giftToUser = await readerGiftForAds();
      if (giftToUser) {
        handleAlert({
          closeButtonText: "Отлично",
          header: "Вознаграждение",
          text: "Вам начислено 3🗝 бонусных ключа! Спасибо, что смотрите рекламу и поддерживаете проект!",
        });
        return;
      } else {
        handleAlert({
          closeButtonText: "Напишу",
          header: "Ошибка рекламы",
          text: "Вы посмотрели рекламу, но почему-то 🗝 не зачислились, напишите нам об этом и мы вам переведем ваши бонусы!",
        });
        return;
      }
    } else {
      handleAlert({
        closeButtonText: "Хорошо",
        header: "Ошибка показа рекламы",
        text: "Реклама не хочет показываться, возможно проблемы с сетью. Возвращайтесь за ключами 🗝 попозже!",
      });
      return;
    }
  };

  return (
    <Button
      appearance="accent"
      before={<Icon24VideoSquareOutline />}
      onClick={onClick}
      size="l"
      stretched={stretched}
    >
      <div style={{ display: 'flex' }}>
        Посмотреть рекламу +3🗝
      </div>
    </Button>
  );
};
