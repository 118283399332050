import {
  Panel,
  PanelHeader,
  Group,
  SplitLayout,
  PanelHeaderButton,
  FormItem,
  FormLayout,
  Input,
  Button,
  NativeSelect,
  Spacing,
  MiniInfoCell,
  Alert,
  Link,
} from "@vkontakte/vkui";
import { FormEventHandler, useEffect, useState } from "react";
import {
  Icon20MoneyCircleOutline,
  Icon28SettingsOutline,
} from "@vkontakte/icons";
import { UseAppDispatch, UseAppSelector } from "../hooks/hooks";
import { RootState } from "../app/store";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  ICreateUserDocument,
  IUserDocument,
  setSelectedUserId,
  setCurrentUser,
} from "../features/user/user.slice";
import { getUserById, updateUserById } from "../Api/user.api";

export const SettingsPanel = ({ id }: { id: string }) => {
  const [popout, setPopout] = useState<any>(null);
  const [userData, setUserData] = useState<IUserDocument | null>(null);
  const [refresh, setRefresh] = useState<boolean>(true);

  const currentUserId = UseAppSelector(
    (state: RootState) => state.user.selectedUserId
  );

  const dispatch = UseAppDispatch();

  useEffect(() => {
    const getUserData = async () => {
      if (!currentUserId) return;
      const userData: IUserDocument = await getUserById(currentUserId);
      if (userData?._id) {
        dispatch(setSelectedUserId(userData._id));
        dispatch(setCurrentUser(userData));
        setUserData(userData);
        formik.setValues(userData);
      }
    };
    getUserData();
  }, [refresh]);

  const openPopout = ({
    closeButtonText,
    header,
    text,
  }: {
    closeButtonText: string;
    header: string;
    text: string;
  }) => {
    setPopout(
      <Alert
        actions={[
          {
            title: closeButtonText || "Хорошо",
            autoClose: true,
            mode: "cancel",
          },
        ]}
        actionsLayout="vertical"
        onClose={() => setPopout(null)}
        header={header || "Ошибкааа"}
        text={
          text ||
          <>
            В случае проблем напишите нам{" "}
            <Link href="https://vk.com/im?sel=-219932114">в группу</Link>
          </>
        }
      />
    );
  };

  const handleUpdateUserData = async (newUserData: ICreateUserDocument) => {
    try {
      if (!currentUserId) return;
      const userUpdated = await updateUserById(currentUserId, newUserData);
      if (userUpdated) {
        setRefresh(!refresh);
        openPopout({
          closeButtonText: "Ок",
          header: "Обновление",
          text: "Данные успешно сохранены!",
        });
      } else {
        openPopout({
          closeButtonText: "Закрыть",
          header: "Ошибка обновления",
          text: "Что-то пошло не так",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      nickname: userData?.nickname || "",
      age: 18,
    },
    validationSchema: Yup.object({
      nickname: Yup.string()
        .max(30, "Должно быть не более 30 символов")
        .min(1, "Должно быть не менее 1 символа")
        .default("Player" + Math.random())
        .optional(),
      age: Yup.number()
        .positive("Возраст должен быть больше нуля")
        .default(18)
        .optional(),
    }),
    onSubmit: (values) => {
      console.warn("WARN", JSON.stringify(values, null, 2));
      handleUpdateUserData(values);
    },
  });

  return (
    <SplitLayout
      popout={popout}
      style={{ maxWidth: 512, margin: "auto", justifyContent: "center" }}
    >
      <Panel id={id}>
        <PanelHeader
          before={
            <PanelHeaderButton aria-label="vk">
              <Icon28SettingsOutline />
            </PanelHeaderButton>
          }
        >
          Настройки
        </PanelHeader>

        <Group>
          <FormLayout
            onSubmit={formik.handleSubmit as FormEventHandler<HTMLElement>}
          >
            <FormItem
              htmlFor="nickname"
              top="Никнейм"
              status={
                formik.touched.nickname
                  ? "valid"
                  : formik.errors.nickname
                    ? "error"
                    : undefined
              }
              bottom={formik?.errors?.nickname as string}
              bottomId="nickname-type"
            >
              <Input
                id="nickname"
                name="nickname"
                type="text"
                aria-labelledby="nickname-type"
                value={formik.values.nickname}
                onChange={formik.handleChange}
              />
            </FormItem>

            {/* <FormItem
              top="Ваша возрастная группа"
              name="age"
              status={
                formik.touched.age
                  ? "valid"
                  : formik.errors.age
                    ? "error"
                    : undefined
              }
              bottom={formik?.errors?.age as string}
            >
              <NativeSelect
                id="age"
                name="age"
                value={formik.values.age}
                onChange={formik.handleChange}
                aria-labelledby="age"
              >
                <option value="14">14</option>
              </NativeSelect>
            </FormItem> */}

            <FormItem>
              <Button
                disabled={Object.keys(formik.errors).length > 0}
                type="submit"
                size="l"
                appearance="positive"
                stretched
              >
                Обновить
              </Button>
            </FormItem>
          </FormLayout>
        </Group>

        <Spacing />
        <MiniInfoCell before={<Icon20MoneyCircleOutline />} textWrap="full">
          У вас уже {userData?.coins || 0} 🗝 ключей
        </MiniInfoCell>
      </Panel>
    </SplitLayout>
  );
};
