import { Icon24GoodsCollection } from "@vkontakte/icons";
import bridge, { RequestIdProp } from "@vkontakte/vk-bridge";
import { Alert, Button, Link } from "@vkontakte/vkui";
import { RootState } from "../app/store";
import { UseAppSelector } from "../hooks/hooks";

export const VkBuy10KeysPer5VoteButton = ({ requestKey, stretched, setPopout }: any) => {
  const handleAlert = (header: string, text: string) => {
    setPopout(
      <Alert
        actions={[
          {
            title: "Хорошо",
            autoClose: true,
            mode: "cancel",
          },
        ]}
        actionsLayout="vertical"
        onClose={() => setPopout(null)}
        header={header}
        text={
          <div style={{ width: 304 }}>
            {text}. В случае проблем напишите нам{" "}
            <Link href="https://vk.com/im?sel=-219932114">в группу</Link>
          </div>
        }
      />
    );
  };

  const currentUserId = UseAppSelector(
    (state: RootState) => state.user.selectedUserId
  );
  if (!currentUserId) {
    handleAlert(
      "Ошибка",
      "Ваш аккаунт не найден"
    );
    return;
  }

  const args: { [key: string]: RequestIdProp } = {};

  if (requestKey) {
    args.requestKey = requestKey;
  }

  const onClick = () => {
    bridge
      .send("VKWebAppShowOrderBox", {
        type: "item",
        item: "item_10KeysPer5Vote",
      })
      .then(async (data: { [key: string]: any }) => {
        if (data.success) {
          handleAlert(
            "Успех",
            "Товар куплен. Вскоре ключи добавятся вам в аккаунт"
          );
        } else {
          handleAlert("Ошибка", "Что-то пошло не так");
        }
      })
      .catch((error) => {
        handleAlert(
          "Ошибка",
          "В данный момент покупка недоступна, попробуйте позже"
        );
        console.error(error);
      });
  };

  return (
    <Button
      appearance="positive"
      before={<Icon24GoodsCollection />}
      onClick={onClick}
      size="l"
      stretched={stretched}
    >
      +10 ключей за 5 голосов
    </Button>
  );
};
