import { useState } from 'react'
import cn from 'classnames'

import {
  Card,
  Image,
} from '@vkontakte/vkui'

import { IEntityCardProps } from './EntityCard.types'
import styles from './EntityCard.module.scss'
import { Icon16Chevron } from '@vkontakte/icons'

export function EntityCard({ children, imageUrl, title, subtitle }: IEntityCardProps): JSX.Element {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <Card className={styles.card} >
      <div
        className={styles.info}
        onClick={() => {
          setIsExpanded((currentIsExpanded) => !currentIsExpanded)
        }}
      >
        {
          !!imageUrl && (
          <Image 
            alt={title}
            src={imageUrl}
            className={styles.image}
          />)
        }
        

        <div className={styles.titles}>
          {
            !!title && (
              <span className={styles.title}>
                {title}
              </span>
            )
          }

          {
            !!subtitle && (
              <span className={styles.subtitle}>
                {subtitle}
              </span>
            )
          }
        </div>
        <div className={styles.arrow}>
          <Icon16Chevron
            className={cn(
              styles.arrowIcon, 
              {
                [styles.arrowIconExpanded]: isExpanded,
              }
            )}
          />
        </div>
      </div>
      {
        !!children && (
          <div className={styles.children} >
            {
              isExpanded && children
            }
          </div>
        )
      }
    </Card>
  )
}
