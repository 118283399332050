import {
  Panel,
  PanelHeader,
  SplitLayout,
  PanelHeaderBack,
  ModalRoot,
  SplitCol,
  InfoRow,
  SimpleCell,
  Cell,
  Image,
  Button,
  Div,
  FormStatus,
  Counter,
  Group,
  ButtonGroup,
  ScreenSpinner,
} from "@vkontakte/vkui";
import { useEffect, useState } from "react";
import { UseAppDispatch, UseAppSelector } from "../hooks/hooks";
import { ModalPageEnum, setModal, setStory } from "../features/navigation/navigation.slice";
import { RootState } from "../app/store";
import { LoadingStatusType, ModerationStatusEnum } from "../Interfaces";
import { SERVER_IMAGE_API } from "../constants/constants";
import { Icon16KeyOutline, Icon24Add, Icon24ArrowDownOutline, Icon24ArrowUp, Icon24CommentAddBadgeOutline, Icon24PenOutline } from "@vkontakte/icons";
import { getCreatorPhraseList, updateCreatorPhrases } from "../Api/Creator/creator.api";
import { IPhraseDocument, createNextPhraseBySlice, createPrevPhraseBySlice, setAllPhrases, setCurrentPhrase, setEditorPhraseNext, setEditorPhrasePrev } from "../features/phrase/phrase.slice";
import CharactersModal from "../Components/CharacterModal";
import EditorPhrasesModal from "../Components/EditorPhraseModal";
import { EditorPhraseListModal } from "../Components/PhraseListModal";
import ScenesModal from "../Components/SceneModal";

export const PhraseLinksEditorPanel = ({ id }: { id: string }) => {
  const [addPrev, setAddPrev] = useState<boolean>(false);
  const [addNext, setAddNext] = useState<boolean>(false);
  const [popout, setPopout] = useState<any>(null);
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatusType | undefined>("loading");
  const [linkedListPrev, setLinkedListPrev] = useState<IPhraseDocument[]>([]);
  const [linkedListNext, setLinkedListNext] = useState<IPhraseDocument[]>([]);

  const dispatch = UseAppDispatch();

  const currentStory = UseAppSelector(
    (state: RootState) => state.story.selectedStory,
  );

  const currentModal = UseAppSelector(
    (state: RootState) => state.navigation.modal
  ) as string | null;

  const currentPhrase = UseAppSelector(
    (state: RootState) => state.phrase.currentPhrase
  );

  const allPhrases = UseAppSelector(
    (state: RootState) => state.phrase.allPhrases,
  );

  const allCharacters = UseAppSelector(
    (state: RootState) => state.character.allCharacters,
  );

  const allScenes = UseAppSelector(
    (state: RootState) => state.scene.allScenes,
  );

  const editorPhrasePrev = UseAppSelector(
    (state: RootState) => state.phrase.editorPhrasePrev,
  );

  const editorPhraseNext = UseAppSelector(
    (state: RootState) => state.phrase.editorPhraseNext,
  );

  useEffect(() => {
    if (!currentStory?._id) return;

    const getAllPhrases = async () => {
      const phraseList = await getCreatorPhraseList(currentStory._id);
      phraseList && dispatch(setAllPhrases(phraseList));
    }

    getAllPhrases();
  }, [editorPhrasePrev, editorPhraseNext]);

  useEffect(() => {
    if (!currentStory?._id) return;
    if (!currentPhrase?._id) return;

    const prev: IPhraseDocument[] = [];
    const next: IPhraseDocument[] = [];

    currentPhrase?.previousPhrases?.forEach((itemId: string) => {
      const finder = allPhrases.find(sub => sub._id.toLocaleLowerCase() === itemId.toLowerCase());
      if (finder) {
        prev.push(finder);
      }
    });

    currentPhrase?.nextPhrases?.forEach((itemId: string) => {
      const finder = allPhrases.find(sub => sub._id.toLocaleLowerCase() === itemId.toLowerCase());
      if (finder) {
        next.push(finder);
      }
    });

    setLinkedListPrev(prev);
    setLinkedListNext(next);
  }, [currentPhrase, allPhrases]);

  const startLoading = () => {
    setLoadingStatus("loading");
    setPopout(<ScreenSpinner size="large" state={loadingStatus} />)
    setTimeout(() => {
      stopLoading();
    }, 500);
  };

  const stopLoading = () => {
    setLoadingStatus("done");
    setPopout(null);
  };

  const modal = (
    <ModalRoot activeModal={currentModal}>
      <ScenesModal id="scenes" />
      <CharactersModal id="characters" />
      <EditorPhrasesModal id="phrase" />
      <EditorPhraseListModal id="phraseList" />
    </ModalRoot>
  );

  const removeFromNextList = async (nextPhrase: IPhraseDocument) => {
    // TODO 
    if (!nextPhrase) return;
    if (!currentPhrase) return;
    if (!currentStory) return;

    startLoading();

    if (currentPhrase?.nextPhrases?.length) {
      const previusUpdated = await updateCreatorPhrases({
        storyId: currentStory._id,
        phraseId: currentPhrase._id,
        dto: {
          nextPhrases: [...currentPhrase.nextPhrases.filter(item => item !== nextPhrase._id)],
        },
      });

      if (previusUpdated) {
        dispatch(setCurrentPhrase(previusUpdated));
        dispatch(setAllPhrases([
          ...allPhrases.filter(item => item._id !== currentPhrase._id),
          previusUpdated,
        ]));
      }
    }

    if (nextPhrase?.previousPhrases?.length) {
      const nextUpdated = await updateCreatorPhrases({
        storyId: currentStory._id,
        phraseId: nextPhrase._id,
        dto: {
          previousPhrases: [...nextPhrase.previousPhrases.filter(item => item !== currentPhrase._id)],
        },
      });

      if (nextUpdated) {
        dispatch(setAllPhrases([
          ...allPhrases.filter(item => item._id !== nextPhrase._id),
          nextUpdated,
        ]));
      }
    }

    stopLoading();
  };

  const removeFromPrevList = async (previusPhrase: IPhraseDocument) => {
    if (!previusPhrase) return;
    if (!currentPhrase) return;
    if (!currentStory) return;

    startLoading();

    if (currentPhrase?.previousPhrases?.length) {
      const previusUpdated = await updateCreatorPhrases({
        storyId: currentStory._id,
        phraseId: currentPhrase._id,
        dto: {
          previousPhrases: [...currentPhrase.previousPhrases.filter(item => item !== previusPhrase._id)],
        },
      });

      if (previusUpdated) {
        dispatch(setCurrentPhrase(previusUpdated));
        dispatch(setAllPhrases([
          ...allPhrases.filter(item => item._id !== currentPhrase._id),
          previusUpdated,
        ]));
      }
    }

    if (previusPhrase?.nextPhrases?.length) {
      const nextUpdated = await updateCreatorPhrases({
        storyId: currentStory._id,
        phraseId: previusPhrase._id,
        dto: {
          nextPhrases: [...previusPhrase.nextPhrases.filter(item => item !== currentPhrase._id)],
        },
      });

      if (nextUpdated) {
        dispatch(setAllPhrases([
          ...allPhrases.filter(item => item._id !== previusPhrase._id),
          nextUpdated,
        ]))
      }
    }

    stopLoading();
  };

  const handleCreateNextPhrase = async () => {
    if (!currentStory?._id) return;
    if (!currentPhrase?._id) return;

    startLoading();

    dispatch(createNextPhraseBySlice({
      storyId: currentStory?._id,
      currentPhrase: currentPhrase,
      dto: {
        storyId: currentStory?._id,
        alias: 'Заголовок',
        text: 'Новая фраза',
        sceneId: currentPhrase?.sceneId,
        characterId: currentPhrase?.characterId,
        cost: '0',
        previousPhrases: [currentPhrase?._id],
        nextPhrases: [],
      }
    }));

    stopLoading();
  };

  const handleCreatePrevPhrase = async () => {
    if (!currentStory?._id) return;
    if (!currentPhrase?._id) return;

    startLoading();

    dispatch(createPrevPhraseBySlice({
      storyId: currentStory?._id,
      currentPhrase: currentPhrase,
      dto: {
        storyId: currentStory?._id,
        alias: 'Заголовок',
        text: 'Новая фраза',
        sceneId: currentPhrase?.sceneId,
        characterId: currentPhrase?.characterId,
        cost: '0',
        previousPhrases: [],
        nextPhrases: [currentPhrase?._id],
      }
    }));

    stopLoading();
  };

  useEffect(() => {
    if (!currentStory?._id) return;
    if (!currentPhrase?._id) return;
    if (!editorPhraseNext?._id) return;
    if (!addNext) return;
    startLoading();

    console.log('editorPhraseNext');

    const toExec = async () => {
      if (editorPhraseNext && currentPhrase?.nextPhrases) {
        if (currentPhrase.nextPhrases.includes(editorPhraseNext._id)) {
          // TODO popup nothing to update
          stopLoading();
          return;
        }

        const currentUpdated = await updateCreatorPhrases({
          storyId: currentStory._id,
          phraseId: currentPhrase._id,
          dto: {
            nextPhrases: [...currentPhrase.nextPhrases, editorPhraseNext._id]
          },
        });

        if (currentUpdated) {
          dispatch(setCurrentPhrase(currentUpdated));
          dispatch(setAllPhrases([...allPhrases.filter(item => item._id !== currentPhrase._id), currentUpdated]));
        }

        const previusUpdated = await updateCreatorPhrases({
          storyId: currentStory._id,
          phraseId: editorPhraseNext._id,
          dto: {
            previousPhrases: [...(editorPhraseNext.previousPhrases || []), currentPhrase._id]
          },
        });

        if (previusUpdated) {
          dispatch(setAllPhrases([...allPhrases.filter(item => item._id !== editorPhraseNext._id), previusUpdated]));
        }

        dispatch(setEditorPhraseNext(null));
        setAddNext(false);
      }
    };

    toExec();
    stopLoading();
  }, [editorPhraseNext])

  useEffect(() => {
    if (!currentStory?._id) return;
    if (!currentPhrase?._id) return;
    if (!editorPhrasePrev?._id) return;
    if (!addPrev) return;
    startLoading();

    console.log('editorPhrasePrev');

    const toExec = async () => {
      if (editorPhrasePrev && currentPhrase?.previousPhrases) {
        if (currentPhrase.previousPhrases.includes(editorPhrasePrev._id)) {
          // TODO popup nothing to update
          stopLoading();
          return;
        }

        const currentUpdated = await updateCreatorPhrases({
          storyId: currentStory._id,
          phraseId: currentPhrase._id,
          dto: {
            previousPhrases: [...currentPhrase.previousPhrases, editorPhrasePrev._id]
          },
        })

        if (currentUpdated) {
          dispatch(setCurrentPhrase(currentUpdated));
          dispatch(setAllPhrases([...allPhrases.filter(item => item._id !== currentUpdated._id), currentUpdated]));
        }

        const previusUpdated = await updateCreatorPhrases({
          storyId: currentStory._id,
          phraseId: editorPhrasePrev._id,
          dto: {
            nextPhrases: [...(editorPhrasePrev.nextPhrases || []), currentPhrase._id]
          },
        })

        if (previusUpdated) {
          dispatch(setAllPhrases([...allPhrases.filter(item => item._id !== editorPhrasePrev._id), previusUpdated]));
        }

        dispatch(setEditorPhrasePrev(null));
        setAddPrev(false);
      }
    };

    toExec();
    stopLoading();
  }, [editorPhrasePrev])

  const handleAddPrevPhrase = async () => {
    setAddPrev(true);
    dispatch(setModal(ModalPageEnum.phraseList));
  };

  const handleAddNextPhrase = async () => {
    setAddNext(true);
    dispatch(setModal(ModalPageEnum.phraseList));
  };

  const getCharacterImgUrl = (id: string | undefined) => {
    if (!id) return;
    if (allCharacters?.length) {
      const imgUrl = allCharacters.find(item => item._id === id)?.imageUrl;
      return imgUrl;
    }
  };

  const getSceneImgUrl = (id: string | undefined) => {
    if (!id) return;
    if (allCharacters?.length) {
      const imgUrl = allScenes.find(item => item._id === id)?.imageUrl;
      return imgUrl;
    }
  };

  return (
    <SplitLayout
      modal={modal}
      popout={popout}
    >
      <SplitCol width="100%" maxWidth="560px">
        <Panel id={id}>
          <PanelHeader
            before={
              <PanelHeaderBack
                onClick={() => {
                  dispatch(setStory("phraseEditor"));
                }}
              />
            }
          >
            Связи между фразами
          </PanelHeader>

          <Div>
            <Div>
              <ButtonGroup mode="vertical" gap="m" style={{ minWidth: '100%' }}>
                <Button onClick={handleCreatePrevPhrase} size="s" stretched appearance="neutral" before={<Icon24Add />}>
                  Создать фразу
                </Button>
                <Button onClick={handleAddPrevPhrase} size="s" stretched appearance="neutral" before={<Icon24CommentAddBadgeOutline />}>
                  Выбрать готовую
                </Button>
              </ButtonGroup>
            </Div>

            {linkedListPrev?.map((item, idx) => {
              return <Cell
                style={{
                  boxShadow: 'rgba(255,200,200,0.12) 0px -8px 8px',
                  borderRadius: '10px 10px 0px 0px',
                }}
                key={item?._id + idx + Math.random()}
                mode="removable"
                onRemove={() => removeFromPrevList(item)}
              >
                <SimpleCell
                  before={<Icon24ArrowUp />}
                  onClick={() => {
                    const ph = allPhrases.find(ph => ph._id === item._id);
                    if (ph) {
                      dispatch(setCurrentPhrase(ph));
                    }
                  }}
                  multiline
                  style={{ minWidth: '50vw', width: '100%', padding: 0, alignItems: 'flex-start' }}
                >
                  <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'row' }}>
                    <Image
                      size={72}
                      style={{ background: 'rgba(0,0,0,0)' }}
                      src={SERVER_IMAGE_API + getSceneImgUrl(item.sceneId)}
                      borderRadius={"s"}
                    >
                    </Image>
                    <Image
                      size={72}
                      style={{ position: 'absolute', background: 'rgba(0,0,0,0)' }}
                      src={SERVER_IMAGE_API + getCharacterImgUrl(item.characterId)}
                      borderRadius={"s"}
                    >
                      {
                        !!item?.cost &&
                        <Image.Badge>
                          <Counter mode='contrast'>{item?.cost}<Icon16KeyOutline /></Counter>
                        </Image.Badge>
                      }
                    </Image>
                    <InfoRow style={{ paddingLeft: 10 }} header={item.alias}>{item.text}</InfoRow>
                  </div>
                </SimpleCell>
              </Cell>
            })
            }

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '15px', opacity: 0.5 }}>
              Предыдущие фразы
              <Icon24ArrowUp />
            </div>

            <div
              style={{
                boxShadow: 'rgba(200,255,200,0.5) 0px 0px 36px',
                borderRadius: 10,
                border: '2px solid white',
                zIndex: 1,
              }}
            >
              {currentPhrase &&
                <div
                  style={{ display: 'flex', alignItems: 'flex-start', padding: 5 }}
                  // after={!!currentPhrase?.cost && <Counter>{currentPhrase?.cost}<Icon16KeyOutline /></Counter>}
                  key={currentPhrase?._id}
                >
                  <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
                    <Image
                      size={96}
                      style={{ background: 'rgba(0,0,0,0)' }}
                      src={SERVER_IMAGE_API + getSceneImgUrl(currentPhrase.sceneId)}
                      borderRadius={"s"}
                    >
                    </Image>
                    <Image
                      size={96}
                      style={{ position: 'absolute', background: 'rgba(0,0,0,0)' }}
                      src={SERVER_IMAGE_API + getCharacterImgUrl(currentPhrase.characterId)}
                      borderRadius={"s"}
                    >
                      {
                        !!currentPhrase?.cost &&
                        <Image.Badge>
                          <Counter mode='contrast'>{currentPhrase?.cost}<Icon16KeyOutline /></Counter>
                        </Image.Badge>
                      }
                    </Image>

                    {currentPhrase?._id === currentPhrase._id &&
                      <Button
                        style={{ marginTop: 5, borderRadius: 3 }}
                        size="s"
                        stretched
                        appearance='neutral'
                        onClick={() => {
                          dispatch(setModal(ModalPageEnum.phrase));
                        }}
                      ><Icon24PenOutline /></Button>
                    }
                  </div>


                  <InfoRow
                    style={{ padding: '0px 10px 10px 10px', width: '100%' }}
                    header={currentPhrase.alias}
                  >
                    {currentPhrase.text}
                  </InfoRow>


                  {currentPhrase.moderationStatus === ModerationStatusEnum.invalid && (
                    <FormStatus header={currentPhrase?.reason || "Причина не указана"} mode="error">
                      {currentPhrase?.comment || "Комментариев модератора нет"}
                    </FormStatus>
                  )}
                </div>
              }

            </div>

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '15px', opacity: 0.5 }}>
              Следующие фразы
              <Icon24ArrowDownOutline />
            </div>

            <Group separator='hide'>
              {linkedListNext?.map((item, idx) => {
                return <Cell
                  style={{
                    boxShadow: 'rgba(255,200,200,0.12) 0px 8px 8px',
                    borderRadius: '0px 0px 10px 10px',
                  }}
                  key={item?._id + idx + Math.random()}
                  mode="removable"
                  onRemove={() => removeFromNextList(item)}
                >
                  <SimpleCell
                    before={<Icon24ArrowDownOutline />}
                    onClick={() => {
                      const ph = allPhrases.find(ph => ph._id === item._id);
                      if (ph) {
                        dispatch(setCurrentPhrase(ph));
                      }
                    }}
                    multiline
                    style={{ minWidth: '50vw', width: '100%', padding: 0, alignItems: 'flex-start' }}
                  >
                    <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'row' }}>
                      <Image
                        size={72}
                        style={{ background: 'rgba(0,0,0,0)' }}
                        src={SERVER_IMAGE_API + getSceneImgUrl(item.sceneId)}
                        borderRadius={"s"}
                      >
                      </Image>
                      <Image
                        size={72}
                        style={{ position: 'absolute', background: 'rgba(0,0,0,0)' }}
                        src={SERVER_IMAGE_API + getCharacterImgUrl(item.characterId)}
                        borderRadius={"s"}
                      >
                        {
                          !!item?.cost &&
                          <Image.Badge>
                            <Counter mode='contrast'>{item?.cost}<Icon16KeyOutline /></Counter>
                          </Image.Badge>
                        }
                      </Image>
                      <InfoRow style={{ paddingLeft: 10 }} header={item.alias}>{item.text}</InfoRow>
                    </div>
                  </SimpleCell>
                </Cell>
              })
              }


              <Div>
                <ButtonGroup mode="vertical" gap="m" style={{ minWidth: '100%' }}>
                  <Button onClick={handleCreateNextPhrase} size="s" stretched appearance="neutral" before={<Icon24Add />}>
                    Создать фразу
                  </Button>
                  <Button onClick={handleAddNextPhrase} size="s" stretched appearance="neutral" before={<Icon24CommentAddBadgeOutline />}>
                    Выбрать готовую
                  </Button>
                </ButtonGroup>
              </Div>
            </Group>
          </Div>
        </Panel>
      </SplitCol>
    </SplitLayout >
  );
};