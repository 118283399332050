import { Icon24ShareOutline } from "@vkontakte/icons";
import bridge, { RequestIdProp } from "@vkontakte/vk-bridge";
import { Alert, Button, Link } from "@vkontakte/vkui";
import { RootState } from "../app/store";
import { UseAppSelector } from "../hooks/hooks";
import { IUserDocument } from "../features/user/user.slice";
import { readerGiftForAds } from "../Api/Reader/reader.api";
export interface IVkShareButton {
  requestKey?: string;
  stretched?: boolean;
}

export const VkShareButton = ({ requestKey, stretched, setPopout }: any) => {
  const currentUserId = UseAppSelector(
    (state: RootState) => state.user.selectedUserId
  );

  const sendRewardToUser = async () => {
    if (!currentUserId) {
      handleAlert(
        "Ошибка",
        "Текущий пользователь не найден. Попробуй перезайти в игру!"
      );
      return;
    }

    const successPayment: IUserDocument | undefined = await readerGiftForAds();

    return !!successPayment;
  };

  const args: { [key: string]: RequestIdProp } = {};

  if (requestKey) {
    args.requestKey = requestKey;
  }

  const handleAlert = (header: string, text: string) => {
    setPopout(
      <Alert
        actions={[
          {
            title: "Хорошо",
            autoClose: true,
            mode: "cancel",
          },
        ]}
        actionsLayout="vertical"
        onClose={() => setPopout(null)}
        header={header}
        text={
          <div style={{ width: 304 }}>
            {text}. В случае проблем напишите нам{" "}
            <Link href="https://vk.com/im?sel=-219932114">в группу</Link>
          </div>
        }
      />
    );
  };

  const onClick = () => {
    bridge
      .send("VKWebAppShare", { link: "https://vk.com/games/app51613870" })
      .then(async (data: { [key: string]: any }) => {
        if (data?.result?.length) {
          const paymentSuccess = await sendRewardToUser();

          if (paymentSuccess) {
            handleAlert(
              "Успех",
              "Вы успешно поделились игрой, вы получите дополнительные ключи. Награду возможно получить пару раз в день"
            );
          } else {
            handleAlert(
              "Ошибка",
              "Вы успешно поделились игрой, но ключи не были выданы, попробуй позже еще раз"
            );
          }
        }
      })
      .catch((error) => {
        handleAlert(
          "Ошибка",
          "В данный момент поделиться игрой нельзя, попробуйте позже"
        );
        console.error(error);
      });
  };

  return (
    <Button
      appearance="accent"
      before={<Icon24ShareOutline />}
      onClick={onClick}
      size="l"
      stretched={stretched}
    >
      Поделиться +3🗝
    </Button>
  );
};
