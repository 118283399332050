import { ReaderGameAboutDto, ReaderGamePageDto } from "../../Interfaces";
import { IStoryDocument } from "../../features/story/story.slice";
import { IUserDocument } from "../../features/user/user.slice";
import api from "../config";

export const readerPayForChoice = async ({
	phraseId,
	coins,
}: {
	phraseId: string,
	coins: number,
}): Promise<IUserDocument | undefined> => {
	try {
		const response = await api.put(`/reader/payment/pay`, {
			phraseId,
			coins,
		});

		return response?.data;
	} catch (error) {
		console.error(error);
	}
};

export const readerCheckAdsLimit = async (): Promise<boolean | undefined> => {
	try {
		const response = await api.get(`/reader/payment/check-ads-limit`);

		return response?.data;
	} catch (error) {
		console.error(error);
	}
};

export const readerGiftForAds = async (): Promise<IUserDocument | undefined> => {
	try {
		const response = await api.put(`/reader/payment/gift`);

		return response?.data;
	} catch (error) {
		console.error(error);
	}
};

export const getReaderUser = async (): Promise<IUserDocument | undefined> => {
	try {
		const response = await api.get(`/reader/game/user`);

		return response?.data;
	} catch (error) {
		console.error(error);
	}
};

export const getReaderStoryAbout = async ({
	storyId,
}: {
	storyId: string,
}): Promise<ReaderGameAboutDto | undefined> => {
	try {
		const response = await api.get(`/reader/game/story-about`, {
			params: {
				storyId,
			}
		});

		return response?.data;
	} catch (error) {
		console.error(error);
	}
};

export const getReaderAllStories = async (): Promise<IStoryDocument[] | undefined> => {
	try {
		const response = await api.get(`/reader/game/story-list`);

		return response?.data;
	} catch (error) {
		console.error(error);
	}
};

export const getReaderUserStoryLibrary = async (): Promise<IStoryDocument[] | undefined> => {
	try {
		const response = await api.get(`/reader/game/story-progress-list`);

		return response?.data;
	} catch (error) {
		console.error(error);
	}
};

export const getReaderAuthorStoryList = async (): Promise<IStoryDocument[] | undefined> => {
	try {
		const response = await api.get(`/reader/game/story-author-list`);

		return response?.data;
	} catch (error) {
		console.error(error);
	}
};

export const getReaderPageData = async ({
	storyId,
	phraseId,
}: {
	storyId: string,
	phraseId?: string,
}): Promise<ReaderGamePageDto | undefined> => {
	try {
		const response = await api.get(`/reader/game/story-page`, {
			params: {
				storyId,
				phraseId,
			}
		});

		return response?.data;
	} catch (error) {
		console.error(error);
	}
};

export const setStoryStar = async ({
	storyId,
	star,
}: {
	storyId: string,
	star: number,
}): Promise<number | undefined> => {
	try {
		const response = await api.post(`/reader/game/story-star`, {
			storyId,
			star,
		});

		return response?.data;
	} catch (error) {
		console.error(error);
	}
};

export const resetReaderStoryProgress = async ({
	storyId,
}: {
	storyId: string,
}): Promise<string | undefined> => {
	try {
		const response = await api.post(`/reader/game/reset-story-progress`, {
			storyId,
		});

		return response?.data;
	} catch (error) {
		console.error(error);
	}
};

export const removeStoryProgress = async ({
	storyId,
}: {
	storyId: string,
}): Promise<ReaderGamePageDto | undefined> => {
	try {
		const response = await api.delete(`/reader/game/delete-story-progress`, {
			params: {
				storyId,
			}
		});

		return response?.data;
	} catch (error) {
		console.error(error);
	}
};



export default api;
