import {
  ChangeEvent,
  FormEventHandler,
  useEffect,
  useRef,
  useState,
} from "react";
import * as Yup from "yup";
import {
  Panel,
  PanelHeader,
  Group,
  SplitLayout,
  FormItem,
  FormLayout,
  FormLayoutGroup,
  Input,
  File,
  Textarea,
  Button,
  ButtonGroup,
  ContentCard,
  List,
  Cell,
  Header,
  Separator,
  Spacing,
  Alert,
  ScreenSpinner,
  Div,
  PanelHeaderBack,
} from "@vkontakte/vkui";
import { useFormik } from "formik";
import {
  Icon16Like,
  Icon16LikeOutline,
  Icon24Camera,
  Icon28BubbleLolOutline,
  Icon28CameraOutline,
  Icon28HappyFaceOutline,
} from "@vkontakte/icons";
import { UseAppDispatch, UseAppSelector } from "../hooks/hooks";
import { setStory } from "../features/navigation/navigation.slice";
import {
  ICreateStoryDocument,
  setSelectedStory,
} from "../features/story/story.slice";
import { createStory, deleteStory, updateStory } from "../Api/story.api";
import {
  IMAGE_TYPES,
  MAX_IMAGE_SIZE,
  SERVER_IMAGE_API,
  TAGS_EXISTED,
} from "../constants/constants";
import { RootState } from "../app/store";
import { isFileTooBig } from "../utils/isFileTooBig";
import { isFileOfImage } from "../utils/isFileOfImage";
import { getReaderStoryAbout } from "../Api/Reader/reader.api";
import { ReaderGameAboutDto } from "../Interfaces";
import { badWordsCheck } from "../utils/text";
import { askCreatorStoryToModerate } from "../Api/Creator/creator.api";
import { ChipsSelect } from "@vkontakte/vkui/dist/components/ChipsSelect/ChipsSelect";

interface IStoryTags extends Array<{ value: string; label: string }> { }

const MAX_STORY_ABOUT_LENGTH = 100;

export const StoryEditorPanel = ({ id }: { id: string }) => {
  const [photoFile, setPhotoFile] = useState<File | null>(null);
  const [popout, setPopout] = useState<any>(null);
  const [moderationStatus, setModerationStatus] = useState<string>('Запрос на модерацию');
  const [storyData, setStoryData] = useState<ReaderGameAboutDto>();
  const [loadingStatus, setLoadingStatus] = useState<
    "loading" | "cancelable" | "done" | "error" | undefined
  >("loading");
  const fileRef = useRef<HTMLInputElement>(null);
  const nameRef = useRef<HTMLInputElement>(null);

  const dispatch = UseAppDispatch();
  const currentUserId = UseAppSelector(
    (state: RootState) => state.user.selectedUserId
  );

  const selectedStory = UseAppSelector(
    (state: RootState) => state.story.selectedStory
  );

  useEffect(() => {
    startLoading();

    if (selectedStory?.askForModeration === true) {
      setModerationStatus('Отменить модерацию');
    }
    if (selectedStory?.askForModeration === false) {
      setModerationStatus('Отправить на модерацию');
    }

    const fetchStory = async () => {
      if (selectedStory?._id) {
        const result: ReaderGameAboutDto | undefined =
          await getReaderStoryAbout({ storyId: selectedStory._id });
        if (result) {
          setStoryData(result);
          result.story.name &&
            formik.setFieldValue("storyName", result.story.name);
          result.story.imageUrl &&
            formik.setFieldValue("storyImageUrl", result.story.imageUrl);
          result.story.about &&
            formik.setFieldValue("storyAbout", result.story.about);
          if (result?.story?.tags) {
            const tags = result.story.tags.split(",").map((item) => {
              return {
                value: item,
                label: item,
              };
            });

            formik.setFieldValue("storyTags", tags);
          }
        }
      }
    };
    if (selectedStory) {
      fetchStory();
    }
    setLoadingStatus("done");
  }, [selectedStory]);

  const handleChangePhoto = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const files = (e.target as HTMLInputElement).files;
    files &&
      formik.setFieldValue("storyImageUrl", URL.createObjectURL(files[0]));
    files && setPhotoFile(files[0]);
  };

  const handleUpdateStory = async () => {
    startLoading();
    const storyData: ICreateStoryDocument = {
      name: formik.values.storyName,
      about: formik.values.storyAbout,
      imageUrl: formik.values.storyImageUrl || undefined,
      tags: formik.values.storyTags.map((item) => item.value).join(","),
      author: currentUserId ? currentUserId : undefined,
      ageLimit: 18,
    };

    photoFile && (storyData.photoFile = photoFile);

    try {
      if (selectedStory?._id) {
        const updatedStory = await updateStory(selectedStory._id, storyData);
        if (updatedStory) {
          formik.setFieldValue("storyImageUrl", updatedStory.imageUrl);
          setPhotoFile(null);
          if (updatedStory?.tags) {
            const serverTags = updatedStory.tags.split(",").map((item) => {
              return {
                value: item,
                label: item,
              };
            });
            formik.setFieldValue("storyTags", serverTags);
          }
        }
      }
      setLoadingStatus("done");
    } catch (error) {
      console.error(error);
      setLoadingStatus("error");
    }
  };

  const handleCreateStory = async () => {
    startLoading();
    const storyData: ICreateStoryDocument = {
      name: formik.values.storyName,
      about: formik.values.storyAbout,
      tags: formik.values.storyTags.map((item) => item.value).join(","),
      author: currentUserId ? currentUserId : undefined,
      ageLimit: 18,
      imageUrl: formik.values.storyImageUrl,
    };
    photoFile && (storyData.photoFile = photoFile);

    try {
      const newStory = await createStory(storyData);
      if (newStory) {
        formik.setFieldValue("storyImageUrl", newStory.imageUrl);
        setPhotoFile(null);
        dispatch(setSelectedStory(newStory));
      }
      setLoadingStatus("done");
    } catch (error) {
      console.error(error);
      setLoadingStatus("error");
    }
  };

  const handleDeleteStory = async () => {
    startLoading();
    try {
      if (selectedStory?._id) {
        const storyIsDeleted = await deleteStory(selectedStory._id);
        if (storyIsDeleted) {
          dispatch(setStory("author"));
        }
      }
      setLoadingStatus("done");
    } catch (error) {
      console.error(error);
      setLoadingStatus("error");
    }
  };

  const handleAskForModeration = async () => {
    if (!selectedStory?._id) return;

    const askStatus = await askCreatorStoryToModerate({ storyId: selectedStory._id })
    if (askStatus?.askForModeration === false) {
      setModerationStatus('Отправить на модерацию');
    } else {
      setModerationStatus('Отменить модерацию');
    }
  };

  const openDeletion = () => {
    setPopout(
      <Alert
        actions={[
          {
            title: "Закрыть",
            autoClose: true,
            mode: "cancel",
          },
          {
            title: "Удалить",
            autoClose: true,
            mode: "destructive",
            action: () => handleDeleteStory(),
          },
        ]}
        actionsLayout="horizontal"
        onClose={() => setPopout(null)}
        header="Удаление истории"
        text={`Вы уверены, что хотите удалить историю "${selectedStory?.name}"?`}
      />
    );
  };

  const startLoading = () => {
    setLoadingStatus("loading");
    setPopout(
      <ScreenSpinner
        onClick={() => setPopout(null)}
        size="large"
        state={loadingStatus}
      />
    );
  };

  useEffect(() => {
    if (loadingStatus !== "loading") {
      setTimeout(() => {
        setPopout(null);
      }, 250);
    }
  }, [loadingStatus]);

  const initialValues = {
    storyName: "",
    storyAbout: "",
    storyImageUrl: "",
    storyTags: [] as IStoryTags,
  };

  const validationSchema = Yup.object().shape({
    storyName: Yup.string()
      .min(2, "Должно быть не менее 2 символа")
      .max(30, "Должно быть не более 30 символов")
      .required("Это обязательное поле")
      .test(
        "text-include-bad-words",
        "В тексте есть неприличные слова",
        badWordsCheck(nameRef)
      ),
    storyAbout: Yup.string().max(100, "Должно быть не более 100 символа"),
    storyImageUrl: Yup.mixed()
      .test(
        "is-file-too-big",
        "Файл должен быть не более 10МБ",
        isFileTooBig(fileRef, MAX_IMAGE_SIZE)
      )
      .test(
        "is-file-of-correct-type",
        "Данный тип файла не поддерживается",
        isFileOfImage(fileRef)
      ),
    storyTags: Yup.array().of(
      Yup.object().shape({
        value: Yup.string(),
        label: Yup.string(),
      })
    ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    onSubmit: () => { },
  });

  const tagsChipsProps = {
    value: formik.values.storyTags,
    onChange: (values: IStoryTags) => {
      formik.setFieldValue("storyTags", values);
    },
    options: TAGS_EXISTED,
    top: "Выберите или добавьте теги/жанры",
    placeholder: "Не выбраны",
    creatable: true,
    creatableText: "Создать значение",
  };

  return (
    <SplitLayout
      popout={popout}
      style={{ maxWidth: 512, margin: "auto", justifyContent: "center" }}
    >
      <Panel id={id}>
        <PanelHeader
          before={
            <>
              <PanelHeaderBack
                onClick={() => {
                  dispatch(setStory("author"));
                }}
              />
              {/* <PanelHeaderButton disabled={true} aria-label="filter">
                <Icon28SettingsOutline />
              </PanelHeaderButton> */}
              <Div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginLeft: 15,
                  paddingLeft: 0,
                  marginRight: 0,
                  paddingRight: 0,
                }}
              >
                <Div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginLeft: 0,
                    paddingLeft: 0,
                    marginRight: 0,
                    paddingRight: 0,
                  }}
                >
                  {Array.from({ length: 5 }, (v, i) => i + 1)?.map(
                    (el, idx) => {
                      if (el <= Math.round(storyData?.story?.stars || 0)) {
                        return <Icon16Like key={idx} />;
                      } else {
                        return <Icon16LikeOutline key={idx} />;
                      }
                    }
                  )}
                </Div>
              </Div>
            </>
          }
        >
          {storyData?.story?.name}
        </PanelHeader>

        <FormLayout
          onSubmit={formik.handleSubmit as FormEventHandler<HTMLElement>}
        >
          <FormLayoutGroup mode="vertical">
            <FormItem
              htmlFor="storyName"
              top="Название истории"
              status={
                formik.touched.storyName && formik.errors.storyName
                  ? "error"
                  : "default"
              }
              bottom={formik.touched.storyName && formik.errors.storyName}
            >
              <Input
                id="storyName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.storyName}
                getRef={nameRef}
              />
            </FormItem>

            <FormItem
              top="Обложка истории"
              status={
                formik.touched.storyImageUrl && formik.errors.storyImageUrl
                  ? "error"
                  : "default"
              }
              bottom={
                formik.touched.storyImageUrl && formik.errors.storyImageUrl
              }
            >
              <File
                id="storyImageUrl"
                getRef={fileRef}
                accept={IMAGE_TYPES.join(", ")}
                appearance="accent"
                mode="tertiary"
                onChange={(e) => {
                  handleChangePhoto(e);
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                before={<Icon24Camera role="presentation" />}
                size="l"
                stretched={true}
              >
                {!!formik.values.storyImageUrl
                  ? "Заменить обложку"
                  : "Загрузить обложку"}
              </File>

              {formik.values.storyImageUrl && (
                <ContentCard
                  onClick={() => { }}
                  src={
                    photoFile
                      ? URL.createObjectURL(photoFile)
                      : SERVER_IMAGE_API + formik.values.storyImageUrl
                  }
                  alt="Выбранная картинка"
                  subtitle="Картинка для обложки"
                  style={{ maxWidth: "70%", margin: "auto" }}
                />
              )}
            </FormItem>

            <FormItem
              top="Описание истории"
              status={formik.errors.storyAbout ? "error" : "default"}
              bottom={
                formik.errors.storyAbout ||
                (!!formik.values.storyAbout.length &&
                  `${formik.values.storyAbout.length}/${MAX_STORY_ABOUT_LENGTH}`)
              }
            >
              <Textarea
                id="storyAbout"
                value={formik.values.storyAbout}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Моя история такая классная, потому что она о..."
              />
            </FormItem>

            <FormItem htmlFor="tags" top="Выберите или добавьте теги/жанры">
              <ChipsSelect id="tags" {...tagsChipsProps} />
            </FormItem>

            <FormItem>
              <ButtonGroup mode="horizontal" gap="s" align="center" stretched>
                {selectedStory ? (
                  <ButtonGroup stretched mode="vertical">
                    <ButtonGroup stretched>
                      <Button
                        size="l"
                        appearance="neutral"
                        stretched
                        onClick={handleUpdateStory}
                        disabled={!formik.isValid || !formik.dirty}
                      >
                        Обновить
                      </Button>
                      <Button
                        size="l"
                        appearance="negative"
                        stretched
                        onClick={openDeletion}
                      >
                        Удалить
                      </Button>
                    </ButtonGroup>
                    <Button
                      size="l"
                      disabled={moderationStatus === 'На проверке (1-4 дня)'}
                      appearance="overlay"
                      stretched
                      onClick={handleAskForModeration}
                    >

                      {moderationStatus}
                    </Button>
                  </ButtonGroup>
                ) : (
                  <Button
                    size="l"
                    appearance="positive"
                    stretched
                    onClick={handleCreateStory}
                    disabled={!formik.isValid || !formik.dirty}
                  >
                    Создать
                  </Button>
                )}
              </ButtonGroup>
            </FormItem>
          </FormLayoutGroup>

          {selectedStory && (
            <>
              <Spacing size={16}>
                <Separator />
              </Spacing>
              <Group header={<Header mode="secondary">Данные истории</Header>}>
                <Div>
                  Любая история состоит из 3 частей. Это фоны или сцены, на
                  которых разворачивается диалог. Так же это персонажи,
                  действующие лица истории, которые общаются между собой. И
                  собственно сами диалоги.
                </Div>
                <List>
                  <Cell
                    onClick={() => {
                      dispatch(setStory("characterEditor"));
                    }}
                    before={
                      <Icon28HappyFaceOutline
                        style={{ color: "currentcolor" }}
                      />
                    }
                  // indicator={<Counter>+</Counter>}
                  >
                    Персонажи
                  </Cell>
                  <Cell
                    onClick={() => {
                      dispatch(setStory("sceneEditor"));
                    }}
                    before={
                      <Icon28CameraOutline style={{ color: "currentcolor" }} />
                    }
                  // indicator={<Counter mode="primary">+</Counter>}
                  >
                    Сцены
                  </Cell>
                  <Cell
                    onClick={() => {
                      dispatch(setStory("phraseEditor"));
                    }}
                    before={
                      <Icon28BubbleLolOutline
                        style={{ color: "currentcolor" }}
                      />
                    }
                  // indicator={<Counter>+</Counter>}
                  >
                    Диалоги - древо фраз
                  </Cell>
                </List>
              </Group>
            </>
          )}
        </FormLayout>
      </Panel>
    </SplitLayout>
  );
};
