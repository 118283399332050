import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cookieGetUserId } from "../../Api/config";

export interface IUserDocument {
	_id: string;
	nickname: string;
	email: string;
	password: string;
	age: number;
	coins: number;
	stories?: string[];
}

export interface ICreateUserDocument {
	_id?: string;
	nickname?: string;
	email?: string;
	password?: string;
	age?: number;
	coins?: number;
	stories?: string[];
}

interface UserState {
	allUsers: IUserDocument[];
	selectedUserId: string | null;
	currentUser?: IUserDocument | null | undefined;
}

const initialState: UserState = {
	allUsers: [],
	selectedUserId: cookieGetUserId() || null,
	currentUser: null,
};

export const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		setCurrentUser: (state, action: PayloadAction<IUserDocument | null>) => {
			state.currentUser = action.payload;
		},
		setSelectedUserId: (state, action: PayloadAction<string | null>) => {
			state.selectedUserId = action.payload;
		},
		setAllUsers: (state, action: PayloadAction<IUserDocument[]>) => {
			state.allUsers = action.payload;
		},
	},
});

export const { setSelectedUserId, setAllUsers, setCurrentUser } = userSlice.actions;

export default userSlice.reducer;
