import {
  cookieGetStoryId,
  cookieSetStoryId,
  cookieGetUserId,
  cookieSetUserId,
  cookieGetJwtToken,
  cookieSetAuthToken,
} from "../Api/config";

export function parseDataFromJwtToken(token: string) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

/**
 * После перезагрузки страницы устанавливает базовые данные из куки
 */
export function cookieSetAppState(): void {
  const accessToken = cookieGetJwtToken();
  accessToken && cookieSetAuthToken(accessToken);

  // TODO возвращать пользователя на место после сбоя или перезагрузки
  const storyId = cookieGetStoryId();
  storyId && cookieSetStoryId(storyId);

  const userId = cookieGetUserId();
  userId && cookieSetUserId(userId);
}
