import { IFrameTransporterProps } from './FrameTransporter.types';
import { Frame } from '../Frame/Frame';
import styles from './FrameTransporter.module.scss';
import { useEffect, useState } from 'react';

export function FrameTransporter(props: IFrameTransporterProps): JSX.Element {
  const [currentProps, setCurrentProps] = useState<IFrameTransporterProps | null>(null)
  const [current, setCurrent] = useState(0)
  const [fadeOut, setFadeOut] = useState(false)

  useEffect(() => {
    setFadeOut(true)
    const timeout = setTimeout(() => {
      setFadeOut(false)
      setCurrentProps(props)
      setCurrent((prev) => prev + 1)
    }, 1000)
    return () => {
      setFadeOut(false)
      clearTimeout(timeout)
    }
  }, [props])

  return (
    <div className={styles.root} >
      <Frame
        {...currentProps}
        key={String(current)}
        fadeOut={fadeOut}
        effects={props.effects}
      />
    </div>
  )
}
