import { ChangeEvent, FormEventHandler, useEffect, useRef, useState } from 'react'
import cn from 'classnames'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import {
  Button,
  ButtonGroup,
  File,
  FormItem,
  FormLayout,
  FormLayoutGroup,
  Input,
} from '@vkontakte/vkui'

import { ISceneEditFormProps } from './SceneEditForm.types'
import styles from './SceneEditForm.module.scss'
import { isFileTooBig } from '../../utils/isFileTooBig'
import { isFileOfImage } from '../../utils/isFileOfImage'
import { EFFECT_PRESETS } from '../../constants/effectPresets'
import { IMAGE_TYPES, MAX_IMAGE_SIZE, SERVER_IMAGE_API } from '../../constants/constants'
import { Icon24Camera } from '@vkontakte/icons'
import { Frame } from '../../Components/Frame/Frame'
import { prepareEffects } from '../../utils/prepareEffects'
import { IHandleCreateSceneProps, IHandleUpdateSceneProps } from '../../Panels/SceneEditorPanel/SceneEditorPanel.types'
import { ChipsSelect } from '@vkontakte/vkui/dist/components/ChipsSelect/ChipsSelect'

export function SceneEditForm({
  className,
  handleCreate,
  handleDelete,
  handleUpdate,
  isLoading,
  scene,
}: ISceneEditFormProps): JSX.Element {
  const [photoFile, setPhotoFile] = useState<File | null>(null)
  const fileRef = useRef<HTMLInputElement>(null)
  const nameRef = useRef<HTMLInputElement>(null)

  const initialValues = {
    effects: prepareEffects(scene?.effects || []),
    imageUrl: scene?.imageUrl || '',
    name: scene?.name || '',
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Должно быть не менее 2 символа')
      .max(30, 'Должно быть не более 30 символов')
      .required('Это обязательное поле'),
      // .test(
      //   'text-include-bad-words',
      //   'В тексте есть неприличные слова'
      //   // badWordsCheck(nameRef)
      // ),
    imageUrl: Yup.mixed()
      .test(
        'is-file-too-big',
        'Файл должен быть не более 10МБ',
        isFileTooBig(fileRef, MAX_IMAGE_SIZE)
      )
      .test(
        'is-file-of-correct-type',
        'Данный тип файла не поддерживается',
        isFileOfImage(fileRef)
      )
      .required('Необходимо загрузить изображение'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    onSubmit: () => { },
  })

  const effectPresets = Object.values(EFFECT_PRESETS)
    .filter(({type}) => {
      return !formik.values.effects.find(
        (selectedEffect: { type: string }) => {
          return selectedEffect?.type === type
        }
      )
    })
    .map(({ value, label, type }) => ({ value, label, type }))

  const handleChangePhoto = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const files = (e.target as HTMLInputElement).files
    files && formik.setFieldValue('imageUrl', URL.createObjectURL(files[0]))
    files && setPhotoFile(files[0])
  }

  const isUpdateDisabled = !formik.isValid || isLoading || !formik.dirty

  const sceneImageSrc = !!photoFile ? URL.createObjectURL(photoFile) : SERVER_IMAGE_API + scene?.imageUrl  

  return (
    <FormLayout
      className={cn(styles.root, className)}
      onSubmit={formik.handleSubmit as FormEventHandler<HTMLElement>}
    >
      <FormLayoutGroup mode='vertical'> 
        {
          (!!scene?.imageUrl || !!formik.values.imageUrl) && (
            <Frame
              key={SERVER_IMAGE_API + scene?.imageUrl}
              sceneImageUrl={sceneImageSrc}
              effects={formik.values.effects.map((effect)=> effect.value)}
            />
          )
        }
       
        <FormItem
          htmlFor='name'
          top='Название сцены'
          status={
            formik.touched.name && formik.errors.name ? 'error' : 'default'
          }
          bottom={formik.touched.name && formik.errors.name}
        >
          <Input
            id='name'
            placeholder='Например - задний дворик...'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            getRef={nameRef}
          />
        </FormItem>

        <FormItem
          status={
            photoFile && formik.touched.imageUrl && formik.errors.imageUrl
              ? 'error'
              : 'default'
          }
          bottom={
            photoFile && formik.touched.imageUrl && formik.errors.imageUrl
          }
        >
          <File
            accept={IMAGE_TYPES.join(', ')}
            appearance='accent'
            before={<Icon24Camera role='presentation' />}
            getRef={fileRef}
            id='imageUrl'
            mode='tertiary'
            onBlur={formik.handleBlur}
            onChange={(e) => {
              handleChangePhoto(e)
              formik.handleChange(e)
            }}
            size='l'
            stretched={true}
          >
            {!!scene?.imageUrl ? 'Заменить фон' : 'Добавить фон'}    
          </File>
        </FormItem>

        <FormItem>
          <ChipsSelect
            emptyText='Нет доступных эффектов'
            id='effects'
            onChange={(e) => {
              formik.setFieldValue('effects', e) 
            }}
            options={effectPresets} 
            placeholder='Эффекты не выбраны'
            value={formik.values.effects}
          />
        </FormItem>

        <FormItem>
          <ButtonGroup mode='horizontal' gap='s' align='right' stretched>
            {
              !!handleDelete && !!scene?._id && (
                <Button
                  size='m'
                  appearance='negative'
                  onClick={() => handleDelete(scene._id, scene.name)}
                >
                  Удалить
                </Button>
              )
            }

            {
              !!scene && handleUpdate && (
                <Button
                  size='m'
                  appearance='positive'
                  onClick={() => {
    
                    const data: IHandleUpdateSceneProps = {
                      _id: scene?._id || '',
                      effects: formik.values.effects.map((effect) => effect.value),
                      imageUrl: formik.values.imageUrl,
                      name: formik.values.name,
                    }
                    if (!!photoFile) data.photoFile = photoFile
                    handleUpdate(data)
                  }}
                  disabled={isUpdateDisabled}
                >
                  Сохранить
                </Button>
              )
            }

            {
              !scene && !!handleCreate && (
                <Button
                  size='m'
                  appearance='positive'
                  onClick={() => {
                    const data: IHandleCreateSceneProps = {
                      effects: formik.values.effects.map((effect) => effect.value),
                      imageUrl: formik.values.imageUrl,
                      name: formik.values.name,
                    }
                    if (!!photoFile) data.photoFile = photoFile
                    handleCreate(data)
                  }}
                  disabled={isUpdateDisabled}
                >
                  Создать
                </Button>
              )
            }

          </ButtonGroup>
        </FormItem>
      </FormLayoutGroup>
    </FormLayout >
  )
}

// function badWordsCheck(nameRef: any): Yup.TestFunction<string, Yup.AnyObject> {
//   throw new Error('Function not implemented.');
// }

