// export const SERVER_IMAGE_API = "http://localhost:3000/api/game/image/";
export const SERVER_IMAGE_API = "https://lazy.moscow/api/game/image/";

// export const API_BASE_URL = "http://localhost:3000/api/";
export const API_BASE_URL = "https://lazy.moscow/api/";

export const IMAGE_TYPES = ['.jpg', '.gif', '.png', '.jpeg', '.bmp']

export const MAX_IMAGE_SIZE = 10000 * 1024 * 1024 // 10 MB

export const TAGS_EXISTED = ([
  { value: "романтика", label: "романтика" },
  { value: "комедия", label: "комедия" },
  { value: "ужасы", label: "ужасы" },
  { value: "аниме", label: "аниме" },
  { value: "детектив", label: "детектив" },
  { value: "игра", label: "игра" },
  { value: "обучение", label: "обучение" },
  { value: "триллер", label: "триллер" },
]);

export enum MainMenuListEnum {
  news = 'news',
  contacts = 'contacts',
  market = 'market',
  history = 'history',
  settings = 'settings',
  menu = 'menu',
}

export const MAIN_MENU_LIST: { key: MainMenuListEnum, value: string, mobile: boolean }[] = [
  { key: MainMenuListEnum.news, value: "Новости", mobile: false },
  { key: MainMenuListEnum.contacts, value: "Контакты", mobile: false },
  { key: MainMenuListEnum.menu, value: "Меню", mobile: false },
  { key: MainMenuListEnum.market, value: "Магазин", mobile: true },
  { key: MainMenuListEnum.history, value: "История", mobile: true },
  { key: MainMenuListEnum.settings, value: "Настройки", mobile: true },
]; 