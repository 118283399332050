import { useState, useEffect } from 'react';
import styles from './Balloon.module.scss';
import { IBalloonProps } from './Balloon.types';

export const Balloon = function ({ message }: IBalloonProps): JSX.Element {
  const [index, setIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => {
        if (prevIndex < message.length) {
          return prevIndex + 1
        }
        return prevIndex
      })

    }, 2)
    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    setIndex(0)
  }, [message])

  return (
    <div className={styles.root}>
      <div className={styles.text}>
        {message?.substring(0, index)}
      </div>
      <div className={styles.ghost}>
        {message}
      </div>
    </div>
  )
}
