import {
  Panel,
  PanelHeader,
  Group,
  SplitLayout,
  PanelHeaderBack,
  Header,
  IconButton,
  SimpleCell,
  Div,
  PanelHeaderButton,
  Alert,
  ButtonGroup,
  Button,
} from "@vkontakte/vkui";
import { useEffect, useState } from "react";
import {
  Icon24KeyOutline,
  Icon24RefreshOutline,
  Icon24StorefrontOutline,
  Icon28ArrowRightOutline,
} from "@vkontakte/icons";
import { UseAppDispatch, UseAppSelector } from "../../hooks/hooks";
import { setPanel, setStory } from "../../features/navigation/navigation.slice";
import { SERVER_IMAGE_API } from "../../constants/constants";
import { RootState } from "../../app/store";
import { ReaderGameAnswersDto, ReaderGamePageDto } from "../../Interfaces";
import {
  getReaderPageData,
  getReaderUser,
  readerPayForChoice,
} from "../../Api/Reader/reader.api";
import { IUserDocument, setCurrentUser } from "../../features/user/user.slice";
import bridge from "@vkontakte/vk-bridge";
import { FrameTransporter } from "../../Components/FrameTransporter/FrameTransporter";
import { VkInviteButton } from "../../Components/InviteButton";
import { VkShareButton } from "../../Components/ShareButton";

export const ReaderStoryPagePanel = ({ id }: { id: string }) => {
  const [storyData, setStoryData] = useState<ReaderGamePageDto>();
  const [popout, setPopout] = useState<any>(null);
  const [refresh, setRefresh] = useState<boolean>(true);

  const dispatch = UseAppDispatch();

  const selectedStory = UseAppSelector(
    (state: RootState) => state.story.selectedStory
  );
  const currentUser = UseAppSelector(
    (state: RootState) => state.user.currentUser
  );

  useEffect(() => {
    refreshAll();
  }, [refresh]);

  const refreshAll = async () => {
    if (!selectedStory?._id) return;

    const updateStoryData = async () => {
      const story: ReaderGamePageDto | undefined = await getReaderPageData({
        storyId: selectedStory._id,
      });
      if (story) {
        setStoryData(story);
      }
    };

    const updateUserData = async () => {
      const user: IUserDocument | undefined = await getReaderUser();
      if (user) {
        dispatch(setCurrentUser(user));
      }
    };

    await updateStoryData();
    if (!currentUser) {
      await updateUserData();
    }

    if (storyData?.answers?.length === 0) {
      console.info("РЕКЛАМА ТУТ");
      //   if (Math.random() > 0.75) {
      bridge
        .send("VKWebAppShowBannerAd")
        .then((data: any) => {
          if (data?.result) {
            // TODO send to server
          }
        })
        .catch((error: any) => {
          // TODO send to server
          console.log(error);
        });
      //   }
    }
  };

  const openCaseNoCoins = () => {
    setPopout(
      <Alert
        actions={[
          {
            title: "Закрыть",
            autoClose: true,
            mode: "cancel",
          },
          {
            title: "Пополнить",
            autoClose: true,
            mode: "destructive",
            action: () => handleGetCoins(),
          },
        ]}
        actionsLayout="horizontal"
        onClose={() => setPopout(null)}
        header="Мало ключей 🗝"
        text={"Нужно больше ключей, чтобы пройти сюжет именно так"}
      />
    );
  };

  const handleGoToPhrase = async (nextPhrase: ReaderGameAnswersDto) => {
    if (!selectedStory?._id) return;

    if (Number(nextPhrase?.cost) !== 0) {
      if (!currentUser || !currentUser?.coins || currentUser.coins === 0) {
        openCaseNoCoins();
        return;
      }
      if (Number(currentUser.coins) - Number(nextPhrase.cost) < 0) {
        openCaseNoCoins();
        return;
      }

      const coinsTotal = currentUser.coins - Number(nextPhrase.cost);

      const userReduceCoinsResult = await readerPayForChoice({
        coins: Number(nextPhrase.cost),
        phraseId: nextPhrase?.phraseId,
      });

      if (userReduceCoinsResult) {
        dispatch(setCurrentUser({ ...currentUser, coins: coinsTotal }));
      } else {
        throw new Error("Cant get user for bye next phrase");
      }
    }

    const story: ReaderGamePageDto | undefined = await getReaderPageData({
      storyId: selectedStory._id,
      phraseId: nextPhrase.phraseId,
    });

    if (story) {
      setStoryData(story);
    }
  };

  const handleGetCoins = () => {
    dispatch(setStory("storyViewer"));
    dispatch(setPanel("epic"));
  };

  return (
    <SplitLayout
      popout={popout}
      style={{ maxWidth: 512, margin: "auto", justifyContent: "center" }}
    >
      <Panel id={id}>
        <PanelHeader
          before={
            <>
              <PanelHeaderBack
                onClick={() => {
                  dispatch(setStory("storyViewer"));
                  dispatch(setPanel("epic"));
                }}
              />
              <PanelHeaderButton aria-label="refresh">
                <Icon24RefreshOutline onClick={() => setRefresh(!refresh)} />
              </PanelHeaderButton>
            </>
          }
        >
          {selectedStory?.name}
        </PanelHeader>

        {
          !!storyData && (
            <FrameTransporter
              characterImageUrl={SERVER_IMAGE_API + storyData?.characterData.characterPhotoUrl}
              message={storyData?.phraseText}
              sceneImageUrl={SERVER_IMAGE_API + storyData?.sceneData.scenePhotoUrl}
              title={`${storyData?.characterData?.characterTitle} - ${storyData?.sceneData?.sceneTitle}`}
              effects={storyData?.sceneData.effects}
            />
          )
        }

        <Div style={{ marginBottom: 15 }}>
          <Group>
            {!!storyData?.answers?.length &&
              storyData.answers.map(
                (nextPhrase: ReaderGameAnswersDto, index: number) => (
                  <SimpleCell
                    key={index}
                    onClick={() => handleGoToPhrase(nextPhrase)}
                    multiline={true}
                    after={
                      <IconButton aria-label="Icon28ArrowRightOutline">
                        <Icon28ArrowRightOutline />
                      </IconButton>
                    }
                    subtitle={
                      +nextPhrase?.cost > 0
                        ? "Цена выбора: " + nextPhrase?.cost + "🗝"
                        : ""
                    }
                  >
                    {nextPhrase.buttonAlias}
                  </SimpleCell>
                )
              )}

            {!storyData?.answers?.length && (
              <>
                <Header mode="secondary">Это край данной истории</Header>
                <Div>
                  <Button
                    appearance="accent"
                    before={<Icon24StorefrontOutline />}
                    onClick={() => {
                      dispatch(setStory("storyViewer"));
                      dispatch(setPanel("epic"));
                    }}
                    size="l"
                    stretched
                  >
                    На главную
                  </Button>
                </Div>
                <Group
                  header={
                    <Header indicator={<Icon24KeyOutline />}>
                      Поделитесь впечатлениями!
                    </Header>
                  }
                >
                  <Div>
                    <ButtonGroup gap="m" mode={"vertical"} stretched>
                      <VkShareButton
                        setPopout={setPopout}
                        requestKey={ReaderStoryPagePanel.name}
                        stretched
                      />
                      <VkInviteButton
                        setPopout={setPopout}
                        requestKey={ReaderStoryPagePanel.name}
                        stretched
                      />
                    </ButtonGroup>
                  </Div>
                </Group>
              </>
            )}
          </Group>
        </Div>
      </Panel>
    </SplitLayout>
  );
};
