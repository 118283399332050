import { useEffect, useState } from 'react'

import {
  Alert,
  Group,
  Panel,
  PanelHeader,
  PanelHeaderBack,
  ScreenSpinner,
  SplitLayout,
} from '@vkontakte/vkui'

import { UseAppDispatch } from '../../hooks/hooks'
import { createCreatorScene, deleteCreatorScene, getCreatorSceneList, updateCreatorScene } from '../../Api/Creator/creator.api'
import { ICreateSceneDocument, ISceneDocument } from '../../features/scenes/scene.slice'
import { RootState } from '../../app/store'
import { setStory } from '../../features/navigation/navigation.slice'
import { UseAppSelector } from '../../hooks/hooks'
import { EntityCard } from '../../Components/EntityCard/EntityCard'
import { SceneEditForm } from '../../forms/SceneEditForm/SceneEditForm'
import { SERVER_IMAGE_API } from '../../constants/constants'
import { IHandleCreateSceneProps, IHandleUpdateSceneProps, ISceneEditorPanelProps } from './SceneEditorPanel.types'
import { prepareEffects } from '../../utils/prepareEffects'

// eslint-disable-next-line no-empty-pattern
export function SceneEditorPanel({}: ISceneEditorPanelProps): JSX.Element {
  const dispatch = UseAppDispatch()
  const [popout, setPopout] = useState<any>(null)
  const [sceneList, setSceneList] = useState<ISceneDocument[]>()
  const [refreshKey, setRefreshKey] = useState(0)
  
  const currentStory = UseAppSelector(
    (state: RootState) => state.story.selectedStory
  )

  const getAllScenes = async () => {
    if (!currentStory?._id) return;

    const list: ISceneDocument[] | undefined = await getCreatorSceneList(
      currentStory._id
    )

    if (list?.length) {
      setSceneList(list);
    }
  }

  useEffect(() => {
    getAllScenes()
  }, [])

  const startLoading = () => {
    setPopout(<ScreenSpinner size='large' />)
  }

  const stopLoading = () => {
    setPopout(null)
    setRefreshKey((refreshKey) => refreshKey + 1)
  }

  const handleCreateNewScene  = async (data: IHandleCreateSceneProps) => {
    startLoading()

    if (!currentStory?._id) {
      throw new Error('No current story id')
    }

    const sceneData: ICreateSceneDocument = {
      ...data,
      storyId: currentStory._id,
    }

    try {
      await createCreatorScene({ dto: sceneData });
      await getAllScenes()
      stopLoading()
      
    } catch (error) {
      console.error(error)
      stopLoading()
    }
  }

  const handleUpdateScene = async (data: IHandleUpdateSceneProps) => {
    startLoading()

    if (!currentStory?._id) {
      throw new Error('No current story id')
    }

    const sceneData: ICreateSceneDocument = {
      ...data,
      storyId: currentStory._id,
    }

    try {
      await updateCreatorScene({ dto: sceneData });
      await getAllScenes()
      stopLoading()
      
    } catch (error) {
      console.error(error)
      stopLoading()
    }
  }

  const handleDeleteScene = async (sceneId: string) => {
    startLoading()

    try {
      await deleteCreatorScene({ sceneId, })
      await getAllScenes()
    } catch (error) {
      console.error(error)
    }
    stopLoading()
  };

  const openDeletion = (sceneId: string, sceneName: string) => {
    setPopout(
      <Alert
        actions={[
          {
            title: "Закрыть",
            autoClose: true,
            mode: "cancel",
          },
          {
            title: "Удалить",
            autoClose: true,
            mode: "destructive",
            action: () => handleDeleteScene(sceneId),
          },
        ]}
        actionsLayout="horizontal"
        onClose={() => setPopout(null)}
        header="Удаление сцены"
        text={`Вы уверены, что хотите удалить сцену "${sceneName}"?`}
      />
    );
  };

  return (
    <SplitLayout popout={popout} style={{ maxWidth: 512, margin: "auto", justifyContent: "center" }}>
      <Panel>
        <PanelHeader
          before={
            <PanelHeaderBack
              onClick={() => {
                dispatch(setStory("storyEditor"))
              }}
            />
          }
        >
          Сцены
        </PanelHeader>

        <Group mode='plain'>
          <ul
            style={{
              listStyle: 'none',
              margin: '0 auto',
              maxWidth: 512,
              padding: 16,
            }
          }>
            <li
              style={{
                marginBottom: 8,
              }}
            >
              <EntityCard
                key={refreshKey}
                title={'Создать новую сцену'}
              >
                <SceneEditForm
                  handleCreate={handleCreateNewScene}
                  isLoading={!!popout}
                  key={refreshKey}
                />
              </EntityCard>
            </li>
            {(!!sceneList && !!sceneList?.length && sceneList?.map((scene: ISceneDocument, idx: number) => {
              return (
                <li
                  key={idx}
                  style={{
                    marginBottom: 8,
                  }}
                >
                  <EntityCard
                    imageUrl={SERVER_IMAGE_API + scene.imageUrl}
                    title={scene.name}
                    subtitle={prepareEffects(scene.effects || []).map((effect) => effect.label).join(', ') || ''}
                  >
                    <SceneEditForm
                      handleDelete={openDeletion}
                      handleUpdate={handleUpdateScene}
                      isLoading={!!popout}
                      key={refreshKey}
                      scene={scene}
                    />
                  </EntityCard>
                </li>
              )
            }))}
          </ul>
        </Group>
      </Panel>
    </SplitLayout>
  )
}
