import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface ISceneDocument {
	_id: string;
	name: string;
	imageUrl: string;
	storyId: string;
	effects?: string[];
	photoFile?: File;
}

export interface ICreateSceneDocument {
	_id?: string;
	name?: string;
	imageUrl?: string;
	storyId: string;
	effects?: string[];
	photoFile?: File;
}

export interface SceneState {
	allScenes: ISceneDocument[];
	selectedScene: ISceneDocument | null;
	newScene: ISceneDocument | null;
}

const initialState: SceneState = {
	allScenes: [],
	selectedScene: null,
	newScene: null,
};

export const sceneSlice = createSlice({
	name: "scene",
	initialState,
	reducers: {
		setCurrentScene: (state, action: PayloadAction<ISceneDocument | null>) => {
			state.selectedScene = action.payload;
		},
		setAllScenes: (state, action: PayloadAction<ISceneDocument[]>) => {
			state.allScenes = action.payload;
		},
		setNewScene: (state, action: PayloadAction<ISceneDocument | null>) => {
			state.newScene = action.payload;
		},
	},
});

export const { setCurrentScene, setAllScenes, setNewScene } = sceneSlice.actions;

export default sceneSlice.reducer;
