
import React, { ReactNode } from 'react';
import { Icon24UserAddOutline } from "@vkontakte/icons";
import bridge, { RequestIdProp } from "@vkontakte/vk-bridge";
import { Alert, Button, Link } from "@vkontakte/vkui";
import { RootState } from "../app/store";
import { UseAppSelector } from "../hooks/hooks";
import { IUserDocument } from "../features/user/user.slice";
import { readerGiftForAds } from "../Api/Reader/reader.api";

export interface IVkInviteButton {
  requestKey?: string;
  stretched?: boolean;
}

export const VkInviteButton = ({ requestKey, stretched, setPopout }: any) => {
  const currentUserId = UseAppSelector(
    (state: RootState) => state.user.selectedUserId
  );

  const sendRewardToUser = async () => {
    if (!currentUserId) {
      handleAlert(
        "Ошибка",
        "Текущий пользователь не найден. Попробуй перезайти в игру!"
      );
      return;
    }

    const successPayment: IUserDocument | undefined = await readerGiftForAds();

    return !!successPayment;
  };

  const args: { [key: string]: RequestIdProp } = {};

  if (requestKey) {
    args.requestKey = requestKey;
  }

  const handleAlert = (header: string, text: string | ReactNode) => {
    setPopout(
      <Alert
        actions={[
          {
            title: "Хорошо",
            autoClose: true,
            mode: "cancel",
          },
        ]}
        actionsLayout="vertical"
        onClose={() => setPopout(null)}
        header={header}
        text={text}
      />
    );
  };

  const onClick = () => {
    bridge
      .send("VKWebAppShowInviteBox", { request_id: "123" })
      .then(async (data: { [key: string]: any }) => {

        if (data.success) {
          const paymentSuccess = await sendRewardToUser();

          if (paymentSuccess) {
            handleAlert(
              "Успех",
              "Приглашения отправлены, вы получите дополнительные ключи. Награду возможно получить пару раз в день"
            );
          } else {
            handleAlert(
              "Ошибка",
              <>
                Приглашение друзей прошло успешно, но ключи не были выданы, попробуй позже еще раз или напишите нам{" "}
                <Link href="https://vk.com/im?sel=-219932114">в группу</Link>
              </>
            );
          }
        }
      })
      .catch((error) => {
        handleAlert(
          "Ошибка",
          <>
            В данный момент приглашение друзей недоступно, попробуйте позже или напишите нам{" "}
            <Link href="https://vk.com/im?sel=-219932114">в группу</Link>
          </>
        );
        console.error(error);
      });
  };

  return (
    <Button
      appearance="accent"
      before={<Icon24UserAddOutline />}
      onClick={onClick}
      size="l"
      stretched={stretched}
    >
      Пригласить друзей +3🗝
    </Button>
  );
};
