import {
  Panel,
  PanelHeader,
  Group,
  SplitLayout,
  ContentCard,
  Search,
  CardGrid,
  PanelHeaderButton,
  Button,
  Placeholder,
  PanelHeaderBack,
  Div,
  Spacing,
} from "@vkontakte/vkui";
import { useEffect, useState } from "react";
import {
  Icon16Like,
  Icon16LikeOutline,
  Icon56LogoClipsOutline,
} from "@vkontakte/icons";
import { UseAppDispatch, UseAppSelector } from "../hooks/hooks";
import { setStory } from "../features/navigation/navigation.slice";
import {
  IStoryDocument,
  setAllStories,
  setSelectedStory,
} from "../features/story/story.slice";
import { RootState } from "../app/store";
import { SERVER_IMAGE_API } from "../constants/constants";
import { getReaderAllStories } from "../Api/Reader/reader.api";
import NovellaCard from "../Components/NovellaCard.component";

export const SearchPanel = ({ id }: { id: string }) => {
  const [popout, setPopout] = useState<any>(null);
  const [search, setSearch] = useState<string>("");
  //   const [refresh, setRefresh] = useState<boolean>(true);

  const dispatch = UseAppDispatch();

  const allStories = UseAppSelector(
    (state: RootState) => state.story.allStories
  );

  useEffect(() => {
    setPopout(null); // TODO think about it
    const getAllStoriesHandle = async () => {
      const storyList: IStoryDocument[] | undefined =
        await getReaderAllStories();
      if (storyList) {
        dispatch(setAllStories(storyList));
      }
    };
    getAllStoriesHandle();
  }, [dispatch]);

  return (
    <SplitLayout
      popout={popout}
      style={{ maxWidth: 512, margin: "auto", justifyContent: "center" }}
    >
      <Panel id={id}>
        <PanelHeader
          before={
            <>
              <PanelHeaderBack
                onClick={() => {
                  dispatch(setStory("library"));
                }}
              />
              <PanelHeaderButton disabled={true} aria-label="filter">
                {/* <Icon24Filter /> */}
              </PanelHeaderButton>
            </>
          }
        >
          Поиск историй
        </PanelHeader>

        <Group description="Результаты поиска">
          <Search value={search} onChange={(e) => setSearch(e.target.value)} />

          <CardGrid size="m">
            {!allStories.length && (
              <Placeholder
                icon={<Icon56LogoClipsOutline />}
                header="Не найдено ни одной истории"
                stretched
                action={<Button size="m">Создать историю</Button>}
              >
                <>
                  Создайте историю сами, все в ваших руках!
                  <Spacing size={36} />
                </>
              </Placeholder>
            )}
            {(allStories.length &&
              allStories
                ?.filter(
                  (item) =>
                    item.name.toLowerCase().includes(search.toLowerCase()) ||
                    item.about.toLowerCase().includes(search.toLowerCase()) ||
                    (item?.tags?.length &&
                      item.tags.toLowerCase().includes(search.toLowerCase())) ||
                    item.author.toLowerCase().includes(search.toLowerCase())
                )
                .map((cardData: IStoryDocument) => <NovellaCard key={cardData._id} cardData={cardData} />)) ||
              ""}
          </CardGrid>
        </Group>
      </Panel>
    </SplitLayout>
  );
};
