import { RefObject } from 'react'

export const isFileOfCorrectType = (fileRef: RefObject<HTMLInputElement>, fileTypes: string[]) => {
  return () => {
    let valid = true
    const files = fileRef?.current?.files

    if (files) {
      const fileArr = Array.from(files)
      fileArr.forEach((file) => {
        const type = file.type.split('/')[1]

        if (!fileTypes.includes(`.${type}`)) {
          valid = false
        }
      })
    }
    return valid
  }
}
