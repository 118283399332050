import { IWeatherProps } from './Weather.types'
import { SERVER_IMAGE_API } from '../../constants/constants'

export function Weather({
  image,
  size,
  style,
}: IWeatherProps): JSX.Element | null {

  return (
    <div
      style={{
        ...style,
        backgroundRepeat: 'repeat',
        backgroundSize: size,
        backgroundImage: `url(${SERVER_IMAGE_API}${image})`
      }}
    />
  )
}
