import ReactDOM from "react-dom/client";
import { store } from "./app/store";
import { Provider } from "react-redux";
import { cookieSetAppState } from "./utils";
import bridge from "@vkontakte/vk-bridge";
import {
  ConfigProvider,
  AdaptivityProvider,
} from "@vkontakte/vkui";

import "@vkontakte/vkui/dist/vkui.css";
import "./index.css"
import { App } from "./Components/App";

bridge.send("VKWebAppInit", {});

cookieSetAppState();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <ConfigProvider appearance="dark">
      <AdaptivityProvider>
        <App />
      </AdaptivityProvider>
    </ConfigProvider>
  </Provider>
);
