import Cookies from "js-cookie";
import axios from "axios";
import { API_BASE_URL } from "../constants/constants";


const api = axios.create({
  baseURL: API_BASE_URL,
});

export const cookieSetAuthToken = (token?: string) => {
  if (token) {
    Cookies.set("jwt_token", token, { expires: 7 });
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    Cookies.remove("jwt_token");
    delete api.defaults.headers.Authorization;
  }
};

export const cookieSetUserId = (id?: string) => {
  if (id) {
    Cookies.set("userId", id, { expires: 7 });
  } else {
    Cookies.remove("userId");
  }
};

export const cookieSetStoryId = (id?: string) => {
  if (id) {
    Cookies.set("storyId", id, { expires: 7 });
  } else {
    Cookies.remove("storyId");
  }
};

export const cookieIsAuthenticated = () => {
  const token = Cookies.get('jwt_token');
  return token ? true : false;
};

export const cookieGetJwtToken = () => {
  const token = Cookies.get('jwt_token');
  return token;
};

export const cookieGetUserId = () => {
  const userId = Cookies.get('userId');
  return userId;
};

export const cookieGetStoryId = () => {
  const storyId = Cookies.get('storyId');
  return storyId;
};

export default api;
