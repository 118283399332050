import { useEffect, useState } from "react";
import { UseAppDispatch, UseAppSelector } from "../hooks/hooks";
import { RootState } from "../app/store";
import { getCreatorCharacterOne, getCreatorPhraseOne, getCreatorSceneOne, updateCreatorPhrases } from "../Api/Creator/creator.api";
import {
  CellButton,
  FormItem,
  Group,
  IconButton,
  Input,
  ModalPage,
  ModalPageHeader,
  PanelHeaderClose,
  Spinner,
  Image,
  Textarea,
  Button,
} from "@vkontakte/vkui";
import { ModalPageEnum, setModal } from "../features/navigation/navigation.slice";
import { IPhraseDocument, setAllPhrases, setCurrentPhrase } from "../features/phrase/phrase.slice";
import { Icon20Verified, Icon32MasksOutline, Icon32PictureOutline } from "@vkontakte/icons";
import { isTextIncludesBadWords } from "../utils/text";
import { SERVER_IMAGE_API } from "../constants/constants";
import { ICharacterDocument } from "../features/characters/character.slice";
import { ISceneDocument } from "../features/scenes/scene.slice";
import { LoadingStatusType } from "../Interfaces";


function EditorPhrasesModal({ id }: { id: string }) {
  const [text, setText] = useState<string>("");
  const [alias, setAlias] = useState<string>("");
  const [cost, setCost] = useState<string>("");
  const [character, setCharacter] = useState<ICharacterDocument | null>(null);
  const [scene, setScene] = useState<ISceneDocument | null>(null);

  const [textErrors, setTextErrors] = useState<string[]>([]);
  const [loadingStatus, setLoadingStatus] = useState<{ [key: string]: LoadingStatusType }>({
    text: "done", // LoadingStatus.loading,
    alias: "done", // LoadingStatus.loading,
    cost: "done", // LoadingStatus.loading,
  });

  const currentStory = UseAppSelector(
    (state: RootState) => state.story.selectedStory
  );

  const currentPhrase = UseAppSelector(
    (state: RootState) => state.phrase.currentPhrase,
  );

  const allPhrases = UseAppSelector(
    (state: RootState) => state.phrase.allPhrases,
  );

  const dispatch = UseAppDispatch();

  useEffect(() => {
    const getActualPhraseHandle = async () => {
      if (!currentStory?._id || !currentPhrase?._id) return;

      const currentStatePhrase: IPhraseDocument | undefined =
        await getCreatorPhraseOne({ phraseId: currentPhrase._id });

      if (currentStatePhrase) {
        setText(currentStatePhrase.text);
        setAlias(currentStatePhrase.alias);
        setCost(currentStatePhrase.cost.toString());

        const scene = await getCreatorSceneOne({ sceneId: currentPhrase.sceneId });
        if (scene) {
          setScene(scene);
        }

        const character = await getCreatorCharacterOne({ characterId: currentPhrase.characterId });
        if (character) {
          setCharacter(character);
        }

        const newAllPhraseState = [...allPhrases];
        const phraseIdx = allPhrases.findIndex(item => item._id === currentPhrase._id);
        if (phraseIdx) {
          newAllPhraseState[phraseIdx] = {
            ...newAllPhraseState[phraseIdx],
            text: currentStatePhrase.text,
            alias: currentStatePhrase.alias,
            cost: currentStatePhrase.cost,
          }
          dispatch(setAllPhrases(newAllPhraseState));
        }
      }
    };

    getActualPhraseHandle();
  }, [currentPhrase]);

  useEffect(() => {
    setLoadingStatus({ ...loadingStatus, alias: "loading" });
    const timer = setTimeout(async () => {
      if (!currentStory?._id) return;
      if (!currentPhrase?._id) return;

      if (!textErrors.length) {
        await updateCreatorPhrases({
          storyId: currentStory._id,
          phraseId: currentPhrase._id,
          dto: {
            alias: alias,
          },
        });
      }

      setLoadingStatus({ ...loadingStatus, alias: "done" });
    }, 1000)

    return () => {
      clearTimeout(timer);
    }
  }, [alias])

  useEffect(() => {
    setLoadingStatus({ ...loadingStatus, text: "loading" });
    const timer = setTimeout(async () => {
      if (!currentStory?._id) return;
      if (!currentPhrase?._id) return;

      if (!textErrors.length) {
        await updateCreatorPhrases({
          storyId: currentStory._id,
          phraseId: currentPhrase._id,
          dto: {
            text: text,
          },
        });
      }

      setLoadingStatus({ ...loadingStatus, text: "done" });
    }, 1000)

    return () => {
      clearTimeout(timer);
    }
  }, [text])

  useEffect(() => {
    setLoadingStatus({ ...loadingStatus, cost: "loading" });

    const timer = setTimeout(async () => {
      if (!currentStory?._id) return;
      if (!currentPhrase?._id) return;

      await updateCreatorPhrases({
        storyId: currentStory._id,
        phraseId: currentPhrase._id,
        dto: {
          cost: cost,
        },
      });

      setLoadingStatus({ ...loadingStatus, cost: "done" });
    }, 1000)

    return () => {
      clearTimeout(timer);
    }
  }, [cost])

  const handleSetAlias = async (alias: string) => {
    findMatt(alias);
    setAlias(alias);
  };

  const handleSetCost = async (cost: string) => {
    if (Number(cost) > 100) {
      cost = "100";
    }
    setCost(cost.toString());
  };

  const handleSetText = async (text: string) => {
    findMatt(text);
    setText(text);
  };

  const findMatt = (words: string) => {
    const textErrorMessage =
      "В тексте есть мат, вы не сможете пройти модерацию";

    const isHasMatt = isTextIncludesBadWords(words + text + alias);

    if (isHasMatt) {
      if (!textErrors.includes(textErrorMessage)) {
        setTextErrors([...textErrors, textErrorMessage]);
      }
    } else {
      setTextErrors(textErrors.filter((item) => item !== textErrorMessage));
    }

    return words;
  };

  const handleClose = () => {
    if (currentPhrase) {
      const phraseToSet = { ...currentPhrase };

      phraseToSet.text = text;
      phraseToSet.alias = alias;
      phraseToSet.cost = cost;

      dispatch(setCurrentPhrase(phraseToSet));

      const list = [...allPhrases];
      const idx = list.findIndex(item => item._id === currentPhrase._id);
      list[idx] = phraseToSet;

      dispatch(setAllPhrases(list));
    }

    dispatch(setModal(null));
  };

  return (
    <ModalPage
      id={"phrase"}
      onClose={handleClose}
      dynamicContentHeight
      settlingHeight={100}
      header={
        <ModalPageHeader before={<PanelHeaderClose onClick={handleClose} />}>
          Фраза
        </ModalPageHeader>
      }
    >
      <Group>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignContent: "center",
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          <div
            style={{
              position: "relative",
              width: "240px",
              height: "240px",
              padding: 0,
            }}
          >
            {scene?.imageUrl && (
              <img
                style={{
                  position: "absolute",
                  width: "240px",
                  borderRadius: 10,
                }}
                alt="scene"
                src={
                  SERVER_IMAGE_API +
                  scene.imageUrl
                }
              />
            )}

            {character?.imageUrl && (
              <img
                style={{
                  position: "absolute",
                  width: "240px",
                  borderRadius: 10,
                }}
                alt="character"
                src={
                  SERVER_IMAGE_API +
                  character.imageUrl
                }
              />
            )}
          </div>
          <div
            style={{
              width: "200px",
              padding: 0,
            }}
          >
            <CellButton
              onClick={() => dispatch(setModal(ModalPageEnum.scenes))}
              before={
                <Image withBorder={false} size={72}>
                  <Icon32PictureOutline />
                </Image>
              }
            >
              Сцена
            </CellButton>
            <CellButton
              onClick={() => dispatch(setModal(ModalPageEnum.characters))}
              before={
                <Image withBorder={false} size={72}>
                  <Icon32MasksOutline />
                </Image>
              }
            >
              Персонаж
            </CellButton>
          </div>
        </div>
        <FormItem
          style={{ padding: 5 }}
          htmlFor="alias"
          top="Заголовок фразы"
          >
          <Input
            id="alias"
            value={alias}
            placeholder="Например - к звездам..."
            onChange={(e) => handleSetAlias(e.target.value)}
            type="text"
            after={
              <IconButton hoverMode="opacity" aria-label="Очистить поле" onClick={() => { }}>
                {
                  loadingStatus.alias === "loading"
                    ? <Spinner size="small" />
                    : <Icon20Verified />
                }
              </IconButton>
            }
          />
        </FormItem>

        <div style={{ display: "flex" }}>
          <FormItem
            style={{ padding: 5, width: "100%" }}
            htmlFor="text"
            top="Текст фразы"
            bottom={
              ""
            }
          >
            <Textarea
              id="pass"
              value={text}
              onChange={(e) => handleSetText(e.target.value)}
            />
          </FormItem>
          <div style={{ display: "flex", justifyContent: "center", height: 22, width: 22, padding: 10 }}>
            {
              loadingStatus.text === "loading"
                ? <Spinner size="small" />
                : <Icon20Verified fill="rgb(118,120,122)" />
            }
          </div>
        </div>

        <FormItem
          style={{ padding: 5 }}
          htmlFor="cost"
          top="Стоимость (0-100)"
        >
          <Input
            id="cost"
            value={cost}
            onChange={(e) => handleSetCost(e.target.value)}
            type="number"
            after={
              <IconButton hoverMode="opacity" aria-label="Очистить поле" onClick={() => { }}>
                {loadingStatus.cost === "loading"
                  ? <Spinner size="small" />
                  : <Icon20Verified />
                }
              </IconButton>
            }
          />

        </FormItem>

        <FormItem
          style={{ padding: 5 }}
          htmlFor="go"
        >
          <Button id="go" size="l" mode="primary" stretched onClick={handleClose} disabled={!!textErrors.length} >
            {textErrors.length ? "Ошибки в тексте" : "Готово"}
          </Button>
        </FormItem>

      </Group>
    </ModalPage>
  );
}

export default EditorPhrasesModal;
