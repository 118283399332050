import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";
import { cookieGetJwtToken, cookieSetAuthToken } from "../../Api/config";

const accessToken = cookieGetJwtToken();

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    jwt: accessToken || "",
    isAuth: accessToken || false,
    nickname: "No nickname",
  },
  reducers: {
    setJwt: (state, action) => {
      state.jwt = action.payload.accessToken;
      state.isAuth = true;
    },
    setLogin: (state, action) => {
      state.jwt = action.payload.accessToken;
      state.nickname = action.payload.nickname;
      state.isAuth = true;
    },
    setLogout: (state) => {
      state.nickname = "No nickname";
      cookieSetAuthToken();
      state.isAuth = false;
    },
  },
});

export const { setLogin, setLogout, setJwt } = authSlice.actions;

export const selectJwt = (state: RootState) => state.auth.jwt;
export const selectAuthStatus = (state: RootState) => state.auth.isAuth;

export default authSlice.reducer;
