import { useState, useEffect, RefObject } from "react";

export const useOnLoadImages = (ref: RefObject<HTMLElement>) => {
  const [status, setStatus] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      // console.log('!!ref?.current', !!ref?.current)
      if (!!ref?.current) {
        const images = Array.from(ref.current.querySelectorAll("img"));
        const status = images.map((image) => image.complete).every((item) => item === true)

        if (status) {
          setStatus(status)
          clearInterval(interval)
        }
      }

    }, 1)
    return () => {
      clearInterval(interval)
    }
  }, [ref])

  return status;
};
