import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LoadingStatusType, ModerationStatusEnum } from "../../Interfaces";
import { createCreatorPhrase, updateCreatorPhrases } from '../../Api/Creator/creator.api';

export interface IPhraseDocument {
  _id: string;
  text: string;
  sceneId: string;
  storyId: string;
  characterId: string;
  alias: string;
  cost: string;
  previousPhrases?: string[];
  nextPhrases?: string[];
  moderationStatus?: ModerationStatusEnum;
  reason?: string;
  comment?: string;
}

export interface ICreatePhraseDocument {
  _id?: string;
  text?: string;
  sceneId?: string;
  storyId?: string;
  characterId?: string;
  alias?: string;
  cost?: string;
  previousPhrases?: string[];
  nextPhrases?: string[];
  moderationStatus?: ModerationStatusEnum;
  reason?: string;
  comment?: string;
}

interface PhraseState {
  allPhrases: IPhraseDocument[];
  currentPhrase: IPhraseDocument | null;
  loading: LoadingStatusType;
  editorPhraseNext: IPhraseDocument | null;
  editorPhrasePrev: IPhraseDocument | null;
}

const initialState: PhraseState = {
  allPhrases: [],
  currentPhrase: null,
  editorPhraseNext: null,
  editorPhrasePrev: null,
  loading: "done",
};

export const createNextPhraseBySlice = createAsyncThunk(
  'phrase/createNextPhraseBySlice',
  async ({ currentPhrase, storyId, dto }: { currentPhrase: IPhraseDocument, storyId: string, dto: ICreatePhraseDocument }, thunkAPI) => {
    if (!storyId || !currentPhrase?._id) {
      thunkAPI.abort('Недостаточно данных для обновления и создания фразы');
      return;
    };
    const createResponse = await createCreatorPhrase({ dto });
    if (!createResponse?._id || !dto?.nextPhrases) {
      thunkAPI.abort('Недостаточно данных для обновления фразы');
      return;
    };

    const updateResponse = await updateCreatorPhrases({
      storyId,
      phraseId: currentPhrase._id,
      dto: {
        nextPhrases: [...(currentPhrase?.nextPhrases || []), createResponse._id],
      }
    });
    console.log({updateResponse});

    return {
      newPhrase: createResponse,
      currentPhrase: updateResponse,
    };
  }
)

export const createPrevPhraseBySlice = createAsyncThunk(
  'phrase/createPrevPhraseBySlice',
  async ({ storyId, currentPhrase, dto }: { currentPhrase: IPhraseDocument, storyId: string, dto: ICreatePhraseDocument }, thunkAPI) => {
    if (!storyId || !currentPhrase?._id) {
      thunkAPI.abort('Недостаточно данных для обновления и создания фразы');
      return;
    };

    const createResponse = await createCreatorPhrase({ dto });
    if (!createResponse?._id || !dto.previousPhrases) {
      thunkAPI.abort('Недостаточно данных для обновления фразы');
      return;
    };

    const updateResponse = await updateCreatorPhrases({
      storyId,
      phraseId: currentPhrase._id,
      dto: {
        previousPhrases: [...(currentPhrase?.previousPhrases || []), createResponse._id],
      }
    });

    return {
      newPhrase: createResponse,
      currentPhrase: updateResponse,
    };
  }
)

export const phraseSlice = createSlice({
  name: "phrase",
  initialState,
  reducers: {
    setEditorPhraseNext: (
      state,
      action: PayloadAction<IPhraseDocument | null>
    ) => {
      state.editorPhraseNext = action.payload;
    },

    setEditorPhrasePrev: (
      state,
      action: PayloadAction<IPhraseDocument | null>
    ) => {
      state.editorPhrasePrev = action.payload;
    },

    setCurrentPhrase: (
      state,
      action: PayloadAction<IPhraseDocument | null>
    ) => {
      state.currentPhrase = action.payload;
    },

    setAllPhrases: (state, action) => {
      state.allPhrases = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createNextPhraseBySlice.fulfilled, (state, action) => {
      state.loading = "done";
      if (action?.payload?.newPhrase) state.allPhrases.push(action.payload.newPhrase);

      if (action?.payload?.currentPhrase) {
        state.currentPhrase = action?.payload?.currentPhrase;

        const idx = state.allPhrases.findIndex((item: IPhraseDocument) => item._id === state?.currentPhrase?._id);
        if (idx) {
          state.allPhrases[idx] = action?.payload?.currentPhrase;
        }
      }
    });
    builder.addCase(createNextPhraseBySlice.pending, (state, action) => {
      state.loading = "loading";
    });
    builder.addCase(createNextPhraseBySlice.rejected, (state, action) => {
      state.loading = "error";
    });

    builder.addCase(createPrevPhraseBySlice.fulfilled, (state, action) => {
      state.loading = "done";
      if (action?.payload?.newPhrase) state.allPhrases.push(action.payload.newPhrase);

      if (action?.payload?.currentPhrase) {
        state.currentPhrase = action?.payload?.currentPhrase;

        const idx = state.allPhrases.findIndex((item: IPhraseDocument) => item._id === state?.currentPhrase?._id);
        if (idx) {
          state.allPhrases[idx] = action?.payload?.currentPhrase;
        }
      }
    });
    builder.addCase(createPrevPhraseBySlice.pending, (state, action) => {
      state.loading = "loading";
    });
    builder.addCase(createPrevPhraseBySlice.rejected, (state, action) => {
      state.loading = "error";
    });
  },
});

// Action creators are generated for each case reducer function
export const { setAllPhrases, setCurrentPhrase, setEditorPhraseNext, setEditorPhrasePrev } = phraseSlice.actions;

export default phraseSlice.reducer;
