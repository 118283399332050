import { useEffect, useState } from 'react'

export { useEffect } from 'react'

export interface IUseMouseCenterShift {
  xShift: number,
  yShift: number,
}

export const useMouseCenterShift = (): IUseMouseCenterShift => {
  const [xShift, setXShift] = useState(0)
  const [yShift, setYShift] = useState(0)

  const mouseHandler = (e: MouseEvent) => {
    setXShift((e.clientX - window.innerWidth / 2) / window.innerWidth * 2)
    setYShift((e.clientY - window.innerHeight / 2) / window.innerHeight * 2)
  }

  useEffect(() => {
    window.addEventListener('mousemove', mouseHandler)
    return () => {
      window.removeEventListener('mousemove', mouseHandler)
    }
  }, [])

  return {
    xShift,
    yShift,
  }
}
