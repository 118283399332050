import {
  Panel,
  PanelHeader,
  SplitLayout,
  PanelHeaderBack,
  ModalRoot,
  Image,
  SplitCol,
  InfoRow,
  List,
  Button,
  Div,
  FormStatus,
  ButtonGroup,
  Search,
  ModalPage,
  ModalPageHeader,
  PanelHeaderClose,
} from "@vkontakte/vkui";
import { useEffect, useState } from "react";
import { UseAppDispatch, UseAppSelector } from "../hooks/hooks";
import { setModal, setStory } from "../features/navigation/navigation.slice";
import { RootState } from "../app/store";
import ScenesModal from "./SceneModal";
import CharactersModal from "./CharacterModal";
import EditorPhrasesModal from "./EditorPhraseModal";
import { LoadingStatusType, ModerationStatusEnum } from "../Interfaces";
import { SERVER_IMAGE_API } from "../constants/constants";
import { Icon24Add, Icon24Delete, Icon24PenOutline, Icon24TreeNodesOutline } from "@vkontakte/icons";
import { getCreatorCharacterList, getCreatorPhraseList, getCreatorSceneList } from "../Api/Creator/creator.api";
import { IPhraseDocument, setAllPhrases, setEditorPhraseNext, setEditorPhrasePrev } from "../features/phrase/phrase.slice";
import { setAllCharacters } from "../features/characters/character.slice";
import { setAllScenes } from "../features/scenes/scene.slice";

export const EditorPhraseListModal = ({ id }: { id: string }) => {
  const [search, setSearch] = useState<string>('');
  const [refresh, setRefresh] = useState<boolean>(true);
  const [popout, setPopout] = useState<any>(null);
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatusType>("loading");

  const dispatch = UseAppDispatch();

  const currentStory = UseAppSelector(
    (state: RootState) => state.story.selectedStory,
  );

  const currentModal = UseAppSelector(
    (state: RootState) => state.navigation.modal
  ) as string | null;

  const editorPrevPhrase = UseAppSelector(
    (state: RootState) => state.phrase.editorPhrasePrev,
  );

  const allPhrases = UseAppSelector(
    (state: RootState) => state.phrase.allPhrases,
  );

  const allCharacters = UseAppSelector(
    (state: RootState) => state.character.allCharacters,
  );

  const allScenes = UseAppSelector(
    (state: RootState) => state.scene.allScenes,
  );

  useEffect(() => {
    if (!currentStory?._id) return;

    const getAllPhrases = async () => {
      const phraseList = await getCreatorPhraseList(currentStory._id);
      phraseList && dispatch(setAllPhrases(phraseList));
    }

    getAllPhrases();
  }, []);

  useEffect(() => {
    if (!currentStory?._id) return;

    const getAllCharacters = async () => {
      const characterList = await getCreatorCharacterList(currentStory._id);
      characterList && dispatch(setAllCharacters(characterList));
    }

    getAllCharacters();
  }, []);

  useEffect(() => {
    if (!currentStory?._id) return;

    const getAllScenes = async () => {
      const allScenes = await getCreatorSceneList(currentStory._id);
      allScenes && dispatch(setAllScenes(allScenes));
    }

    getAllScenes();
  }, []);

  const modal = (
    <ModalRoot activeModal={currentModal}>
      <ScenesModal id="scenes" />
      <CharactersModal id="characters" />
      <EditorPhrasesModal id="phrase" />
    </ModalRoot>
  );

  const removeFromList = (idx: number) => {
    const list = [...allPhrases];
    list.splice(idx, 1);
    // TODO update with api
    dispatch(setAllPhrases(list));
  };

  const checkIsEditorPhrase = (id: string | undefined) => {
    if (!editorPrevPhrase?._id) return false;
    if (id === editorPrevPhrase._id) return true;
  };

  const getCharacterImgUrl = (id: string | undefined) => {
    if (!id) return;
    if (allCharacters?.length) {
      const imgUrl = allCharacters.find(item => item._id === id)?.imageUrl;
      return imgUrl;
    }
  };

  const getSceneImgUrl = (id: string | undefined) => {
    if (!id) return;
    if (allCharacters?.length) {
      const imgUrl = allScenes.find(item => item._id === id)?.imageUrl;
      return imgUrl;
    }
  };

  const closeModal = () => {
    dispatch(setModal(null));
  };

  return (
    <ModalPage
      id={"phraseList"}
      onClose={closeModal}
      dynamicContentHeight
      settlingHeight={100}
      header={
        <ModalPageHeader before={<PanelHeaderClose onClick={closeModal} />}>
          <Search placeholder="Все фразы" value={search} onChange={(e) => setSearch(e.target.value)} noPadding after={null} />
        </ModalPageHeader>
      }
    >

      <Div>
        <List>
          {allPhrases.filter(item =>
            item.text.toLowerCase().includes(search.toLowerCase()) ||
            item.alias.toLowerCase().includes(search.toLowerCase()) ||
            item._id.toLowerCase().includes(search.toLowerCase())
          ).map((item: IPhraseDocument, idx) => {
            return (
              <div
                key={item._id + idx}
                onClick={() => {
                  dispatch(setEditorPhraseNext(item));
                  dispatch(setEditorPhrasePrev(item));
                  closeModal();
                }}
                style={{
                  margin: checkIsEditorPhrase(item._id) ? "5px 0px" : "5px 0px",
                  boxShadow: checkIsEditorPhrase(item._id) ? 'rgba(255,255,255,1) 0px 0px 25px' : 'none',
                  borderRadius: checkIsEditorPhrase(item._id) ? 10 : 0
                }}
              >
                <div style={{ display: 'flex', alignItems: 'flex-start', padding: 5 }}>
                  <Image
                    size={64}
                    style={{ position: 'absolute', background: 'rgba(0,0,0,0)' }}
                    src={SERVER_IMAGE_API + getSceneImgUrl(item.sceneId)}
                    borderRadius={"s"}
                  >
                  </Image>

                  <Image
                    size={64}
                    style={{ background: 'rgba(0,0,0,0)' }}
                    src={SERVER_IMAGE_API + getCharacterImgUrl(item.characterId)}
                    borderRadius={"s"}
                  >
                  </Image>

                  <InfoRow
                    style={{ margin: 10, width: '100%' }}
                    header={item.alias}
                  >
                    {item.text.slice(0, 75) + (item.text.length > 75 ? "..." : '')}
                  </InfoRow>
                </div>

                {item.moderationStatus === ModerationStatusEnum.invalid && (
                  <FormStatus header={item?.reason || "Причина не указана"} mode="error">
                    {item?.comment || "Комментариев модератора нет"}
                  </FormStatus>
                )}
              </div>

            );
          })}
        </List>
      </Div>
    </ModalPage>
  );
};