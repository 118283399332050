import {
  Icon28StorefrontOutline,
  Icon28SettingsOutline,
  Icon28UserOutline,
  Icon28BookSpreadOutline,
} from "@vkontakte/icons";
import {
  usePlatform,
  Platform,
  SplitLayout,
  PanelHeader,
  SplitCol,
  Epic,
  Tabbar,
  TabbarItem,
} from "@vkontakte/vkui";
import { AuthPanel } from "./AuthPanel/AuthPanel";
import { LibraryPanel } from "./LibraryPanel";
import { UseAppDispatch, UseAppSelector } from "../hooks/hooks";
import { setStory } from "../features/navigation/navigation.slice";
import { SearchPanel } from "./SearchPanel";
import { MyStoriesPanel } from "./MyStoriesPanel";
import { SettingsPanel } from "./SettingsPanel";
import { StoryEditorPanel } from "./StoryEditorPanel";
import { CharacterEditorPanel } from "./CharacterEditorPanel/CharacterEditorPanel";
import { SceneEditorPanel } from "./SceneEditorPanel/SceneEditorPanel";
import { PhraseEditorPanel } from "./PhraseListPanel";
import { ReadProgressPanel } from "./Reader/ReadProgressPanel";
import { RootState } from "../app/store";
import { ReaderStoryAboutPanel } from "./Reader/StoryAboutPanel";
import { ReaderStoryPagePanel } from "./Reader/StoryPagePanel";
import { PhraseLinksEditorPanel } from "./PhraseLinksPanel";

export const EpicPanel = ({ id }: { id: string }) => {
  const platform = usePlatform();

  const activeStory = UseAppSelector(
    (state: RootState) => state.navigation.story
  );

  const dispatch = UseAppDispatch();

  const onStoryChange = (e: any) =>
    dispatch(setStory(e.currentTarget.dataset.story));
  const isVKCOM = platform !== Platform.VKCOM;

  return (
    <SplitLayout
      header={isVKCOM && <PanelHeader separator={false} />}
      style={{ justifyContent: "center" }}
    >
      <SplitCol width="512px" maxWidth="480px" stretchedOnMobile autoSpaced>
        <Epic
          activeStory={activeStory}
          tabbar={
            <Tabbar hidden={false}>
              <TabbarItem
                aria-labelledby="library"
                onClick={onStoryChange}
                selected={activeStory === "library"}
                data-story="library"
                text="Библиотека"
              >
                <Icon28StorefrontOutline />
              </TabbarItem>
              <TabbarItem
                aria-labelledby="author"
                onClick={onStoryChange}
                selected={activeStory === "author"}
                data-story="author"
                text="Мои новеллы"
              >
                <Icon28UserOutline />
              </TabbarItem>
              <TabbarItem
                aria-labelledby="readProgress"
                onClick={onStoryChange}
                selected={activeStory === "readProgress"}
                data-story="readProgress"
                text="История"
              >
                <Icon28BookSpreadOutline />
              </TabbarItem>
              <TabbarItem
                aria-labelledby="settings"
                onClick={onStoryChange}
                selected={activeStory === "settings"}
                data-story="settings"
                text="Настройки"
              >
                <Icon28SettingsOutline />
              </TabbarItem>
            </Tabbar>
          }
        >
          <EpicPanel id="epic" />
          <AuthPanel id="auth" />
          <LibraryPanel id="library" />
          <SearchPanel id="search" />
          <MyStoriesPanel id="author" />
          <SettingsPanel id="settings" />
          <StoryEditorPanel id="storyEditor" />
          <CharacterEditorPanel id="characterEditor" />
          <SceneEditorPanel id="sceneEditor" />
          <PhraseEditorPanel id="phraseEditor" />
          <PhraseLinksEditorPanel id="phraseLinksEditor" />
          <ReadProgressPanel id="readProgress" />
          {/* <StoryViewerPanel id="storyViewer" /> */}
          <ReaderStoryAboutPanel id="storyViewer" />

          {/* <PhraseViewerPanel id="phraseViewer" /> */}
          <ReaderStoryPagePanel id="phraseViewer" />
        </Epic>
      </SplitCol>
    </SplitLayout>
  );
};
