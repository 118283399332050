import { ChangeEvent, FormEventHandler, useRef, useState } from 'react'
import cn from 'classnames'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import {
  Button,
  ButtonGroup,
  File,
  FormItem,
  FormLayout,
  FormLayoutGroup,
  Input,
} from '@vkontakte/vkui'

import { ICharacterEditFormProps } from './CharacterEditForm.types'
import styles from './CharacterEditForm.module.scss'
import { isFileTooBig } from '../../utils/isFileTooBig'
import { isFileOfImage } from '../../utils/isFileOfImage'
import { IMAGE_TYPES, MAX_IMAGE_SIZE, SERVER_IMAGE_API } from '../../constants/constants'
import { IHandleCreateCharacterProps, IHandleUpdateCharacterProps } from '../../Panels/CharacterEditorPanel/CharacterEditorPanel.types';
import { Icon24Camera } from '@vkontakte/icons'
import { badWordsCheck } from '../../utils/text'

export function CharacterEditForm({
  character,
  className,
  handleCreate,
  handleDelete,
  handleUpdate,
  isLoading,
}: ICharacterEditFormProps): JSX.Element {
  const [photoFile, setPhotoFile] = useState<File | null>(null)
  const fileRef = useRef<HTMLInputElement>(null)
  const nameRef = useRef<HTMLInputElement>(null)

  const initialValues = {
    name: character?.name || '',
    imageUrl: character?.imageUrl || '',
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Должно быть не менее 2 символа")
      .max(30, "Должно быть не более 30 символов")
      .required("Это обязательное поле")
      .test(
        "text-include-bad-words",
        "В тексте есть неприличные слова",
        badWordsCheck(nameRef)
      ),
    imageUrl: Yup.mixed()
      .test(
        "is-file-too-big",
        "Файл должен быть не более 10МБ",
        isFileTooBig(fileRef, MAX_IMAGE_SIZE)
      )
      .test(
        "is-file-of-correct-type",
        "Данный тип файла не поддерживается",
        isFileOfImage(fileRef)
      )
      .required("Необходимо загрузить изображение"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    onSubmit: () => { },
  })

  const handleChangePhoto = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const files = (e.target as HTMLInputElement).files
    files && formik.setFieldValue('imageUrl', URL.createObjectURL(files[0]))
    files && setPhotoFile(files[0])
  }

  const isUpdateDisabled = !formik.isValid || isLoading || !formik.dirty

  const characterImageSrc = !!photoFile ? URL.createObjectURL(photoFile) : character?.imageUrl ? SERVER_IMAGE_API + character?.imageUrl : ''

  return (
    <FormLayout
      className={cn(styles.root, className)}
      onSubmit={formik.handleSubmit as FormEventHandler<HTMLElement>}
    >
      <FormLayoutGroup mode='vertical'>
        {
          !!characterImageSrc && (
            <img
              alt='Изображение персонажа'
              className={styles.image}
              src={characterImageSrc}
            />
          )
        }
        
        <FormItem
          htmlFor='name'
          top='Имя персонажа'
          status={
            formik.touched.name && formik.errors.name ? 'error' : 'default'
          }
          bottom={formik.touched.name && formik.errors.name}
        >
          <Input
            id='name'
            placeholder=''
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            getRef={nameRef}
          />
        </FormItem>

        <FormItem
          status={
            photoFile && formik.touched.imageUrl && formik.errors.imageUrl
              ? 'error'
              : 'default'
          }
          bottom={
            photoFile && formik.touched.imageUrl && formik.errors.imageUrl
          }
        >
          <File
            accept={IMAGE_TYPES.join(', ')}
            appearance='accent'
            before={<Icon24Camera role='presentation' />}
            getRef={fileRef}
            id='imageUrl'
            mode='tertiary'
            onBlur={formik.handleBlur}
            onChange={(e) => {
              handleChangePhoto(e)
              formik.handleChange(e)
            }}
            size='l'
            stretched={true}
          >
            {!!character?.imageUrl ? 'Заменить изображение' : 'Добавить изображение'}    
          </File>
        </FormItem>


        <FormItem>
          <ButtonGroup mode='horizontal' gap='s' align='right' stretched>
            {
              !!handleDelete && !!character?._id && (
                <Button
                  size='m'
                  appearance='negative'
                  onClick={() => handleDelete(character._id, character.name)}
                >
                  Удалить
                </Button>
              )
            }

            {
              !!character && handleUpdate && (
                <Button
                  size='m'
                  appearance='positive'
                  onClick={() => {
    
                    const data: IHandleUpdateCharacterProps = {
                      _id: character?._id || '',
                      imageUrl: formik.values.imageUrl,
                      name: formik.values.name,
                    }
                    if (!!photoFile) data.photoFile = photoFile
                    handleUpdate(data)
                  }}
                  disabled={isUpdateDisabled}
                >
                  Сохранить
                </Button>
              )
            }

            {
              !character && !!handleCreate && (
                <Button
                  size='m'
                  appearance='positive'
                  onClick={() => {
                    const data: IHandleCreateCharacterProps = {
                      imageUrl: formik.values.imageUrl,
                      name: formik.values.name,
                    }
                    if (!!photoFile) data.photoFile = photoFile
                    handleCreate(data)
                  }}
                  disabled={isUpdateDisabled}
                >
                  Создать
                </Button>
              )
            }

          </ButtonGroup>
        </FormItem>
      </FormLayoutGroup>
    </FormLayout >
  )
}
