import { AppRoot, ModalRoot, Root, View } from "@vkontakte/vkui";
import { loginUserByVk } from "../Api/auth.api";
import { cookieIsAuthenticated, cookieSetUserId } from "../Api/config";
import { getUserById } from "../Api/user.api";
import { AuthPanel } from "../Panels/AuthPanel/AuthPanel";
import { EpicPanel } from "../Panels/EpicPanel";
import { ReaderStoryPagePanel } from "../Panels/Reader/StoryPagePanel";
import { UseAppSelector } from "../hooks/hooks";
import { RootState } from "../app/store";
import { setLogin } from "../features/auth/auth.slice";
import { setPanel } from "../features/navigation/navigation.slice";
import {
  IUserDocument,
  setSelectedUserId,
  setCurrentUser,
} from "../features/user/user.slice";
import { parseDataFromJwtToken } from "../utils";
import { UseAppDispatch } from "../hooks/hooks";
import { useEffect } from "react";

export const App = () => {
  const activeView = UseAppSelector(
    (state: RootState) => state.navigation.view
  );

  const activePanel = UseAppSelector(
    (state: RootState) => state.navigation.panel
  );

  const dispatch = UseAppDispatch();

  const loginByVK = async (hrefString: string) => {
    const loginResult = await loginUserByVk(hrefString);
    if (loginResult) {
      const accessToken = loginResult.accessToken;
      dispatch(setLogin(accessToken));

      const userIdFromServer: string = parseDataFromJwtToken(
        loginResult.accessToken
      ).userId;
      if (!userIdFromServer) return;

      const userData: IUserDocument = await getUserById(userIdFromServer);
      if (!userData) return;

      dispatch(setSelectedUserId(userIdFromServer));
      dispatch(setCurrentUser(userData));

      cookieSetUserId(userIdFromServer);
      dispatch(setPanel("epic"));
    }
  };

  useEffect(() => {
    const isVkApp: boolean = !!window.location.href.includes("access_token");
    if (isVkApp) {
      loginByVK(window.location.href).then(() => {
        dispatch(setPanel("epic"));
      });
      return;
    }

    const isAuth = cookieIsAuthenticated();

    if (isAuth) {
      dispatch(setPanel("epic"));
    } else {
      dispatch(setPanel("auth"));
    }
  }, []);

  return (
    <AppRoot style={{ background: "rgb(25,25,26)" }}>
      <Root activeView={activeView}>
        <View
          id="main"
          activePanel={activePanel}
          style={{ overflow: "scroll" }}
        >
          <div id="start" />
          <AuthPanel id="auth" />
          <EpicPanel id="epic" />
          <ReaderStoryPagePanel id="phraseViewer" />
        </View>
      </Root>
    </AppRoot>
  );
};
