import { RefObject } from "react";

export const badWordsRegExp = new RegExp(
	/(?<![а-яё])(?:(?:(?:у|[нз]а|(?:хитро|не)?вз?[ыьъ]|с[ьъ]|(?:и|ра)[зс]ъ?|(?:о[тб]|п[оа]д)[ьъ]?|(?:\S(?=[а-яё]))+?[оаеи-])-?)?(?:[её](?:б(?!о[рй]|рач)|п[уа](?:ц|тс))|и[пб][ае][тцд][ьъ]).*?|(?:(?:н[иеа]|ра[зс]|[зд]?[ао](?:т|дн[оа])?|с(?:м[еи])?|а[пб]ч)-?)?ху(?:[яйиеёю]|л+и(?!ган)).*?|бл(?:[эя]|еа?)(?:[дт][ьъ]?)?|\S*?(?:п(?:[иеё]зд|ид[аое]?р|ед(?:р(?!о)|[аое]р|ик))|бля(?:[дбц]|тс)|[ое]ху[яйиеёю]|хуйн).*?|(?:о[тб]?|про|на|вы)?м(?:анд(?:[ауеыи](?:л(?:и[сзщ])?[ауеиы])?|ой|[ао]в.*?|юк(?:ов|[ауи])?|е[нт]ь|ища)|уд(?:[яаиое].+?|е?н(?:[ьюия]|ей))|[ао]л[ао]ф[ьъ](?:[яиюе]|[еёо]й))|елд[ауые].*?|ля[тд]ь|(?:[нз]а|по)х)(?![а-яё])|су[чк][иа]/gim
);

export function isTextIncludesBadWords(text: string): boolean {
	if (!text) return false;
	return badWordsRegExp.test(text);
};

export const badWordsCheck = (nameRef: RefObject<HTMLInputElement>) => {
	return () => {
		if (!nameRef.current?.value) return true;
		return !badWordsRegExp.test(nameRef.current.value);
	}
};

export function replaceBadWords(text: string, char: string = '*'): string {
	if (!text) return '';
	return text.replaceAll(badWordsRegExp, char);
};

export const rusReg = new RegExp(/[?!,.а-яА-ЯёЁ0-9\s]+/gim);