import { RefObject } from 'react'

import { isFileOfCorrectType } from './isFileOfCorrectType'
import { IMAGE_TYPES } from '../constants/constants'

export const isFileOfImage = (fileRef: RefObject<HTMLInputElement>) => {
  return () => {
    return isFileOfCorrectType(fileRef, IMAGE_TYPES)()
  }
}
