import { PayloadAction, createSlice } from "@reduxjs/toolkit";
export interface ICharacterDocument {
	_id: string;
	storyId: string;
	name: string;
	imageUrl: string;
	photoFile?: File;
	depthMapImageUrl?: string;
}

export interface ICreateCharacterDocument {
	_id?: string;
	storyId: string;
	name?: string;
	imageUrl?: string;
	photoFile?: File;
	depthMapImageUrl?: string;
}

interface CharacterState {
	allCharacters: ICharacterDocument[];
	selectedCharacter: ICharacterDocument | null;
	newCharacter: ICharacterDocument | null;
}

const initialState: CharacterState = {
	allCharacters: [],
	selectedCharacter: null,
	newCharacter: null,
};

export const characterSlice = createSlice({
	name: "character",
	initialState,
	reducers: {
		setCurrentCharacter: (
			state,
			action: PayloadAction<ICharacterDocument | null>
		) => {
			state.selectedCharacter = action.payload;
		},
		setAllCharacters: (
			state,
			action: PayloadAction<ICharacterDocument[] | null>
		) => {
			if (action?.payload) {
				state.allCharacters = action.payload;
			}
		},
		setNewCharacter: (state, action: PayloadAction<ICharacterDocument | null>) => {
			state.newCharacter = action.payload;
		},
	},
});

export const { setCurrentCharacter, setAllCharacters, setNewCharacter } = characterSlice.actions;

export default characterSlice.reducer;
