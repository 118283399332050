import { ICreateStoryDocument, IStoryDocument } from "../features/story/story.slice";
import api from "./config";

export const createStory = async (
  storyData: ICreateStoryDocument
): Promise<IStoryDocument> => {
  try {
    const formData = new FormData();
    Object.entries(storyData).forEach(([key, value]) => {
      formData.append(key, value);
    });
    const response = await api.post(`/story`, formData);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("An error occurred while updating the story.");
  }
};

export const updateStory = async (
  storyId: string,
  storyData: ICreateStoryDocument
): Promise<IStoryDocument> => {
  try {
    const formData = new FormData();
    Object.entries(storyData).forEach(([key, value]) => {
      formData.append(key, value);
    });
    const response = await api.put(`/story/${storyId}`, formData);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("An error occurred while updating the story.");
  }
};

export const deleteStory = async (storyId: string): Promise<IStoryDocument> => {
  try {
    const response = await api.delete(`/story/${storyId}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("An error occurred while deleting the story.");
  }
};

export default api;
