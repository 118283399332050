import { useEffect, useState } from 'react'

import {
  Alert,
  Group,
  Panel,
  PanelHeader,
  PanelHeaderBack,
  ScreenSpinner,
  SplitLayout,
} from '@vkontakte/vkui'

import { EntityCard } from '../../Components/EntityCard/EntityCard'
import { ICharacterDocument, ICreateCharacterDocument } from '../../features/characters/character.slice'
import { ICharacterEditorPanelProps, IHandleCreateCharacterProps, IHandleUpdateCharacterProps } from './CharacterEditorPanel.types'
import { RootState } from '../../app/store'
import { setStory } from '../../features/navigation/navigation.slice'
import { UseAppDispatch, UseAppSelector } from '../../hooks/hooks'

import styles from './CharacterEditorPanel.module.scss'
import { createCreatorCharacter, deleteCreatorCharacter, getCreatorCharacterList, updateCreatorCharacter } from '../../Api/Creator/creator.api'
import { SERVER_IMAGE_API } from '../../constants/constants'
import { CharacterEditForm } from '../../forms/CharacterEditForm/CharacterEditForm'

// NEW Character Editor Panel

export function CharacterEditorPanel(props: ICharacterEditorPanelProps): JSX.Element {
  const dispatch = UseAppDispatch()
  const [popout, setPopout] = useState<any>(null)
  const [characterList, setCharacterList] = useState<ICharacterDocument[]>()
  const [refreshKey, setRefreshKey] = useState(0)

  const currentStory = UseAppSelector(
    (state: RootState) => state.story.selectedStory
  )

  const getAllCharacters = async () => {
    if (!currentStory?._id) return

    const charList: ICharacterDocument[] | undefined = await getCreatorCharacterList(currentStory._id)

    if (charList?.length) {
      setCharacterList(charList)
    }
  }

  const startLoading = () => {
    setPopout(<ScreenSpinner size='large' />)
  }

  const stopLoading = () => {
    setPopout(null)
    setRefreshKey((refreshKey) => refreshKey + 1)
  }

  const handleCreateCharacter = async (data: IHandleCreateCharacterProps) => {
    startLoading()

    if (!currentStory?._id) {
      throw new Error('No current story id')
    }

    const sceneData: ICreateCharacterDocument = {
      ...data,
      storyId: currentStory._id,
    }

    try {
      await createCreatorCharacter({ dto: sceneData });
      await getAllCharacters()

    } catch (error) {
      console.error(error)
    }

    stopLoading()
  }

  const handleUpdateScene = async (data: IHandleUpdateCharacterProps) => {
    startLoading()

    if (!currentStory?._id) {
      throw new Error('No current story id')
    }

    const sceneData: ICreateCharacterDocument = {
      ...data,
      storyId: currentStory._id,
    }

    try {
      await updateCreatorCharacter({ dto: sceneData });
      await getAllCharacters()
    } catch (error) {
      console.error(error)
    }

    stopLoading()
  }

  const handleDeleteScene = async (characterId: string) => {
    startLoading()

    try {
        await deleteCreatorCharacter({ characterId, })
        await getAllCharacters()
    } catch (error) {
      console.error(error)
    }

    stopLoading()
  }

  const openDeletion = (characterId: string, characterName: string) => {
    setPopout(
      <Alert
        actions={[
          {
            title: 'Закрыть',
            autoClose: true,
            mode: 'cancel',
          },
          {
            title: 'Удалить',
            autoClose: true,
            mode: 'destructive',
            action: () => handleDeleteScene(characterId),
          },
        ]}
        actionsLayout='horizontal'
        onClose={() => setPopout(null)}
        header='Удаление персонажа'
        text={`Вы уверены, что хотите удалить персонажа '${characterName}'?`}
      />
    );
  };

  useEffect(() => {
    getAllCharacters()
  }, [])

  return (
    <SplitLayout
      className={styles.root}
      popout={popout}
    >
      <Panel>
        <PanelHeader
          before={
            <PanelHeaderBack
              onClick={() => {
                dispatch(setStory('storyEditor'))
              }}
            />
          }
        >
          Персонажи
        </PanelHeader>

        <Group mode='plain'>
          <ul className={styles.list}>
            <li className={styles.item}>
              <EntityCard
                key={refreshKey}
                title={'Создать нового персонажа'}
              >
                <CharacterEditForm
                  handleCreate={handleCreateCharacter}
                  isLoading={!!popout}
                  key={refreshKey}
                />
              </EntityCard>
            </li>
            {
              characterList?.map((character: ICharacterDocument, idx: number) => {
                return (
                  <li
                    className={styles.item}
                    key={idx}
                  >
                    <EntityCard
                      imageUrl={SERVER_IMAGE_API + character.imageUrl}
                      title={character.name}
                    >
                      <CharacterEditForm
                        handleDelete={openDeletion}
                        handleUpdate={handleUpdateScene}
                        isLoading={!!popout}
                        key={refreshKey}
                        character={character}
                      />
                    </EntityCard>
                  </li>
                )
              })
            }
          </ul>
        </Group>
      </Panel>
    </SplitLayout>
  )
}
