import { ICharacterDocument, ICreateCharacterDocument } from "../../features/characters/character.slice";
import { ICreatePhraseDocument, IPhraseDocument } from "../../features/phrase/phrase.slice";
import { ICreateSceneDocument, ISceneDocument } from "../../features/scenes/scene.slice";
import { ICreateStoryDocument, IStoryDocument } from "../../features/story/story.slice";
import api from "../config";

// STORIES
export const getCreatorStoryList = async (): Promise<IStoryDocument | undefined> => {
	try {
		const response = await api.get(`/creator/story-list`);

		return response?.data;
	} catch (error) {
		console.error(error);
	}
};

export const getCreatorStoryOne = async ({
	storyId,
}: {
	storyId: string,
}): Promise<IStoryDocument | undefined> => {
	try {
		const response = await api.get(`/creator/story-one`, {
			params: {
				storyId: storyId,
			},
		});

		return response?.data;
	} catch (error) {
		console.error(error);
	}
};

export const createCreatorStory = async ({
	dto,
}: {
	dto: ICreateStoryDocument,
}): Promise<IStoryDocument | undefined> => {
	try {
		const response = await api.post(`/creator/stories`, {
			...dto,
		});

		return response?.data;
	} catch (error) {
		console.error(error);
	}
};

export const updateCreatorStory = async ({
	storyId,
	dto,
}: {
	storyId: string,
	dto: ICreateStoryDocument,
}): Promise<IStoryDocument | undefined> => {
	try {
		dto._id = storyId;

		const response = await api.put(`/creator/stories`, {
			...dto,
		});

		return response?.data;
	} catch (error) {
		console.error(error);
	}
};

export const askCreatorStoryToModerate = async ({
	storyId,
}: {
	storyId: string,
}): Promise<IStoryDocument | undefined> => {
	try {
		const response = await api.post(`/creator/stories/askForModeration`, {
			storyId: storyId,
		});

		return response?.data;
	} catch (error) {
		console.error(error);
	}
};

export const deleteCreatorStory = async ({
	storyId,
}: {
	storyId: string,
}): Promise<boolean | undefined> => {
	try {
		const response = await api.delete(`/creator/stories`, {
			params: {
				storyId: storyId,
			},
		});

		return response?.data;
	} catch (error) {
		console.error(error);
	}
};

// SCENES
export const getCreatorSceneList = async (storyId: string): Promise<ISceneDocument[] | undefined> => {
	try {
		const response = await api.get(`/creator/scene-list`, {
			params: {
				storyId: storyId,
			}
		});

		return response?.data;
	} catch (error) {
		console.error(error);
	}
};

export const getCreatorSceneOne = async ({
	sceneId,
}: {
	sceneId: string,
}): Promise<ISceneDocument | undefined> => {
	try {
		const response = await api.get(`/creator/scene-one`, {
			params: {
				sceneId: sceneId,
			},
		});

		return response?.data;
	} catch (error) {
		console.error(error);
	}
};

export const createCreatorScene = async ({ dto }: { dto: ICreateSceneDocument }): Promise<ISceneDocument> => {
	try {
		const formData = new FormData();
		Object.entries({ ...dto }).forEach(([key, value]) => {
			// @ts-ignore
			formData.append(key, value);
		});

		const response = await api.post("/creator/scene", formData);
		return response.data;
	} catch (error) {
		console.error(error);
		throw new Error("An error occurred while creating the character.");
	}
};

export const updateCreatorScene = async ({
  dto,
}: {
  dto: ICreateSceneDocument;
}): Promise<ISceneDocument | undefined> => {
  try {
    const formData = new FormData();
    Object.entries({ ...dto }).forEach(([key, value]) => {
      if (Array.isArray(value) && !!value.length) {
        value.forEach((v) => formData.append(key, v))
      } else if (Array.isArray(value) && !value.length) {
				formData.append(key, '')
      } else if (typeof value === "string") {
        formData.append(key, value);
      } else if (value instanceof File) {
        formData.append(key, value, value.name);
      }
    })

    const response = await api.put("/creator/scene", formData);

    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("An error occurred while updating the scene.");
  }
};



export const deleteCreatorScene = async ({
	sceneId,
}: {
	sceneId: string,
}): Promise<boolean | undefined> => {
	try {
		const response = await api.delete(`/creator/scene`, {
			params: {
				sceneId: sceneId,
			},
		});

		return response?.data;
	} catch (error) {
		console.error(error);
	}
};

// CHARACTERS
export const getCreatorCharacterList = async (storyId: string): Promise<ICharacterDocument[] | undefined> => {
	try {
		const response = await api.get(`/creator/character-list`, {
			params: {
				storyId: storyId,
			},
		});

		return response?.data;
	} catch (error) {
		console.error(error);
	}
};

export const getCreatorCharacterOne = async ({
	characterId,
}: {
	characterId: string,
}): Promise<ICharacterDocument | undefined> => {
	try {
		const response = await api.get(`/creator/character-one`, {
			params: {
				characterId: characterId,
			},
		});

		return response?.data;
	} catch (error) {
		console.error(error);
	}
};

export const createCreatorCharacter = async ({ dto }: { dto: ICreateCharacterDocument }): Promise<ICharacterDocument> => {
	try {
		const formData = new FormData();
		Object.entries({ ...dto }).forEach(([key, value]) => {
			formData.append(key, value);
		});

		const response = await api.post("/creator/character", formData);
		return response.data;
	} catch (error) {
		console.error(error);
		throw new Error("An error occurred while creating the character.");
	}
};

export const updateCreatorCharacter = async ({ dto }: { dto: ICreateCharacterDocument }): Promise<ICharacterDocument | undefined> => {
	try {
		const formData = new FormData();
		Object.entries({ ...dto }).forEach(([key, value]) => {
			formData.append(key, value);
		});

		const response = await api.put("/creator/character", formData);
		return response.data;
	} catch (error) {
		console.error(error);
		throw new Error("An error occurred while updating the character.");
	}
};

export const deleteCreatorCharacter = async ({
	characterId,
}: {
	characterId: string,
}): Promise<boolean | undefined> => {
	try {
		const response = await api.delete(`/creator/character`, {
			params: {
				characterId: characterId,
			},
		});

		return response?.data;
	} catch (error) {
		console.error(error);
	}
};

// PHRASES
export const getCreatorPhraseList = async (storyId: string): Promise<IPhraseDocument[] | undefined> => {
	try {
		const response = await api.get(`/creator/phrase-list`, {
			params: {
				storyId: storyId,
			},
		});

		return response?.data;
	} catch (error) {
		console.error(error);
	}
};

export const getCreatorPhraseOne = async ({
	phraseId,
}: {
	phraseId: string,
}): Promise<IPhraseDocument | undefined> => {
	try {
		const response = await api.get(`/creator/phrase-one`, {
			params: {
				phraseId: phraseId,
			},
		});

		return response?.data;
	} catch (error) {
		console.error(error);
	}
};

export const createCreatorPhrase = async ({
	dto,
}: {
	dto: ICreatePhraseDocument,
}): Promise<IPhraseDocument | undefined> => {
	try {
		const response = await api.post(`/creator/phrases`, {
			...dto,
		});

		return response?.data;
	} catch (error) {
		console.error(error);
	}
};

export const updateCreatorPhrases = async ({
	storyId,
	phraseId,
	dto,
}: {
	storyId: string,
	phraseId: string,
	dto: ICreatePhraseDocument,
}): Promise<IPhraseDocument | undefined> => {
	try {
		dto.storyId = storyId;
		dto._id = phraseId;
		const response = await api.put(`/creator/phrases`, {
			...dto,
		});

		return response?.data;
	} catch (error) {
		console.error(error);
	}
};

export const deleteCreatorPhrase = async ({
	phraseId,
}: {
	phraseId: string,
}): Promise<boolean | undefined> => {
	try {
		const response = await api.delete(`/creator/phrases`, {
			params: {
				phraseId: phraseId,
			},
		});

		return response?.data;
	} catch (error) {
		console.error(error);
	}
};

export default api;
