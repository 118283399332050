import {
  Panel,
  PanelHeader,
  Group,
  Alert,
  SplitLayout,
} from "@vkontakte/vkui";
import { useState } from "react";
import { Icon20DoorEnterArrowRightOutline } from "@vkontakte/icons";
import { loginUser, registerUser } from "../../Api/auth.api";
import { cookieSetUserId } from "../../Api/config";
import { setLogin } from "../../features/auth/auth.slice";
import {
  setSelectedUserId,
  setCurrentUser,
  IUserDocument,
} from "../../features/user/user.slice";
import { parseDataFromJwtToken } from "../../utils"
import { UseAppDispatch } from "../../hooks/hooks"
import { setPanel } from "../../features/navigation/navigation.slice"
import { getUserById } from "../../Api/user.api"
import { AuthForm } from "../../forms/AuthForm/AuthForm"
import { IAuthPanelProps } from "./AuthPanel.types"
import { IAuthFormHandleSignInProps, IAuthFormHandleSignUpProps } from "../../forms/AuthForm/AuthForm.types";

export const AuthPanel = ({ id }: IAuthPanelProps) => {
  const [popout, setPopout] = useState<any>(null);

  const dispatch = UseAppDispatch();

  const [isLoading, setIsLoading] = useState(false)

  const openWarning = () => {
    setPopout(
      <Alert
        actions={[
          {
            title: "Попробовать еще раз",
            autoClose: true,
            mode: "cancel",
          },
        ]}
        actionsLayout="vertical"
        onClose={() => setPopout(null)}
        header="Упссс...Произошла ошибка"
        text="Ошибка может быть связана с логином или паролем. Попробуйте войти или зарегистрироваться еще раз"
      />
    );
  };

  const handleSignIn = async ({email, password}: IAuthFormHandleSignInProps) => {
    setIsLoading(true)
    try {
      const loginResult = await loginUser({email, password});
      if (loginResult) {
        const accessToken = loginResult.accessToken;
        dispatch(setLogin(accessToken));
        const userIdFromServer: string = parseDataFromJwtToken(
          loginResult.accessToken
        ).userId;
        if (!userIdFromServer) return;

        const userData: IUserDocument = await getUserById(userIdFromServer);
        if (!userData) return;

        dispatch(setSelectedUserId(userIdFromServer));
        dispatch(setCurrentUser(userData));

        cookieSetUserId(userIdFromServer);
        dispatch(setPanel("epic"));
      }
    } catch (error: any) {
      openWarning();
      console.error(error);
    }
    setIsLoading(false)
  };

  const handleSignUp = async ({email, password}: IAuthFormHandleSignUpProps) => {
    setIsLoading(true)
    try {
      const registerResult = await registerUser({email, password});

      if (registerResult) {
        const accessToken = registerResult.accessToken;
        dispatch(setLogin(accessToken));
        const userIdFromServer: string = parseDataFromJwtToken(
          registerResult.accessToken
        ).userId;
        if (!userIdFromServer) return;

        const userData: IUserDocument = await getUserById(userIdFromServer);
        if (!userData) return;

        dispatch(setSelectedUserId(userIdFromServer));
        dispatch(setCurrentUser(userData));

        cookieSetUserId(userIdFromServer);
        dispatch(setPanel("epic"));
      }
    } catch (error: any) {
      openWarning();
      console.error(error);
    }
    setIsLoading(false)
  };

  return (
    <SplitLayout
      popout={popout}
      style={{ maxWidth: 512, margin: "auto", justifyContent: "center" }}
    >
      <Panel id={id}>
        <PanelHeader
          before={
            <Icon20DoorEnterArrowRightOutline
              width={36}
            />
          }
        >
          Вход
        </PanelHeader>
        <Group>
          <AuthForm
            handleSignUp={handleSignUp}
            handleSignIn={handleSignIn}
            isLoading={isLoading}
          />
        </Group>
      </Panel>
    </SplitLayout>
  );
};
