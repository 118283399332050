import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ModerationStatusEnum } from "../../Interfaces";

export interface IStoryDocument {
	isPublic?: boolean;
	moderationStatus?: ModerationStatusEnum;
	reason?: string;
	comment?: string;
	phraseId?: string,
	_id: string;
	name: string;
	star?: number;
	views?: number;
	author: string;
	about: string;
	tags?: string;
	imageUrl: string;
	startId: string;
	endIds: string[];
	ageLimit: number;
	askForModeration?: boolean,
	photoFile?: File;
	date?: Date;
}

export interface ICreateStoryDocument {
	_id?: string;
	name: string;
	author?: string;
	about?: string;
	tags?: string;
	imageUrl?: string;
	startId?: string;
	phraseId?: string,
	endIds?: string[];
	ageLimit?: number;
	askForModeration?: boolean,
	photoFile?: File;
}

export interface IStoryState {
	allStories: IStoryDocument[];
	selectedStory: IStoryDocument | null;
}

const initialState: IStoryState = {
	allStories: [],
	selectedStory: null,
};

export const storySlice = createSlice({
	name: "story",
	initialState,
	reducers: {
		setSelectedStory: (state, action: PayloadAction<IStoryDocument | null>) => {
			state.selectedStory = action.payload;
		},
		setAllStories: (state, action: PayloadAction<IStoryDocument[]>) => {
			state.allStories = action.payload;
		},
	},
});

export const { setSelectedStory, setAllStories } = storySlice.actions;

export default storySlice.reducer;
