import { RefObject } from 'react'

export const isFileTooBig = (fileRef: RefObject<HTMLInputElement>, maxFileSize: number) => {
  return () => {
    let valid = true
    const files = fileRef?.current?.files
    if (files) {
      const fileArr = Array.from(files)
      fileArr.forEach((file) => {
        if (file.size > maxFileSize) {
          valid = false
        }
      })
    }
    return valid
  }
}
