import { EFFECT_PRESETS } from "../constants/effectPresets"

export interface IEffectProperties {
  image: string
  size: number | string
  blur: number
}

export interface IEffect {
  label: string
  value: string
  type: string
  background: IEffectProperties
  foreground: IEffectProperties
}

export const prepareEffects = (effects: string[]): IEffect[] => {
  if (!effects) return []

  return effects
    .filter((effect) => {
      // TODO: think about any
      return !!(EFFECT_PRESETS as any)[effect]
    })
    .map((effect) => {
    // TODO: think about any
    return (EFFECT_PRESETS as any)[effect]
    })
}
