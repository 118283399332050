import {
  Panel,
  PanelHeader,
  SplitLayout,
  PanelHeaderBack,
  ModalRoot,
  Image,
  SplitCol,
  InfoRow,
  List,
  Button,
  Div,
  FormStatus,
  ButtonGroup,
  Search,
  ScreenSpinner,
} from "@vkontakte/vkui";
import { useEffect, useState } from "react";
import { UseAppDispatch, UseAppSelector } from "../hooks/hooks";
import { ModalPageEnum, setModal, setStory } from "../features/navigation/navigation.slice";
import { RootState } from "../app/store";
import { LoadingStatusType, ModerationStatusEnum } from "../Interfaces";
import { SERVER_IMAGE_API } from "../constants/constants";
import { Icon24Add, Icon24Delete, Icon24PenOutline, Icon24TreeNodesOutline } from "@vkontakte/icons";
import { deleteCreatorPhrase, getCreatorCharacterList, getCreatorPhraseList, getCreatorSceneList } from "../Api/Creator/creator.api";
import { IPhraseDocument, createNextPhraseBySlice, setAllPhrases, setCurrentPhrase } from "../features/phrase/phrase.slice";
import { setAllCharacters } from "../features/characters/character.slice";
import { setAllScenes } from "../features/scenes/scene.slice";
import CharactersModal from "../Components/CharacterModal";
import EditorPhrasesModal from "../Components/EditorPhraseModal";
import { EditorPhraseListModal } from "../Components/PhraseListModal";
import ScenesModal from "../Components/SceneModal";

export const PhraseEditorPanel = ({ id }: { id: string }) => {
  const [search, setSearch] = useState<string>('');
  const [popout, setPopout] = useState<any>(null);
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatusType>("loading");

  const dispatch = UseAppDispatch();

  const currentStory = UseAppSelector(
    (state: RootState) => state.story.selectedStory,
  );

  const currentModal = UseAppSelector(
    (state: RootState) => state.navigation.modal
  ) as string | null;

  const currentPhrase = UseAppSelector(
    (state: RootState) => state.phrase.currentPhrase
  );

  const allPhrases = UseAppSelector(
    (state: RootState) => state.phrase.allPhrases,
  );

  const allCharacters = UseAppSelector(
    (state: RootState) => state.character.allCharacters,
  );

  const allScenes = UseAppSelector(
    (state: RootState) => state.scene.allScenes,
  );

  useEffect(() => {
    if (!currentStory?._id) return;

    const getAllPhrases = async () => {
      const phraseList = await getCreatorPhraseList(currentStory._id);
      phraseList && dispatch(setAllPhrases(phraseList));
    }

    getAllPhrases();
  }, []);

  useEffect(() => {
    if (!currentStory?._id) return;
    startLoading();

    const getAllCharacters = async () => {
      const characterList = await getCreatorCharacterList(currentStory._id);
      characterList && dispatch(setAllCharacters(characterList));
    }

    getAllCharacters();
    stopLoading();
  }, []);

  useEffect(() => {
    if (!currentStory?._id) return;

    startLoading();

    const getAllScenes = async () => {
      const allScenes = await getCreatorSceneList(currentStory._id);
      allScenes && dispatch(setAllScenes(allScenes));
    }

    getAllScenes();

    stopLoading();
  }, []);

  const modal = (
    <ModalRoot activeModal={currentModal}>
      <ScenesModal id="scenes" />
      <CharactersModal id="characters" />
      <EditorPhrasesModal id="phrase" />
      <EditorPhraseListModal id="phraseList" />
    </ModalRoot>
  );

  const removeFromList = async (elem: IPhraseDocument) => {
    const isDeleted = await deleteCreatorPhrase({
      phraseId: elem._id,
    });

    if (isDeleted) {
      dispatch(setAllPhrases([...allPhrases].filter(item => item._id !== elem._id)));
    }
  };

  const checkIsCurrentPhrase = (id: string | undefined) => {
    if (!currentPhrase?._id) return false;
    if (id === currentPhrase._id) return true;
  };

  const getCharacterImgUrl = (id: string | undefined) => {
    if (!id) return;
    if (allCharacters?.length) {
      const imgUrl = allCharacters.find(item => item._id === id)?.imageUrl;
      return imgUrl;
    }
  };

  const getSceneImgUrl = (id: string | undefined) => {
    if (!id) return;
    if (allCharacters?.length) {
      const imgUrl = allScenes.find(item => item._id === id)?.imageUrl;
      return imgUrl;
    }
  };

  const handleCreatePhrase = async () => {
    if (!currentStory) return;
    if (!currentPhrase) {
      const candidate = allPhrases.find(item => item._id === currentStory.startId);
      if (candidate) {
        setCurrentPhrase(candidate);
      }
    }
    if (!currentPhrase) return;

    dispatch(
      createNextPhraseBySlice({
        storyId: currentStory._id,
        dto: {
          storyId: currentStory._id,
          alias: 'Заголовок',
          text: 'Новая фраза',
          sceneId: currentPhrase?.sceneId,
          characterId: currentPhrase?.characterId,
          cost: '0',
          previousPhrases: [],
          nextPhrases: [],
        },
        currentPhrase,
      })
    )
  }

  const startLoading = () => {
    setLoadingStatus("loading");
    setPopout(<ScreenSpinner size="large" state={loadingStatus} />)
    setTimeout(() => {
      stopLoading();
    }, 500);
  };

  const stopLoading = () => {
    setLoadingStatus("done");
    setPopout(null);
  };

  return (
    <SplitLayout
      modal={modal}
      popout={popout}
    >
      <SplitCol width="100%" maxWidth="560px">
        <Panel id={id}>
          <PanelHeader
            before={
              <PanelHeaderBack
                onClick={() => {
                  dispatch(setStory("storyEditor"));
                }}
              />
            }
          >
            <div style={{ display: "flex" }}>
              <Search placeholder="Все фразы" value={search} onChange={(e) => setSearch(e.target.value)} noPadding after={null} />
            </div>
          </PanelHeader>
          <Div>

            <List>
              <Div>
                <Button size="l" stretched appearance="accent" before={<Icon24Add />} onClick={() => handleCreatePhrase()}>
                  Добавить фразу
                </Button>
              </Div>
              {allPhrases.filter(item =>
                item.text.toLowerCase().includes(search.toLowerCase()) ||
                item.alias.toLowerCase().includes(search.toLowerCase()) ||
                item._id.toLowerCase().includes(search.toLowerCase())
              ).map((item: IPhraseDocument, idx) => {
                return (
                  <div
                    key={item._id + Math.random()}
                    onClick={() => {
                      const ph = allPhrases.find(ph => ph._id === item._id);
                      if (ph) {
                        dispatch(setCurrentPhrase(ph));
                      }
                    }}
                    style={{
                      border: currentStory?.startId === item._id ? '2px solid rgb(0,75,25)' : '0',
                      margin: checkIsCurrentPhrase(item._id) ? "5px 0px" : "5px 0px",
                      boxShadow: checkIsCurrentPhrase(item._id) ? 'rgba(255,255,255,1) 0px 0px 25px' : 'none',
                      borderRadius: 10,
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column', padding: 5 }}>
                      <Image
                        size={72}
                        style={{ position: 'absolute', background: 'rgba(0,0,0,0)' }}
                        src={SERVER_IMAGE_API + getSceneImgUrl(item?.sceneId)}
                        borderRadius={"s"}
                      >
                      </Image>
                      <Image
                        size={72}
                        style={{ background: 'rgba(0,0,0,0)' }}
                        src={SERVER_IMAGE_API + getCharacterImgUrl(item?.characterId)}
                        borderRadius={"s"}
                      >
                      </Image>

                      <InfoRow
                        style={{ margin: '10px 0px', width: '100%' }}
                        header={item.alias}
                      >
                        {item.text.slice(0, 75) + (item.text.length > 75 ? "..." : '')}
                      </InfoRow>

                      {currentPhrase?._id === item._id &&
                        <ButtonGroup mode="vertical">
                          <Button
                            size="s"
                            appearance="accent"
                            stretched
                            before={<Icon24PenOutline />}
                            onClick={() => {
                              dispatch(setModal(ModalPageEnum.phrase));
                            }}
                          >
                            Редактировать фразу
                          </Button>

                          <Button
                            size="s"
                            appearance="accent"
                            stretched
                            before={<Icon24TreeNodesOutline />}
                            onClick={() => {
                              dispatch(setStory("phraseLinksEditor"));
                            }}
                          >
                            Связь с другими фразами
                          </Button>
                          <Button
                            size="s"
                            disabled={currentStory?.startId === item._id}
                            appearance="negative"
                            stretched
                            before={<Icon24Delete />}
                            onClick={() => removeFromList(item)}
                          >
                            {currentStory?.startId === item._id ? 'Стартовая фраза' : "Удалить фразу"}
                          </Button>

                        </ButtonGroup >
                      }

                    </div>

                    {item.moderationStatus === ModerationStatusEnum.invalid && (
                      <FormStatus header={item?.reason || "Причина не указана"} mode="error">
                        {item?.comment || "Комментариев модератора нет"}
                      </FormStatus>
                    )}
                  </div>
                );
              })}
            </List>
          </Div>
        </Panel>
      </SplitCol>
    </SplitLayout >
  );
};