import { useEffect, useState } from "react";
import { ISceneDocument } from "../features/scenes/scene.slice";
import { UseAppDispatch, UseAppSelector } from "../hooks/hooks";
import { RootState } from "../app/store";
import { getCreatorSceneList, updateCreatorPhrases } from "../Api/Creator/creator.api";
import {
  CardGrid,
  ContentCard,
  Group,
  ModalPage,
  ModalPageHeader,
  PanelHeaderClose,
} from "@vkontakte/vkui";
import { ModalPageEnum, setModal } from "../features/navigation/navigation.slice";
import { SERVER_IMAGE_API } from "../constants/constants";
import { setAllPhrases, setCurrentPhrase } from "../features/phrase/phrase.slice";

function ScenesModal({ id: string }) {
  const [sceneList, setSceneList] = useState<ISceneDocument[]>([]);

  const currentStory = UseAppSelector(
    (state: RootState) => state.story.selectedStory
  );

  const currentPhrase = UseAppSelector(
    (state: RootState) => state.phrase.currentPhrase,
  );

  const allPhrases = UseAppSelector(
    (state: RootState) => state.phrase.allPhrases,
  );

  const modalHistory = UseAppSelector(
    (state: RootState) => state.navigation.modalHistory
  );

  const dispatch = UseAppDispatch();

  useEffect(() => {
    const getAllStoriesHandle = async () => {
      if (!currentStory?._id) return;

      const charList: ISceneDocument[] | undefined = await getCreatorSceneList(
        currentStory._id
      );

      if (charList?.length) {
        setSceneList(charList);
      }
    };

    getAllStoriesHandle();
  }, []);

  const handleSelectScene = async (scene: ISceneDocument) => {
    if (!currentPhrase?._id) return;
    if (!currentStory?._id) return;
    if (!scene?._id) return;

    const isUpdated = await updateCreatorPhrases({
      storyId: currentStory._id,
      phraseId: currentPhrase._id,
      dto: {
        sceneId: scene._id,
      },
    });

    if (isUpdated) {
      dispatch(setCurrentPhrase({ ...currentPhrase, sceneId: scene._id }));

      const phraseIdx = allPhrases.findIndex(phrase => phrase._id === currentPhrase._id);
      if (phraseIdx !== -1) {
        const phraseList = [...allPhrases];
        phraseList[phraseIdx] = { ...currentPhrase, sceneId: scene._id };
        dispatch(setAllPhrases(phraseList));
      }
    }
  };

  const handleClose = () => {
    if (modalHistory[modalHistory.length - 2] === ModalPageEnum.phrase) {
      dispatch(setModal(ModalPageEnum.phrase));
    } else {
      dispatch(setModal(null));
    }
  };

  return (
    <ModalPage
      id={"scenes"}
      onClose={handleClose}
      settlingHeight={100}
      dynamicContentHeight
      header={
        <ModalPageHeader before={<PanelHeaderClose onClick={handleClose} />}>
          Выберете фон
        </ModalPageHeader>
      }
    >
      <Group description="Сцены">
        <CardGrid size="m">
          {(sceneList &&
            sceneList?.length &&
            sceneList?.map((sceneOne: ISceneDocument, idx: number) => (
              <ContentCard
                mode="outline"
                key={idx}
                onClick={() => {
                  handleSelectScene(sceneOne);
                  handleClose();
                }}
                src={SERVER_IMAGE_API + sceneOne.imageUrl}
                alt="none"
                header={sceneOne.name}
                maxHeight={200}
              />
            ))) ||
            ""}
        </CardGrid>
      </Group>
    </ModalPage>
  );
}

export default ScenesModal;
