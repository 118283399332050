import React from 'react';
import { useDispatch } from 'react-redux';
import { SERVER_IMAGE_API } from '../constants/constants';
import { IStoryDocument, setSelectedStory } from '../features/story/story.slice';
import { setStory } from '../features/navigation/navigation.slice';
import { ContentCard } from '@vkontakte/vkui';

const NovellaCard = ({ cardData }: { cardData: IStoryDocument }) => {
  const dispatch = useDispatch();

  return (
    <ContentCard
      onClick={() => {
        dispatch(setSelectedStory(cardData));
        dispatch(setStory("storyViewer"));
      }}
      src={SERVER_IMAGE_API + cardData.imageUrl}
      alt="photo"
      subtitle={
        (cardData?.tags?.length &&
          cardData?.tags.split(",").join(", ")) ||
        "новелла без тегов"
      }
      header={cardData.name}
      caption={cardData.about}
      text={`♥️ ${Math.round((cardData?.star || 0) * 10) / 10} 👁 ${cardData?.views}`}
    // text={
    // <Div
    //   style={{
    //     display: "flex",
    //     padding: 0,
    //     margin: 0,
    //   }}
    // >
    //   {Array.from({ length: 5 }, (v, i) => i + 1)?.map(
    //     (el, idx) => {
    //       if (el <= Math.round(cardData.star)) {
    //         return <Icon20Like key={idx} />;
    //       } else {
    //         return <Icon20LikeOutline key={idx} />;
    //       }
    //     }
    //   )}
    // </Div>
    // }
    />
  );
};

export default NovellaCard;

