import {
  Panel,
  PanelHeader,
  Group,
  SplitLayout,
  CardGrid,
  Placeholder,
  ContentCard,
  PanelHeaderButton,
  Div,
  Spacing,
} from "@vkontakte/vkui";
import { useEffect, useState } from "react";
import {
  Icon16Like,
  Icon16LikeOutline,
  Icon24RefreshOutline,
  Icon28BookSpreadOutline,
  Icon56MessageReadOutline,
} from "@vkontakte/icons";
import { UseAppDispatch } from "../../hooks/hooks";
import { setStory } from "../../features/navigation/navigation.slice";
import {
  IStoryDocument,
  setSelectedStory,
} from "../../features/story/story.slice";
import { SERVER_IMAGE_API } from "../../constants/constants";
import { getReaderUserStoryLibrary } from "../../Api/Reader/reader.api";
import NovellaCard from "../../Components/NovellaCard.component";

export const ReadProgressPanel = ({ id }: { id: string }) => {
  const [popout, setPopout] = useState<any>(null);
  const [refresh, setRefresh] = useState<boolean>(true);

  const [storyList, setStoryList] = useState<IStoryDocument[]>([]);

  const dispatch = UseAppDispatch();

  useEffect(() => {
    const getList = async () => {
      const list: IStoryDocument[] | undefined =
        await getReaderUserStoryLibrary();
      if (list) {
        setStoryList(list);
      }
    };
    getList();
  }, [refresh]);

  return (
    <SplitLayout popout={popout} style={{ maxWidth: 512, margin: "auto", justifyContent: "center" }}>
      <Panel id={id}>
        <PanelHeader
          before={
            <PanelHeaderButton aria-label="vk">
              <Icon28BookSpreadOutline />
            </PanelHeaderButton>
          }
        >
          История
        </PanelHeader>
        <Group>
          <CardGrid size="m">
            {(storyList.length &&
              storyList.map((cardData: IStoryDocument) => (
                <NovellaCard key={cardData._id} cardData={cardData} />
              ))) ||
              ""}
            {(!storyList.length && (
              <Placeholder
                stretched
                icon={<Icon56MessageReadOutline />}
                header="Вы пока ничего не читали"
              >
                <>
                  Перейдите в раздел Библиотека, там есть что посмотреть ;)
                  <Spacing size={36} />
                </>
              </Placeholder>
            )) ||
              ""}
          </CardGrid>
        </Group>
      </Panel>
    </SplitLayout>
  );
};
