import { createSlice } from "@reduxjs/toolkit";

export enum ModalPageEnum {
  scenes = 'scenes',
  stories = 'stories',
  users = 'users',
  characters = 'characters',
  phrase = 'phrase',
  phraseList = 'phraseList',
}

type InitialStateType = {
  view: string,
  panel: string,
  story: string,
  modal: ModalPageEnum | null,
  history: string[],
  modalHistory: ModalPageEnum[],
}

const initialState: InitialStateType = {
  view: "main",
  panel: "start",
  story: "library",
  modal: null,
  history: ['auth'],
  modalHistory: [],
};

export const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    setView: (state, action) => {
      state.history.push(state.view);
      state.view = action.payload;
    },
    setModal: (state, action) => {
      state.modalHistory.push(action.payload);
      state.modal = action.payload;
    },
    setPanel: (state, action) => {
      state.history.push(state.panel);
      state.panel = action.payload;
    },
    setStory: (state, action) => {
      state.story = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setView, setPanel, setStory, setModal } = navigationSlice.actions;

export default navigationSlice.reducer;
