import {
  Panel,
  PanelHeader,
  Group,
  SplitLayout,
  Header,
  HorizontalScroll,
  CardScroll,
  Tabs,
  TabsItem,
  Spacing,
  MiniInfoCell,
  PanelHeaderButton,
  Link,
  Div,
  ButtonGroup,
  Button,
  Text,
  Alert,
} from "@vkontakte/vkui";
import { useEffect, useState } from "react";
import {
  Icon20ArticleOutline,
  Icon24DrillOutline,
  Icon24GameOutline,
  Icon24GhostOutline,
  Icon24GunOutline,
  Icon24HealthOutline,
  Icon24KeyOutline,
  Icon24MasksOutline,
  Icon24Search,
  Icon24StarsOutline,
  Icon28BookSpreadOutline,
  Icon28SettingsOutline,
  Icon28StorefrontOutline,
  Icon28UserOutline,
} from "@vkontakte/icons";
import { UseAppDispatch } from "../hooks/hooks";
import { setStory } from "../features/navigation/navigation.slice";
import React from "react";
import {
  IStoryDocument,
} from "../features/story/story.slice";
import { getReaderAllStories } from "../Api/Reader/reader.api";
import { VkAdsButton } from "../Components/AdsButton";
import { VkBuy10KeysPer5VoteButton } from "../Components/Buy10KeysPer5Vote.button";
import { VkInviteButton } from "../Components/InviteButton";
import { VkShareButton } from "../Components/ShareButton";
import { VkBuy100KeysPer25VoteButton } from "../Components/Buy100KeysPer25Vote.button";
import { VkBuyUnlimitedKeysPer1500VoteButton } from "../Components/BuyCustomStoryPer500Vote.button";
import NovellaCard from "../Components/NovellaCard.component";
import bridge, { BannerAdLocation } from "@vkontakte/vk-bridge";

export const LibraryPanel = ({ id }: { id: string }) => {
  const [popout, setPopout] = useState<any>(null);
  const [selectedTag, setSelectedTag] = useState<string | null>(null);

  const [allStories, setAllStories] = useState<IStoryDocument[]>([]);

  const dispatch = UseAppDispatch();

  useEffect(() => {
    bridge.send('VKWebAppShowBannerAd', {
      banner_location: BannerAdLocation.BOTTOM
    })
      .then((data) => {
        if (data.result) {
          console.log('Bunner was shown');
        }
      })
      .catch((error) => {
        console.log(error);
      });

    const getAllStoriesHandle = async () => {
      const resultArr = await getReaderAllStories();
      if (resultArr) {
        setAllStories(resultArr);
      }
    };
    getAllStoriesHandle();
  }, []);

  const handleOnboarding1Menu = () => {
    setPopout(
      <Alert
        actionsLayout="vertical"
        onClose={closePopout}
        header="Знакомство!"
        text={
          <>
            <Text>Novella Maker это библиотека новелл от пользователей VK. Наслаждайтесь работами наших мейкеров и удивляйте своими</Text>
            <Spacing />
            <MiniInfoCell textWrap="full" before={<Icon28StorefrontOutline />}>
              "Библиотека" - текущая страница с главными новостями и свежими новеллами
            </MiniInfoCell>

            <MiniInfoCell textWrap="full" before={<Icon28UserOutline />}>
              "Мои новеллы" - созданные вами новеллы. Есть модерация
            </MiniInfoCell>

            <MiniInfoCell textWrap="full" before={<Icon28BookSpreadOutline />}>
              "История" - список новелл, которые вы читали
            </MiniInfoCell>

            <MiniInfoCell textWrap="full" before={<Icon28SettingsOutline />}>
              "Настройки" - общая информация о вас
            </MiniInfoCell>
          </>
        }
        actions={[
          {
            title: 'Далее',
            mode: 'default',
            action: () => handleOnboarding2Money(),
          },
          {
            title: 'Пропустить обучение',
            mode: 'cancel',
            action: () => closePopout(),
          },
        ]}
      />
    );
  }

  const handleOnboarding2Money = () => {
    setPopout(
      <Alert
        actionsLayout="vertical"
        onClose={closePopout}
        header="Местная валюта - ключики"
        text={
          <>
            <Text>Новеллы не линейные, в них есть выбор с интересными последствиями. Часть из них можно пройти расплатившись ключиками</Text>

            <Spacing />

            <MiniInfoCell textWrap="full" before={<Icon24KeyOutline />}>
              Ключики открывают скрытые части новелл
            </MiniInfoCell>

            <MiniInfoCell textWrap="full" before={<Icon24KeyOutline />}>
              Заработать ключики можно смотря рекламу или рассказывая друзьям о Novella Maker, через репосты и приглашения
            </MiniInfoCell>

            <MiniInfoCell textWrap="full" before={<Icon24KeyOutline />}>
              Ключики так же можно выгодно купить, в том числе в безлимитном количестве
            </MiniInfoCell>
          </>
        }
        actions={[
          {
            title: 'Далее',
            mode: 'default',
            action: () => handleOnboarding3Maker(),
          },
          {
            title: 'Пропустить обучение',
            mode: 'cancel',
            action: () => closePopout(),
          },
        ]}
      />
    );
  }

  const handleOnboarding3Maker = () => {
    setPopout(
      <Alert
        actionsLayout="vertical"
        onClose={closePopout}
        header="Создание своих новелл"
        text={
          <>
            <Text>Творчество - дело интересное, особенно когда можно воплотить свои задумки. Это может быть фанфик вашего любимого кино, книги или игры
            </Text>

            <Spacing />

            <MiniInfoCell textWrap="full" before={<Icon28UserOutline />}>
              "Мои новеллы" - меню создания своих новелл
            </MiniInfoCell>

            <MiniInfoCell textWrap="full" before={<Icon28UserOutline />}>
              Редактор дает много возможностей. Мы создали обучение в канале на Youtube. Найти канал можно в разделе "Мои новеллы"
            </MiniInfoCell>

            <MiniInfoCell textWrap="full" before={<Icon28UserOutline />}>
              ИИ - наше все. Картинки для фонов и персонажей нам рисует Stable Diffustion, фон вырезает clipdrop.co
            </MiniInfoCell>

            <Text>Теперь вы знаете все! Добро пожаловать в мир Novella Maker!</Text>
          </>
        }
        actions={[
          {
            title: 'Выйти из обучения',
            mode: 'cancel',
            action: () => closePopout(),
          },
        ]}
      />
    );
  }

  const closePopout = () => {
    setPopout(null);
  };

  return (
    <SplitLayout
      popout={popout}
      style={{ maxWidth: 512, margin: "auto", justifyContent: "center" }}
    >
      <Panel id={id}>
        <PanelHeader
          before={
            <React.Fragment>
              {/* <PanelHeaderButton aria-label="vk" disabled={true}>
                <Icon28Notifications />
              </PanelHeaderButton> */}
              <PanelHeaderButton aria-label="vk">
                <Icon28StorefrontOutline />
              </PanelHeaderButton>
            </React.Fragment>
          }
        >
          Библиотека
        </PanelHeader>

        <Div>
          <Button
            style={{
              boxShadow: '0 0 0 0 rgba(255, 105, 180, 0.7)',
              animation: 'pulse 3s infinite',
            }}
            stretched
            aria-label="vk"
            onClick={() => handleOnboarding1Menu()}
            mode="secondary"
            before={
              <Icon24StarsOutline height={72} />
            }
          >
            Впервые у нас? - давайте знакомиться!
          </Button>
        </Div>

        <Spacing />

        <Group separator="hide" header={<Header mode="secondary">Новинки</Header>}>
          <CardScroll size="m">
            {allStories?.length &&
              allStories
                .slice(allStories.length >= 3 ? allStories.length - 3 : allStories.length - 1, allStories.length)
                .map((cardData: IStoryDocument) => <NovellaCard key={cardData._id} cardData={cardData} />)}
          </CardScroll>
        </Group>

        <Div>
          <Button
            stretched
            size="l"
            before={<Icon24Search />}
            aria-label="vk"
            mode="secondary"
            onClick={() => dispatch(setStory("search"))}
          >
            Поиск новелл
          </Button>
        </Div>

        <Group
          mode="card"
          header={<Header mode="secondary">Новеллы по жанрам</Header>}
        >
          <Tabs>
            <HorizontalScroll arrowSize="l" showArrows='always'>
              <TabsItem
                id="все"
                aria-labelledby="все"
                aria-controls="все"
                selected={selectedTag === "все"}
                onClick={() => setSelectedTag(null)}
              >
                Все
              </TabsItem>
              <TabsItem
                id="романтика"
                aria-labelledby="романтика"
                aria-controls="романтика"
                before={<Icon24HealthOutline />}
                selected={selectedTag === "романтика"}
                onClick={() => setSelectedTag("романтика")}
              >
                Романтика
              </TabsItem>
              <TabsItem
                id="комедия"
                aria-labelledby="комедия"
                aria-controls="комедия"
                before={<Icon24MasksOutline />}
                selected={selectedTag === "комедия"}
                onClick={() => setSelectedTag("комедия")}
              >
                Комедия
              </TabsItem>
              <TabsItem
                id="ужасы"
                aria-labelledby="ужасы"
                aria-controls="ужасы"
                before={<Icon24DrillOutline />}
                selected={selectedTag === "ужасы"}
                onClick={() => setSelectedTag("ужасы")}
              >
                Ужастики
              </TabsItem>
              <TabsItem
                id="игра"
                aria-labelledby="игра"
                aria-controls="игра"
                before={<Icon24GameOutline />}
                selected={selectedTag === "игра"}
                onClick={() => setSelectedTag("игра")}
              >
                Игровое
              </TabsItem>
              <TabsItem
                id="аниме"
                aria-labelledby="аниме"
                aria-controls="аниме"
                before={<Icon24GhostOutline />}
                selected={selectedTag === "аниме"}
                onClick={() => setSelectedTag("аниме")}
              >
                Аниме
              </TabsItem>
              <TabsItem
                id="детектив"
                aria-labelledby="детектив"
                aria-controls="детектив"
                before={<Icon24GunOutline />}
                selected={selectedTag === "детектив"}
                onClick={() => setSelectedTag("детектив")}
              >
                Детектив
              </TabsItem>
            </HorizontalScroll>
          </Tabs>

          <Spacing size={32} />

          <CardScroll size="m">
            {
              allStories?.length &&
              allStories
                .filter((item) =>
                  selectedTag ? item.tags?.includes(selectedTag) : true
                )
                .slice(0, 5)
                .map((cardData: IStoryDocument) => <NovellaCard key={cardData._id} cardData={cardData} />)
            }
          </CardScroll>
        </Group>

        <Group
          header={
            <Header indicator={<Icon24KeyOutline />}>Получение ключей</Header>
          }
        >
          <Div>
            <ButtonGroup gap="m" mode={"vertical"} stretched>
              <VkAdsButton
                setPopout={setPopout}
                requestKey={LibraryPanel.name}
                stretched
              />
              <VkInviteButton
                setPopout={setPopout}
                requestKey={LibraryPanel.name}
                stretched
              />
              <VkShareButton
                setPopout={setPopout}
                requestKey={LibraryPanel.name}
                stretched
              />
              <VkBuy10KeysPer5VoteButton
                setPopout={setPopout}
                requestKey={LibraryPanel.name}
                stretched
              />
              <VkBuy100KeysPer25VoteButton
                setPopout={setPopout}
                requestKey={LibraryPanel.name}
                stretched
              />
              <VkBuyUnlimitedKeysPer1500VoteButton
                setPopout={setPopout}
                requestKey={LibraryPanel.name}
                stretched
              />
            </ButtonGroup>
          </Div>
        </Group>

        <Spacing size={32} />

        <MiniInfoCell before={<Icon20ArticleOutline />} textWrap="full">
          Novella Maker - место, где встречаются читатели и создатели новелл.
          Если вы хотели когда-либо создать свою нелинейную историю, то у нас вы
          сможете это сделать максимально просто и быстро.
        </MiniInfoCell>

        <Spacing size={32} />

        <Group mode="card" header={<Header mode="secondary">Контакты</Header>}>
          <Div>
            <Link href={"https://vk.com/novella_maker"} target="_blank">
              Сообщество
            </Link>
          </Div>
          <Div>
            <Link href={"https://vk.com/im?sel=-219932114"} target="_blank">
              Разработчики
            </Link>
          </Div>
        </Group>
      </Panel>
    </SplitLayout>
  );
};
