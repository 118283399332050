import { IStoryDocument } from "../features/story/story.slice";

export enum StoryReadStatusEnum {
	notStarted = 'notStarted',
	onStart = 'onStart',
	inTheEnd = 'inTheEnd',
	inProgress = 'inProgress'
}

export enum ModerationStatusEnum {
	notModerated = 'notModerated',
	valid = 'valid',
	invalid = 'invalid',
}

export type LoadingStatusType = "loading" | "done" | "cancelable" | "error" | undefined;

export interface ReaderGamePageDto {
	sceneData: ReaderGameSceneDto;
	characterData: ReaderGameCharacterDto;
	answers?: ReaderGameAnswersDto[];
	storyId: string;
	storyTitle: string;
	storyImageUrl: string;
	storyAbout: string;
	storyReadingStatus: StoryReadStatusEnum;
	phraseId: string;
	phraseText: string;
	phraseAlias: string;
}


export interface ReaderGameSceneDto {
	sceneTitle: string;
	scenePhotoUrl: string;
	sceneId: string;
	effects: string[]
}

export interface ReaderGameCharacterDto {
	characterTitle: string;
	characterPhotoUrl: string;
	characterId: string;
}

export interface ReaderGameAnswersDto {
	cost: number;
	buttonAlias: string;
	phraseId: string;
}

export interface ReaderGameAboutDto {
	story: ReaderGameAboutStoryDto;
	author: ReaderGameAboutAuthorDto;
	storyReadingStatus: StoryReadStatusEnum;
}

export interface ReaderGameAboutStoryDto extends IStoryDocument {
	storyId: string;
	name: string;
	about: string;
	tags: string;
	imageUrl: string;
	startId: string;
	endIds: string[];
	ageLimit: number;
	star: number;
	date?: Date;
}

export interface ReaderGameAboutAuthorDto {
	authorId: string;
	nickname: string;
	age: number;
}