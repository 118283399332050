import { configureStore } from "@reduxjs/toolkit";
import sceneReducer from "../features/scenes/scene.slice";
import characterReducer from "../features/characters/character.slice";
import userReducer from "../features/user/user.slice";
import AuthReducer from "../features/auth/auth.slice";
import StoryReducer from "../features/story/story.slice";
import PhraseReducer from "../features/phrase/phrase.slice";
import NavigationReducer from "../features/navigation/navigation.slice";

export const store = configureStore({
  reducer: {
    scene: sceneReducer,
    character: characterReducer,
    user: userReducer,
    auth: AuthReducer,
    story: StoryReducer,
    phrase: PhraseReducer,
    navigation: NavigationReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
