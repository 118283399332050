import { ICreateUserDocument, IUserDocument } from '../features/user/user.slice';
import api from './config';

export const getUserById = async (userId: string): Promise<IUserDocument> => {
  try {
    const response = await api.get(`/users/${userId}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error('An error occurred while fetching the user.');
  }
};

export const updateUserById = async (userId: string, userData: ICreateUserDocument): Promise<IUserDocument> => {
  try {
    const response = await api.put(`/users/${userId}`, userData);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error('An error occurred while updating the user.');
  }
};

export default api;