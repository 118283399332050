import {
  Panel,
  PanelHeader,
  SplitLayout,
  FormItem,
  FormLayoutGroup,
  Button,
  ButtonGroup,
  Alert,
  PanelHeaderBack,
  Div,
  Group,
  Header,
} from "@vkontakte/vkui";
import { useEffect, useState } from "react";
import { UseAppDispatch, UseAppSelector } from "../../hooks/hooks";
import { setPanel, setStory } from "../../features/navigation/navigation.slice";
import { RootState } from "../../app/store";
import { ReaderGameAboutDto, StoryReadStatusEnum } from "../../Interfaces";
import {
  getReaderStoryAbout,
  removeStoryProgress,
  resetReaderStoryProgress,
  setStoryStar,
} from "../../Api/Reader/reader.api";
import { IStoryDocument } from "../../features/story/story.slice";
import { Icon24Like, Icon24LikeOutline } from "@vkontakte/icons";
import NovellaCard from "../../Components/NovellaCard.component";

export const ReaderStoryAboutPanel = ({ id }: { id: string }) => {
  const [storyData, setStoryData] = useState<ReaderGameAboutDto>();
  const [popout, setPopout] = useState<any>(null);
  const [refresh, setRefresh] = useState<boolean>(true);

  const dispatch = UseAppDispatch();

  const selectedStory: ReaderGameAboutDto | IStoryDocument | null =
    UseAppSelector((state: RootState) => state.story.selectedStory);

  useEffect(() => {
    if (!selectedStory?._id) {
      return;
    }
    const fetchPageData = async () => {
      const readerStoryData = await getReaderStoryAbout({
        storyId: selectedStory?._id,
      });

      if (readerStoryData) {
        setStoryData(readerStoryData);
      }
    };
    fetchPageData();
  }, [selectedStory, id, refresh]);

  const handleRead = async () => {
    try {
      if (storyData) {
        dispatch(setPanel("phraseViewer"));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleRestoreProgress = async () => {
    try {
      if (!selectedStory?._id) {
        return;
      }

      if (!storyData) {
        return;
      }

      const progressPhraseId = await resetReaderStoryProgress({
        storyId: selectedStory._id,
      });

      if (progressPhraseId) {
        if (!storyData?.storyReadingStatus) {
          storyData.storyReadingStatus = StoryReadStatusEnum.notStarted;
        } else {
          storyData.storyReadingStatus = StoryReadStatusEnum.notStarted;
        }
        setStoryData(storyData);
        setRefresh(!refresh);
      }
      // TODO add stars to set by user
    } catch (error) {
      console.error(error);
    }
  };

  const handleRemoveStoryProgress = async (args: any) => {
    try {
      if (!selectedStory?._id) {
        return;
      }

      await removeStoryProgress({
        storyId: selectedStory._id,
      });
    } catch (error) {
      console.error(error);
    }
    dispatch(setStory("library"));
  };

  const handleForget = async () => {
    try {
      if (selectedStory?._id) {
        openWarning(
          "Убрать историю из вашей библиотеки?",
          handleRemoveStoryProgress,
          [selectedStory._id]
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const openWarning = async (message?: string, func?: any, argus?: any) => {
    setPopout(
      <Alert
        actions={[
          {
            title: "Закрыть",
            autoClose: true,
            mode: "cancel",
          },
          {
            title: "Подтвердить",
            autoClose: true,
            mode: "destructive",
            action: async () => {
              return func ? await func(...argus) : undefined;
            },
          },
        ]}
        actionsLayout="vertical"
        onClose={() => setPopout(null)}
        header="Уточнение"
        text={message || "Данные обновлены"}
      />
    );
  };

  const handleSetStar = async (starsCount: number) => {
    if (!selectedStory?._id) {
      return;
    }

    const result = await setStoryStar({
      storyId: selectedStory._id,
      star: starsCount,
    });

    if (result || result === 0) {
      setPopout(
        <Alert
          actions={[
            {
              title: "Закрыть",
              autoClose: true,
              mode: "cancel",
            },
          ]}
          actionsLayout="horizontal"
          onClose={() => setPopout(null)}
          header="Оценка"
          text={
            <>
              {`Ваша оценка учтена!`}
              <Div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: 0,
                  paddingTop: 15,
                }}
              >
                {Array.from({ length: 5 }, (v, i) => i + 1)?.map((el, idx) => {
                  if (el <= Math.round(starsCount || 0)) {
                    return <Icon24Like key={idx} />;
                  } else {
                    return <Icon24LikeOutline key={idx} />;
                  }
                })}
              </Div>
            </>
          }
        />
      );
    }

    setRefresh(refresh);
    return;
  };

  const startButtonText = (storyData?.storyReadingStatus ===
    StoryReadStatusEnum.inProgress &&
    "Продолжить") ||
    (storyData?.storyReadingStatus ===
      StoryReadStatusEnum.notStarted &&
      "Читать") ||
    (storyData?.storyReadingStatus ===
      StoryReadStatusEnum.onStart &&
      "Начать читать") ||
    (storyData?.storyReadingStatus ===
      StoryReadStatusEnum.inTheEnd &&
      "Начать сначала")

  const showStartButton = storyData?.storyReadingStatus ===
    StoryReadStatusEnum.inProgress ||
    storyData?.storyReadingStatus === StoryReadStatusEnum.notStarted ||
    storyData?.storyReadingStatus === StoryReadStatusEnum.onStart ||
    storyData?.storyReadingStatus === StoryReadStatusEnum.inTheEnd

  return (
    <SplitLayout popout={popout} style={{ maxWidth: 512, margin: "auto", justifyContent: "center" }}>
      <Panel id={id}>
        <PanelHeader
          before={
            <PanelHeaderBack
              onClick={() => {
                dispatch(setStory("library"));
              }}
            />
          }
        >
          {storyData?.story.name}
        </PanelHeader>

        <FormLayoutGroup mode="vertical">
          <FormItem>
            {storyData?.story.imageUrl && (
              <NovellaCard cardData={storyData.story} />
            )}
          </FormItem>


          <FormItem>
            {(storyData?.storyReadingStatus ===
              StoryReadStatusEnum.inProgress ||
              storyData?.storyReadingStatus ===
              StoryReadStatusEnum.inTheEnd) && (

                <Group separator="show" header={<Header mode="secondary">Оценить новеллу</Header>}>
                  <Div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: 0,
                      paddingTop: 10,
                      paddingBottom: 20,
                    }}
                  >
                    {Array.from({ length: 5 }, (v, i) => i + 1)?.map((el, idx) => {
                      if (el <= Math.round(storyData?.story?.star || 0)) {
                        return <Icon24Like key={idx}
                          onClick={() => handleSetStar(idx + 1)}
                        />;
                      } else {
                        return <Icon24LikeOutline key={idx}
                          onClick={() => handleSetStar(idx + 1)}
                        />;
                      }
                    })}
                  </Div>
                </Group>
              )}

            <ButtonGroup mode="vertical" gap="s" align="center" stretched>
              {
                showStartButton && (
                  <Button
                    size="l"
                    appearance="positive"
                    stretched
                    onClick={handleRead}
                  >
                    {startButtonText}
                  </Button>
                )
              }

              {(storyData?.storyReadingStatus ===
                StoryReadStatusEnum.inProgress ||
                storyData?.storyReadingStatus ===
                StoryReadStatusEnum.inTheEnd) && (
                  <>
                    <Button
                      size="m"
                      appearance="neutral"
                      stretched
                      onClick={handleRestoreProgress}
                    >
                      Сбросить прогресс
                    </Button>
                  </>
                )}

              {storyData?.storyReadingStatus !==
                StoryReadStatusEnum.notStarted && (
                  <Button
                    size="m"
                    appearance="neutral"
                    stretched
                    onClick={handleForget}
                  >
                    Удалить из вашей библиотеки
                  </Button>
                )}
            </ButtonGroup>
          </FormItem>
        </FormLayoutGroup>
      </Panel>
    </SplitLayout>
  );
};
