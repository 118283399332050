import { ICreateUserDocument } from "../features/user/user.slice";
import api, { cookieSetAuthToken } from "./config";

export const registerUser = async (userData: ICreateUserDocument) => {
  try {
    const response = await api.post("/auth/register", userData);
    const resultOfLogin = await loginUser(userData);
    const accessToken = resultOfLogin?.data?.accessToken;
    cookieSetAuthToken(accessToken);

    return resultOfLogin;
  } catch (error) {
    console.error(error);
    throw new Error("An error occurred while registering the user.");
  }
};

export const registerUserByVk = async (
  userData: ICreateUserDocument,
  urlParamsString: string
) => {
  try {
    await api.post("/auth/registerByVk", {
      ...userData,
      urlParamsString: urlParamsString,
    });
    const resultOfLogin = await loginUserByVk(urlParamsString);
    const accessToken = resultOfLogin.data.accessToken;
    cookieSetAuthToken(accessToken);

    return resultOfLogin.data;
  } catch (error) {
    console.error(error);
    throw new Error("An error occurred while registering the user.");
  }
};

export const loginUser = async (userData: ICreateUserDocument) => {
  try {
    const response = await api.post("/auth/login", userData);
    const accessToken = response?.data?.accessToken;
    cookieSetAuthToken(accessToken);

    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("An error occurred while logging in.");
  }
};

export const loginUserByVk = async (urlParamsString: string) => {
  try {
    const response = await api.post("/auth/loginByVk", {
      urlParamsString: urlParamsString,
    });
    const accessToken = response?.data?.accessToken;
    cookieSetAuthToken(accessToken);

    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(error as string);
  }
};

export const logoutUser = async () => {
  try {
    const response = await api.post("/auth/logout");
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("An error occurred while logging out.");
  }
};
