import { FormEventHandler, useState } from 'react'
import cn from 'classnames'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import {
  Button,
  Checkbox,
  FormItem,
  FormLayout,
  Input,
  Link,
  SegmentedControl,
} from '@vkontakte/vkui'

import { IAuthFormProps } from './AuthForm.types'
import styles from './AuthForm.module.scss'

export function AuthForm({
  className,
  isLoading,
  handleSignUp,
  handleSignIn,
}: IAuthFormProps): JSX.Element {

  const formik = useFormik({
    initialValues: {
      enterWay: 'signIn',
      email: '',
      password: '',
      policy: true,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Некорректный адрес электронной почты')
        .required('Обязательное поле'),
      password: Yup.string()
        .max(15, 'Должно быть не более 15 символов')
        .min(6, 'Должно быть не менее 6 символов')
        .required('Обязательное поле'),
      policy: Yup.boolean().equals([true], 'Соглашение обязательное'),
      enterWay: Yup.string().required('Обязательное поле'),
    }),
    validateOnBlur: true,
    onSubmit: (values) => {
      if (formik.values.enterWay === 'signUp') {
        handleSignUp(values)
      }
      if (formik.values.enterWay === 'signIn') {
        handleSignIn(values)
      }
    },
  })

  const isDisabled = isLoading || !formik.isValid || !formik.dirty

  return (
    <FormLayout
      className={cn(styles.root, className)}
      onSubmit={formik.handleSubmit as FormEventHandler<HTMLElement>}
    >
      <FormItem top='Тип входа'>
        <SegmentedControl
          id='enterWay'
          name='enterWay'
          onChange={(signType: any) => {

            const e = {
              target: {
                id: 'enterWay',
                name: 'enterWay',
                touched: false,
                value: signType,
              },
            }
            formik.handleChange(e)
          }}
          options={[
            {
              label: 'Войти',
              value: 'signIn',
            },
            {
              label: 'Зарегистрироваться',
              value: 'signUp',
            },
          ]}
        />
      </FormItem>

      <FormItem
        htmlFor='email'
        top='E-mail'
        status={formik.touched.email && formik.errors.email ? 'error' : 'default'}
        bottom={formik.touched.email && formik.errors.email}
        bottomId='email-type'
      >
        <Input
          id='email'
          name='email'
          type='email'
          aria-labelledby='email-type'
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </FormItem>

      <FormItem
        top='Пароль'
        htmlFor='password'
        bottomId='password-type'
        status={formik.touched.password && formik.errors.password ? 'error' : 'default'}
        bottom={formik.touched.password && formik.errors.password}
      >
        <Input
          id='password'
          name='password'
          type='password'
          aria-labelledby='password-type'
          placeholder='Введите пароль'
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </FormItem>

      <FormItem
        top='Соглашение'
        htmlFor='policy'
        bottomId='policy-type'
        status={formik.touched.policy && formik.errors.policy ? 'error' : 'default'}
        bottom={formik.touched.policy && formik.errors.policy}
      >
        <Checkbox
          id='policy'
          name='policy'
          aria-labelledby='policy-type'
          checked={formik.values.policy}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        >
          Согласен со всем <Link>этим</Link>
        </Checkbox>
      </FormItem>

      <FormItem>
        <Button
          disabled={isDisabled}
          type='submit'
          size='l'
          appearance='positive'
          stretched
        >
          {formik.values.enterWay === 'signUp' ? 'Зарегистрироваться' : 'Войти'}
        </Button>
      </FormItem>
    </FormLayout >
  )
}
