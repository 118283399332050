import {
  Panel,
  PanelHeader,
  Group,
  SplitLayout,
  ContentCard,
  CardGrid,
  PanelHeaderButton,
  Button,
  Placeholder,
  CellButton,
  Spacing,
  Div,
  Header,
} from "@vkontakte/vkui";
import { useEffect, useState } from "react";
import {
  Icon16Like,
  Icon16LikeOutline,
  Icon28AddOutline,
  Icon28UserOutline,
  Icon56LogoClipsOutline,
} from "@vkontakte/icons";
import { UseAppDispatch } from "../hooks/hooks";
import { setStory } from "../features/navigation/navigation.slice";
import {
  IStoryDocument,
  setSelectedStory,
} from "../features/story/story.slice";
import { SERVER_IMAGE_API } from "../constants/constants";
import { getReaderAuthorStoryList } from "../Api/Reader/reader.api";

export const MyStoriesPanel = ({ id }: { id: string }) => {
  const [popout, setPopout] = useState<any>(null);
  const [refresh, setRefresh] = useState<boolean>(true);

  const [storyList, setStoryList] = useState<IStoryDocument[]>([]);

  const dispatch = UseAppDispatch();

  useEffect(() => {
    const getList = async () => {
      const list: IStoryDocument[] | undefined =
        await getReaderAuthorStoryList();
      if (list) {
        setStoryList(list);
      }
    };
    getList();
  }, [refresh]);

  return (
    <SplitLayout
      popout={popout}
      style={{ maxWidth: 512, margin: "auto", justifyContent: "center" }}
    >
      <Panel id={id}>
        <PanelHeader
          before={
            <PanelHeaderButton aria-label="vk">
              <Icon28UserOutline />
            </PanelHeaderButton>
          }>
          Мои новеллы
        </PanelHeader>

        <Div>
          <Group
            mode="card"
            separator="hide"
            header={<Header mode="secondary">Обучение по созданию новелл</Header>}
          > 
            <iframe style={{ borderRadius: 5, border: 'none' }} width="100%" height={"260px"} src="https://www.youtube.com/embed/IBHWxbhVb4g" title="Novella Maker - обучение" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </Group>
        </Div> 

        <Div>
          <Button
            stretched
            mode="primary"
            before={<Icon28AddOutline />}
            style={{ padding: 15 }}
            onClick={() => {
              dispatch(setSelectedStory(null));
              dispatch(setStory("storyEditor"));
            }}
          >
            Создать историю
          </Button>
        </Div>

        <Spacing size={16} />

        <Group
          padding="s"
          separator="hide"
          //   style={{ margin: 5, padding: 1 }}
          description="Доступные для модерации истории"
        >
          <CardGrid size="m">
            {!storyList.length && (
              <Placeholder
                icon={<Icon56LogoClipsOutline />}
                stretched
                header="Вы еще не создали ни одной истории"
                action={
                  <>
                    <Button
                      size="m"
                      onClick={() => {
                        dispatch(setSelectedStory(null));
                        dispatch(setStory("storyEditor"));
                      }}
                    >
                      Создать первую историю
                    </Button>
                    <Spacing size={36} />
                  </>
                }
              >
                Скоро ваш талант раскроется!
              </Placeholder>
            )}

            {(storyList.length &&
              storyList?.map((item: IStoryDocument, idx: number) => (
                <ContentCard
                  key={idx}
                  onClick={() => {
                    dispatch(setSelectedStory(item));
                    dispatch(setStory("storyEditor"));
                  }}
                  src={SERVER_IMAGE_API + item.imageUrl}
                  alt="none"
                  subtitle={
                    item?.tags?.length && item?.tags.split(",").join(", ") || ''
                  }
                  header={
                    <>
                      <Div
                        style={{
                          display: "flex",
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        {Array.from({ length: 5 }, (v, i) => i + 1)?.map(
                          (el, idx) => {
                            if (el <= Math.round(item.star)) {
                              return <Icon16Like key={idx} />;
                            } else {
                              return <Icon16LikeOutline key={idx} />;
                            }
                          }
                        )}
                      </Div>
                      <Div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          paddingLeft: 0,
                        }}
                      >
                        {item.name}
                      </Div>
                    </>
                  }
                  //   text={}
                  style={{
                    backgroundColor: item.isPublic
                      ? "rgb(15,55,45)"
                      : "rgb(90,45,45)",
                    height: 430,
                  }}
                  caption={
                    <>
                      {item?.about.slice(0, 75).replace("...", "") + "..."}
                      <br />
                      <br />
                      {"Статус модерации: " +
                        (item.isPublic
                          ? "История доступна всем!"
                          : "История временно заблокирована")}
                    </>
                  }
                  maxHeight={200}
                />
              ))) ||
              ""}
          </CardGrid>
        </Group>
      </Panel>
    </SplitLayout>
  );
};
